import {
  DialogTitle,
  Tab,
  TableCell,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  type ToggleButtonProps,
  Tooltip,
  darken,
  lighten,
  styled,
  tableCellClasses,
  tableRowClasses,
} from '@mui/material';

// Style personnalisé pour la Tooltip des RDV agenda
export const CustomTooltip = styled(Tooltip)(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: '1rem',
    border: '1px solid #dadde9',
    position: 'relative',
  },
  '& .MuiTooltip-arrow': {
    color: '#f5f5f9',
  },
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '0.9rem',
  '&.Mui-selected': {
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
  },
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  paddingRight: theme.spacing(1),
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.grey[200],
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.root}:hover`]: {
    backgroundColor: lighten(theme.palette.primary.light, 0.5), // Modifiez ceci selon la couleur souhaitée
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    //backgroundColor: darken(theme.palette.primary.main,0.4),
    color: darken(theme.palette.primary.main, 0.4),
    fontWeight: 'bold',
    borderRadius: 20,
    border: 0,
    //borderLeft: `5px solid ${theme.palette.primary.main}`,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
    borderRight: '100px solid transparent',
    fontSize: '0.9rem',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


export const StyledGroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor: lighten(theme.palette.primary.light, 0.85),
}));

export const StyledGroupItems = styled('ul')({
  padding: 0,
});
