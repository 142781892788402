import { CustomerType, type Employee, type Event, EventState, EventType, Garage } from '@movalib/movalib-commons';
import { flexCenter, formatFrenchVehiclePlate } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import { Box, Typography, darken, useMediaQuery, useTheme } from '@mui/material';
import { differenceInMinutes, format } from 'date-fns';
import { lowerCase, startCase } from 'lodash';
// MyCustomEvent.tsx
import { type CSSProperties, type FunctionComponent, useState } from 'react';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import { Views } from 'react-big-calendar';
import { FIRE_EVENT_DEFAULT_HOURS, PALETTE_THIRD_COLOR_MAIN } from '../../helpers/Constants';
import { CustomTooltip } from '../../helpers/Styled';
import {
  flexCenterCol,
  flexCenterRow,
  flexLeftRow,
  flexStart,
  getEventStateColor,
  getEventStateLabel,
  isWithinLastIndicatedHours,
} from '../../helpers/Tools';
import UserAvatar from '../UserAvatar';
import type { ViewType } from './MyCalendarTypes';
import DeployedCodeHistory from '../../assets/images/icons/deployed_code_history.svg';
require('./MyCalendar.css');
import KeyIcon from '@mui/icons-material/Key';

const DEFAULT_ALERT_ICON_COLOR = 'white';

interface MyCalendarEventProps {
  event: Event;
  view: ViewType;
  teamManagement?: boolean;
  garage?: Garage;
}

const MyCalendarEvent: FunctionComponent<MyCalendarEventProps> = ({ event, view, teamManagement = false, garage }) => {
  const [openTooltip, setOpenTooltip] = useState(event.state === EventState.NEW && view !== 'month');
  const theme = useTheme();

  // Fonction pour gérer le comportement de fermeture
  const handleTooltipClose = () => {
    if (event.state !== EventState.NEW) {
      setOpenTooltip(false); // Permet de fermer la Tooltip pour les événements non-NEW
    }
  };

  // Déterminez si la Tooltip doit être contrôlée ou non
  const isControlled = event.state === EventState.NEW;

  const now = new Date();
  const isPastEvent = event.end ? event.end < now : false;
  const isDoneEvent = event.state ? event.state === EventState.DONE : false;
  // Est-ce que la durée de l'event est égale à l'interval mini ?
  const isMinDuration = event.start && event.end ? differenceInMinutes(event.end, event.start) < 60 : false;
  const startHour = event.start ? new Date(event.start).getHours() : Number.NaN;
  const endHour = event.end ? new Date(event.end).getHours() : Number.NaN;
  const startMinute = event.start ? new Date(event.start).getMinutes() : Number.NaN;
  const endMinute = event.end ? new Date(event.end).getMinutes() : Number.NaN;
  const isDayEvent = startHour === endHour && startMinute === endMinute;
  const eventDuration = event?.start && event?.end && differenceInMinutes(event.end, event.start);

  const getPaddingLeftEvent = (event: Event): string => {
    let paddingLeft = '0';
    const isAvatarShowed = teamManagement && showUserAvatar && employee;
    const haveLoanVehicle = garage?.loanerVehicleActive && event.garageVehicleId;
    const iconProductOrdered = event.hasProductOrdered && event.state === EventState.SCHEDULED;
    if (event.color) {
      paddingLeft = '4px';
    }
    if (
      (event.vehicleAvailableNotified ||  iconProductOrdered || haveLoanVehicle) &&
      isAvatarShowed || ((haveLoanVehicle && iconProductOrdered) || (haveLoanVehicle && event.vehicleAvailableNotified))
    ) {
      paddingLeft = '22px';
    }
    return paddingLeft;
  };

  const getEventStyle = (event: Event): CSSProperties => {
    let style = {};
    if (event?.start && event?.end) {
      const eventDuration = differenceInMinutes(event.end, event.start);
      switch (event.type) {
        case EventType.APPOINTMENT:
          style = {
            position: 'relative',
            fontFamily: 'Outfit',
            opacity: isPastEvent || isDoneEvent ? 0.5 : 1,
            fontSize: 13,
            height: '100%',
            paddingLeft: getPaddingLeftEvent(event),
            backgroundClip: 'padding-box',
          };
          break;
        case EventType.NOTE:
          style = {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            textAlign: 'center',
            fontSize: 14,
            opacity: isPastEvent ? 0.5 : 1,
            color: theme.palette.common.white,
            backgroundColor: (event.color === null || event.color?.length === 0) ? '#4385f4' : event.color,
            cursor: 'pointer',
            border: 'none !important',
            paddingTop: eventDuration && eventDuration > 30 && !isDayEvent ? '15px' : 0,
          };
          break;
        case EventType.UNAVAILABILITY:
          style = {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            fontSize: 14,
            opacity: isPastEvent ? 0.5 : 1,
            color: theme.palette.grey[600],
            textTransform: 'uppercase',
            cursor: event.editable ? 'pointer' : 'default',
            border: 'none !important',
          };
          break;
      }
    }

    return style;
  };

  const avatarStyle: CSSProperties = {
    position: 'absolute',
    left: '-4px',
  };

  const diagonalStyle: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    background: 'repeating-linear-gradient(45deg, transparent, transparent 22px, #fff 22px, #fff 25px)',
    opacity: event.state === EventState.DONE ? 1 : 0, // n'affiche la superposition que si l'événement est clôturé
  };

  const markupIconStyle: CSSProperties = {
    position: 'absolute',
    padding: '2px',
    fontSize: '0.7rem',
    top: '10%',
    left: '100%',
    transform: 'translate(-50%, -50%)',
  };

  const markupTextStyle: CSSProperties = {
    backgroundColor: 'white',
    marginLeft: 'auto',
    marginBottom: '6px',
    marginRight: 'auto',
    padding: '2px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '85%',
    fontSize: '0.7rem',
  };

  const employee: Employee | undefined = event.employees?.[0] ? event.employees?.[0] : undefined;
  const showUserAvatar = teamManagement && !(view === Views.WORK_WEEK || view === Views.DAY);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getFirstLineMarginLeft = (): number => {
    const isAvatarShowed = teamManagement && showUserAvatar && employee;
    const haveLoanVehicle = garage?.loanerVehicleActive && event.garageVehicleId;
    const iconProductOrdered = event.hasProductOrdered && event.state === EventState.SCHEDULED;

    let pixQuantity = 0;
    if (
      (iconProductOrdered ||
        (event.vehicleAvailableNotified && !haveLoanVehicle) ||
        (haveLoanVehicle && !event.vehicleAvailableNotified)) &&
      view !== 'month' &&
      !isAvatarShowed
    ) {
      pixQuantity = 20;
    }
    if (
      !(
        iconProductOrdered ||
        event.vehicleAvailableNotified ||
        haveLoanVehicle
      ) &&
      view !== 'month' &&
      isAvatarShowed
    ) {
      pixQuantity = 20;
    }
    if (haveLoanVehicle && iconProductOrdered) {
      pixQuantity = 0;
    }
    return pixQuantity;
  };

  return (
    <CustomTooltip
      disableTouchListener
      disableInteractive
      title={
        <Box sx={{ color: event.type === EventType.NOTE ? theme.palette.common.white : theme.palette.text.primary }}>
          <Typography
            style={{
              marginBottom: event.type !== EventType.APPOINTMENT ? '2px' : '8px',
              ...flexCenter,
              ...(event.state === EventState.NEW && { ...flexCenter }),
            }}
            className={event.type === EventType.NOTE && view === Views.WORK_WEEK ? '' : 'styled-white-alert'}
          >
            {/*             {event.state === EventState.NEW && (
              <QuestionMarkIcon
                sx={{
                  color: 'white',
                  fontSize: '0.9rem',
                  mr: 1,
                  display: event.state === EventState.NEW ? 'inherit' : 'none',
                }}
              />
            )} */}
            {getEventStateLabel(event)}
          </Typography>
          {/*           {event.type !== EventType.APPOINTMENT && (
            <Typography variant='body2'>
              <strong>{event.title}</strong>
            </Typography>
          )} */}
          {event.type === EventType.NOTE && isDayEvent && <Typography variant='body2'>{event.notes}</Typography>}
          {event.state === EventState.REJECTED && view !== 'month' && (
            <div style={markupTextStyle}>
              <b>DEVIS REFUSÉ</b>
            </div>
          )}
          {event.vehicleAvailableNotified && view !== 'month' && (
            <div style={markupTextStyle}>
              <MarkChatReadIcon sx={{ color: theme.palette.grey[800] }} /> &nbsp;
              <b>SMS FIN RDV ENVOYÉ</b>
            </div>
          )}
          {event.hasProductOrdered &&
            event.state === EventState.SCHEDULED &&
            !event.vehicleAvailableNotified &&
            view !== 'month' && (
              <div style={markupTextStyle}>
                <img src={DeployedCodeHistory} alt='DeployedCodeHistory' />
                &nbsp;
                <b>PIÈCE(S) COMMANDÉE(S)</b>
              </div>
            )}
          {event.customer !== undefined && event.type === EventType.APPOINTMENT && (
            <>
              <Box style={flexStart}>
                <Typography variant='body2' sx={{ mr: 1 }}>
                  Client :{' '}
                </Typography>
                <Typography variant='body2'>
                  {event.customer?.type === CustomerType.INDIVIDUAL ? (
                    <strong>
                      {event.customer?.firstname} {event.customer?.lastname}
                    </strong>
                  ) : (
                    <strong>{event.customer?.companyName}</strong>
                  )}
                </Typography>
              </Box>

              {event.vehicle?.brand && event.vehicle?.model && (
                <Box style={flexStart}>
                  <Typography variant='body2' sx={{ mr: 1 }}>
                    Véhicule :{' '}
                  </Typography>
                  <Typography variant='body2'>
                    {startCase(lowerCase(event.vehicle?.brand))} - {startCase(lowerCase(event.vehicle?.model))}
                  </Typography>
                </Box>
              )}
            </>
          )}

          {showUserAvatar && (
            <Box style={flexStart}>
              <Typography variant='body2' sx={{ mr: 1 }}>
                Réparateur :{' '}
              </Typography>
              <UserAvatar size='small' avatar={employee?.avatar || ''} alt={employee?.firstname || ''} />
              <Typography variant='body2'>
                {employee ? `${employee.firstname} ${employee.lastname}` : 'Non affecté'}
              </Typography>
            </Box>
          )}
          {garage?.loanerVehicleActive && event.garageVehicleId && (
            <Box sx={flexLeftRow}>
              <Typography variant='body2'>
                VP {garage?.vehicles?.find((v) => v.id === event.garageVehicleId)?.index}
                {' : '}&nbsp;
              </Typography>
              <Box sx={flexCenterCol}>
                <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                  {garage?.vehicles?.find((v) => v.id === event.garageVehicleId)?.vehicle.brand}{' '}
                  {garage?.vehicles?.find((v) => v.id === event.garageVehicleId)?.vehicle.model}
                </Typography>
              </Box>
            </Box>
          )}

          {event.vehicleReceived && (
            <Box style={flexStart}>
              <Typography variant='body2' sx={{ mr: 1 }}>
                <i className='fas fa-solid fa-car-on ' style={{ fontSize: '1.1rem', marginRight: '4px' }} />
                Véhicule réceptionné
              </Typography>
            </Box>
          )}
        </Box>
      }
      placement='top-start'
      color={
        event.state === EventState.NEW
          ? isWithinLastIndicatedHours(event.start, FIRE_EVENT_DEFAULT_HOURS)
            ? 'red'
            : '#00BF08'
          : getEventStateColor(event, theme)
      }
      open={isMobile ? false : isControlled ? openTooltip : event.editable ? undefined : false} // Utilisez `undefined` pour laisser la Tooltip non contrôlée pour les événements non-NEW
      onClose={handleTooltipClose}
      // On baisse le zIndex du popper pour éviter de passer au dessus d'une boite de dialogue (détail d'un RDV par exemple)
      slotProps={{
        popper: {
          sx: {
            zIndex: 1000,
          },
        },
        tooltip: {
          sx: {
            border: '1px solid white',
            minWidth: '100px',
          },
        },
      }}
    >
      <div style={{ height: '100%', position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            top: '0px',
            left: '-4px',
            height: 'calc(100% + 20px)',
            width: '8px',
            backgroundColor: event?.color,
          }}
        />
        <div style={{ ...getEventStyle(event), height: '100%' }}>
          <div style={diagonalStyle} />
          <div
            style={{ marginBottom: 3, display: 'flex', flexDirection: 'column' }}
            className='my-calendar-event-label'
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: teamManagement && showUserAvatar && employee ? '25px' : 'auto',
              }}
            >
              {teamManagement && showUserAvatar && (
                <div style={avatarStyle}>
                  {event.employees && (
                    <UserAvatar
                      sx={{
                        position: view !== 'month' ? 'absolute' : 'relative',
                        pt: '1px',
                      }}
                      width='22px'
                      height='22px'
                      showBorder
                      avatar={event.employees?.length > 0 ? event.employees[0].avatar : ''}
                      alt={event.employees?.length > 0 ? event.employees[0].firstname : ''}
                      size='small'
                      placeholder={false}
                    />
                  )}
                </div>
              )}
              <Box
                sx={{
                  position: 'absolute',
                  top: teamManagement && showUserAvatar && employee ? '38px' : '13px',
                  left: '8px',
                }}
              >
                {event.vehicleAvailableNotified && view !== 'month' && (
                  <div style={markupIconStyle}>
                    <MarkChatReadIcon
                      sx={{
                        color: 'white',
                        filter:
                          'drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.5)) drop-shadow(-1px -1px 1px rgba(255, 255, 255, 0.5))',
                      }}
                    />
                  </div>
                )}
                {event.hasProductOrdered &&
                  event.state === EventState.SCHEDULED &&
                  !event.vehicleAvailableNotified &&
                  view !== 'month' && (
                    <div style={markupIconStyle}>
                      <img src={DeployedCodeHistory} alt='DeployedCodeHistory' />{' '}
                    </div>
                  )}
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  top:
                    teamManagement && showUserAvatar && employee
                      ? (event.vehicleAvailableNotified && view !== 'month') ||
                        (event.hasProductOrdered &&
                          event.state === EventState.SCHEDULED &&
                          !event.vehicleAvailableNotified &&
                          view !== 'month')
                        ? '64px'
                        : '38px'
                      :  (((event.hasProductOrdered && event.state === EventState.SCHEDULED ) || event.vehicleAvailableNotified )&& event.garageVehicleId) ? '38px' : '13px',
                  left: '8px',
                }}
              >
                {garage?.loanerVehicleActive && event.garageVehicleId && view !== 'month' && (
                  <Box
                    sx={{
                      ...flexCenterCol,
                      ...markupIconStyle,
                      height: '22px',
                      width: '22px',
                      borderRadius: '11px',
                      border: `2px solid ${theme.palette.grey[700]}`,
                      backgroundColor: 'white',
                    }}
                  >
                    <Typography
                      variant='body1'
                      sx={{
                        lineHeight: '0.7rem',
                        fontWeight: 'bold',
                        fontSize: '0.7rem',
                      }}
                    >
                      {garage?.vehicles?.find((v) => v.id === event.garageVehicleId)?.index}
                    </Typography>
                  </Box>
                )}
              </Box>
              {event.type === EventType.APPOINTMENT && (
                <>
                  <strong
                    style={{
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'row',
                      height:
                        ((event.hasProductOrdered && event.state === EventState.SCHEDULED) ||
                          event.vehicleAvailableNotified ||
                          event.garageVehicleId) &&
                        view !== 'month'
                          ? '25px'
                          : 'auto',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginLeft: `${getFirstLineMarginLeft()}px`,
                    }}
                  >
                    <div
                      style={{
                        display: event?.vehicleReceived ? 'block' : 'none', //TODO : activer si usage avéré
                      }}
                    >
                      <i className='fas fa-solid fa-car-on ' style={{ fontSize: '1rem' }} />
                    </div>
                    {/*                 <VehicleIcon style={{ fontSize: 13, position: 'relative', top: '2px', }} />&nbsp; */}
                    {formatFrenchVehiclePlate(event.vehicle?.plate)}
                    {event.customer?.type === CustomerType.INDIVIDUAL ? (
                      <strong>(&nbsp;{event.customer?.lastname}&nbsp;)</strong>
                    ) : (
                      <strong>(&nbsp;{event.customer?.companyName}&nbsp;)</strong>
                    )}
                  </strong>
                  <br />
                </>
              )}
            </div>

            {/*           {(view !== 'month' || (view === 'month' && event.type !== EventType.UNAVAILABILITY)) && ( */}
            <>
              <span>
                <b>{event.title}</b>
                <br />
              </span>
              {event.type === EventType.NOTE && event.notes && !isDayEvent && (
                <span style={{ marginTop: '10px' }}>{event.notes}</span>
              )}
            </>
            {/*           )} */}
          </div>
          {view !== 'month' && event.type === EventType.APPOINTMENT && !isMinDuration && (
            <span className='my-calendar-event-label'>
              {format(event.start!, 'HH:mm')}&nbsp;à&nbsp;{format(event.end!, 'HH:mm')}
            </span>
          )}

          {view !== 'month' && event.type === EventType.NOTE && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div
                id='folded-corner'
                style={{
                  position: 'absolute',
                  right: '0',
                  bottom: eventDuration && (eventDuration < 30 || isDayEvent) ? '0' : '-10px',
                  width: '0',
                  height: '0',
                  borderTop: `25px solid ${darken('#4285F4', 0.1)}`,
                  borderRight: '25px solid #fff',
                }}
              />
            </div>
          )}
        </div>
      </div>
    </CustomTooltip>
  );
};

export default MyCalendarEvent;
