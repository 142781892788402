import { flexCenter } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import { Box, createTheme, Divider, type SelectChangeEvent, ThemeProvider, Typography } from '@mui/material';
import type { FunctionComponent } from 'react';
import { useEffect, useState } from 'react';
import { flexStart } from '../../../helpers/Tools';
import { styleHeaders } from './pricingTools';
import ClassicOffers, { type ClassicOffersProps } from './offers/ClassicOffers';
import RepaireOffers, { type PartialRepaireOffersData, type RepaireOffersData } from './offers/RepaireOffers';
import { VisibilityOffers } from './offers/VisibilityOffers';
import type { VisibilityOffersData } from './offers/VisibilityOffers';
import FormationOffers, { type FormationOffersData } from './offers/FormationOffers';
import WebOffers, { type WebOffersData } from './offers/WebOffers';

interface DataApi {
  webPage?: { include: boolean; price: number };
  plv?: { id: number; description: string; price: number; quantity: number; free: boolean };
  additionalRepaire?: { id: number; description: string; price: number; quantity: number }; // update la quantité si supp a 3
  additionalPartialRepaire?: { id: number; description: string; price: number; quantity: number }; // update la quantité si supp a 3
  additionalFormation?: { id: number; description: string; price: number; quantity: number; free: boolean }; // update la quantité
}

export interface MovaPackage {
  id: number;
  name: string;
  price: number;
  engagement: number;
  color: string;
}

export interface Offer extends DataApi {
  totalPrice: number;
  movaPackage: MovaPackage;
}
export interface PricingPackageBasicProps {
  movaPackage: MovaPackage;
  options: {
    classique: ClassicOffersProps[];
    reparateur: RepaireOffersData[];
    visibilite: VisibilityOffersData[];
    formation: FormationOffersData[];
    web: WebOffersData[];
  };
  onlyView?: boolean;
  workforce?: number;
  formFormik?: any;
}
export interface PricingPackageProps extends PricingPackageBasicProps {
  selectable?: boolean;
  packageSelected?: number;
  setPackageSelected?: (id: number) => void;
  handleOfferChange?: (offer: Offer) => void;
  plv?: any;
  formation?: any;
  web?: any;
}

const PricingPackage: FunctionComponent<PricingPackageProps> = ({
  movaPackage,
  options,
  selectable,
  packageSelected = 666,
  setPackageSelected,
  workforce,
  plv,
  formation,
  web,
  onlyView = false,
  handleOfferChange,
  formFormik,
}: PricingPackageProps) => {
  const [totalPrice, setTotalPrice] = useState<number>(movaPackage.price);
  const [formDataApi, setFormDataApi] = useState<DataApi>({});
  const [repaireSupp, setRepaireSupp] = useState<number>(0);
  const [partialRepaireSupp, setPartialRepaireSupp] = useState<number>(0);

  const [printPLV, setPrintPLV] = useState<number>(0);
  const [giftPrintPLV, setGiftPrintPLV] = useState<boolean>(false);

  const [formationSupp, setFormationSupp] = useState<number>(0);
  const [giftFormationSupp, setGiftFormationSupp] = useState<boolean>(false);

  const [webPage, setWebPage] = useState<number>(0);

  const resetData = {
    id: 0,
    description: '',
    price: 0,
    quantity: 0,
    free: false,
  };

  useEffect(() => {
    setTotalPrice(movaPackage.price + repaireSupp + partialRepaireSupp + printPLV * (giftPrintPLV ? 0 : 1) + webPage);
  }, [repaireSupp, printPLV, webPage, giftPrintPLV, partialRepaireSupp]);

  useEffect(() => {
    if (onlyView && plv && formation && web && workforce) {
      setGiftFormationSupp(formation.free);
      setGiftPrintPLV(plv.free);
    }
  }, []);

  const handleChangeRepaireSupp = (event: React.ChangeEvent<HTMLInputElement>) => {
    let setData = false;
    const nbRepaireSupp = event.target.value;
    let selectedOffre: RepaireOffersData['offers'][0]['values'][0] = resetData;
    if (Number(nbRepaireSupp) === 0) {
      setRepaireSupp(0);
      setFormDataApi((prevFormDataApi) => ({
        ...prevFormDataApi,
        additionalRepaire: selectedOffre,
      }));
      return;
    }
    const offre = options?.reparateur
      ?.filter((item: RepaireOffersData) => item.id === 8)[0]
      .offers.filter((offre: RepaireOffersData['offers'][0]) => offre.id === movaPackage.id)[0];
    offre.values.map((value: RepaireOffersData['offers'][0]['values'][0]) => {
      if (value.quantity === Number(nbRepaireSupp)) {
        setData = true;
        selectedOffre = value;
        const priceOption = value.price * value.quantity;
        setRepaireSupp(priceOption);
      }
    });
    if (!setData) {
      const maxOffre = offre.values[offre.values.length - 1];
      selectedOffre = maxOffre;
      selectedOffre.quantity = Number(nbRepaireSupp);
      const priceOption = maxOffre.price * Number(nbRepaireSupp);
      setRepaireSupp(priceOption);
    }
    setFormDataApi((prevFormDataApi) => ({
      ...prevFormDataApi,
      additionalRepaire: selectedOffre,
    }));
  };

  const handleChangePrintPLV = (event: SelectChangeEvent<number>) => {
    const offre = options?.visibilite
      ?.filter((item: VisibilityOffersData) => item.id === 10)[0]
      .offers.filter((offre: VisibilityOffersData['offers'][0]) => offre.id === movaPackage.id)[0];
    const PLVoption = event.target.value;
    if (typeof PLVoption === 'number') {
      const selectedOffre = offre.values.filter(
        (value: VisibilityOffersData['offers'][0]['values'][0]) => value.id === PLVoption,
      )[0] as DataApi['plv'];
      if (selectedOffre) {
        selectedOffre.free = giftPrintPLV;
        setFormDataApi((prevFormDataApi) => ({
          ...prevFormDataApi,
          plv: selectedOffre,
        }));
        setPrintPLV(selectedOffre.price);
      }
    } else {
      setPrintPLV(0);
      setFormDataApi((prevFormDataApi) => ({
        ...prevFormDataApi,
        plv: resetData,
      }));
    }
  };

  const handleChangeFormationSupp = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nbFormationSupp = Number(event.target.value);
    if (nbFormationSupp === 0) {
      setFormationSupp(0);
      setFormDataApi((prevFormDataApi) => ({
        ...prevFormDataApi,
        additionalFormation: resetData,
      }));
      return;
    }
    const offre = options?.formation
      ?.filter((item: FormationOffersData) => item.id === 12)[0]
      .offers.filter((offre: FormationOffersData['offers'][0]) => offre.id === movaPackage.id)[0];
    let selectedOffre = offre.values.filter(
      (value: FormationOffersData['offers'][0]['values'][0]) => value.quantity === nbFormationSupp,
    )[0];
    const priceOption = selectedOffre
      ? selectedOffre.price * nbFormationSupp
      : offre.values[offre.values.length - 1].price * nbFormationSupp;
    selectedOffre = selectedOffre || { ...offre.values[offre.values.length - 1], quantity: nbFormationSupp };
    setFormDataApi((prevFormDataApi) => ({
      ...prevFormDataApi,
      additionalFormation: { ...selectedOffre, free: giftFormationSupp },
    }));
    setFormationSupp(priceOption);
  };

  const handleChangePartialRepaireSupp = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nbRepaireSupp = event.target.value;
    const selectedOffre = resetData;
    if (Number(nbRepaireSupp) === 0) {
      setPartialRepaireSupp(0);
      setFormDataApi((prevFormDataApi) => ({
        ...prevFormDataApi,
        additionalPartialRepaire: selectedOffre,
      }));
      return;
    }
    const offre = options?.reparateur
      ?.filter((item: PartialRepaireOffersData) => item.id === 80)[0]
      .offers.filter((offre: PartialRepaireOffersData['offers'][0]) => offre.id === movaPackage.id)[0];
    const data: any = { ...offre, quantity: Number(nbRepaireSupp) };
    setPartialRepaireSupp(data.value * data.quantity);
    setFormDataApi((prevFormDataApi) => ({
      ...prevFormDataApi,
      additionalPartialRepaire: data,
    }));
  };

  const handleChangeWebPage = (event: any) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const offre = options?.web[0].offers.filter(
        (offre: WebOffersData['offers'][0]) => offre.id === movaPackage.id,
      )[0];
      setWebPage(offre.price);
      setFormDataApi((prevFormDataApi) => ({
        ...prevFormDataApi,
        webPage: { include: true, price: offre.price },
      }));
    } else {
      setWebPage(0);
      setFormDataApi((prevFormDataApi) => ({
        ...prevFormDataApi,
        webPage: { include: false, price: 0 },
      }));
    }
  };

  const selectedBox = () => {
    if (setPackageSelected) {
      setPackageSelected(movaPackage.id);
    }
  };

  const theme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: packageSelected === movaPackage.id ? 'black' : movaPackage.color,
              },
              '&.Mui-focused fieldset': {
                borderColor: packageSelected === movaPackage.id ? 'black' : movaPackage.color,
              },
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: packageSelected === movaPackage.id ? 'black' : movaPackage.color,
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: packageSelected === movaPackage.id ? 'black' : movaPackage.color, // Bordure au survol
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: packageSelected === movaPackage.id ? 'black' : movaPackage.color, // Bordure quand c'est focus
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            '&.Mui-focused': {
              color: packageSelected === movaPackage.id ? 'black' : movaPackage.color, // Couleur quand le Select est focus
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            // Spécifiquement pour CheckIcon
            '&.MuiSvgIcon-root.CheckIcon': {
              height: '1.5rem',
              width: '1.5rem',
              fontSize: '1.5rem',
              color: packageSelected === movaPackage.id ? 'white' : movaPackage.color, // Applique la couleur spécifique
            },
            '&.MuiSvgIcon-root.CloseIcon': {
              height: '1rem',
              width: '1rem',
              fontSize: '1rem',
            },
          },
        },
      },

      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: packageSelected === movaPackage.id ? 'rgba(0, 0, 0, 0.6)' : movaPackage.color,
            // Couleur lorsque la checkbox est cochée
            '&.Mui-checked': {
              color: packageSelected === movaPackage.id ? 'rgba(0, 0, 0, 0.6)' : movaPackage.color, // Utiliser la couleur dynamique
            },
            // Couleur lorsque la checkbox est survolée (hover) mais non cochée
            '&:hover:not(.Mui-checked)': {
              color: packageSelected === movaPackage.id ? 'rgba(0, 0, 0, 0.6)' : movaPackage.color, // Couleur différente pour le hover
            },
          },
        },
      },

      MuiTypography: {
        styleOverrides: {
          // Cible tous les Typography avec variant="body2" et une classe spécifique
          root: {
            '&.my-color': {
              color: packageSelected === movaPackage.id ? 'rgba(0, 0, 0, 0.6)' : movaPackage.color, // Applique la couleur dynamique à la classe spécifique
            },
          },
        },
      },
    },
  });

  const giftFormation = () => {
    if (!onlyView) {
      setGiftFormationSupp(!giftFormationSupp);
      setFormDataApi((prevFormDataApi: DataApi) => ({
        ...prevFormDataApi,
        additionalFormation: {
          free: !giftFormationSupp,
          id: prevFormDataApi?.additionalFormation?.id || 0,
          description: prevFormDataApi?.additionalFormation?.description || '',
          price: prevFormDataApi?.additionalFormation?.price || 0,
          quantity: prevFormDataApi?.additionalFormation?.quantity || 0,
        },
      }));
    }
  };
  const giftPrint = () => {
    if (!onlyView) {
      setGiftPrintPLV(!giftPrintPLV);
      setFormDataApi((prevFormDataApi: DataApi) => ({
        ...prevFormDataApi,
        plv: {
          free: !giftPrintPLV,
          id: prevFormDataApi?.plv?.id || 0,
          description: prevFormDataApi?.plv?.description || '',
          price: prevFormDataApi?.plv?.price || 0,
          quantity: prevFormDataApi?.plv?.quantity || 0,
        },
      }));
    }
  };
  useEffect(() => {
    if (packageSelected === movaPackage.id) {
      handleOfferChange?.({ ...formDataApi, totalPrice: totalPrice, movaPackage: movaPackage });
    }
  }, [formDataApi, packageSelected, totalPrice]);
  return (
    <ThemeProvider theme={theme}>
      <Box
        key={packageSelected === movaPackage.id ? movaPackage.id : 'default'}
        onClick={() => selectable && selectedBox()}
        sx={{
          pb: 3,
          minWidth: onlyView ? '50%' : '24%',
          maxWidth: onlyView ? '50%' : '24%',
          flexDirection: 'column',
          cursor: 'pointer',
          scale: packageSelected === movaPackage.id && !onlyView ? 1.1 : 1,
          zIndex: packageSelected === movaPackage.id ? 2 : 1,
          background:
            packageSelected === movaPackage.id
              ? `linear-gradient(135deg, ${movaPackage.color} 0%, #ffffff 100%)`
              : 'linear-gradient(135deg, #f0f0f0 0%, #ffffff 100%)',
          border: packageSelected === movaPackage.id ? '2px solid white' : `2px solid ${movaPackage.color}`,
          borderRadius: '50px',
          position: 'relative',
          transition: 'box-shadow 0.3s ease-in-out',
          '&:hover': {
            boxShadow: `0px 5px 20px -5px ${movaPackage.color}`,
          },
          animation: packageSelected === movaPackage.id && !onlyView ? `pulse-${movaPackage.id} 2s infinite` : 'none', // Animation unique pour chaque package
        }}
        style={flexStart}
      >
        <style>
          {`
            @keyframes pulse-${movaPackage.id} {
              0% {
                box-shadow: 0 0 0 0 ${movaPackage.color};
              }
              70% {
                box-shadow: 0 0 15px 5px ${movaPackage.color};
              }
              100% {
                box-shadow: 0 0 0 0 ${movaPackage.color};
              }
            }
          `}
        </style>
        <Box
          style={{ ...flexCenter, ...styleHeaders }}
          sx={{
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{
              margin: 0,
              padding: 0,
              color: 'white',
              position: 'absolute',
              top: '-17px',
              backgroundColor: movaPackage.color,
              border: '1px solid white',
              px: 2,
              fontSize: '1.2rem',
              borderRadius: '20px',
            }}
          >
            <b>{movaPackage.name}</b>
          </Typography>
          <Typography
            variant='h6'
            sx={{
              margin: 0,
              padding: 0,
              color: packageSelected === movaPackage.id ? 'white' : movaPackage.color,
            }}
          >
            <b>{movaPackage.engagement} mois</b>
          </Typography>
          <Typography
            sx={{
              margin: 0,
              padding: 0,
              fontFamily: 'Caveat',
              color: theme.palette.text.secondary,
              fontSize: '1.7rem',
            }}
          >
            <b>{totalPrice?.toFixed(2)}€ / mois</b>
          </Typography>

          {formationSupp !== 0 && (
            <Typography
              variant='body1'
              sx={{
                margin: 0,
                padding: 0,
                width: '100%',
                textAlign: 'center',
                minHeight: '24px',
                color: theme.palette.text.secondary,
                textDecoration: giftFormationSupp ? 'line-through' : 'none',
              }}
            >
              {formationSupp !== 0 && `(+${formationSupp.toFixed(2)}€ à payer en une seule fois)`}
            </Typography>
          )}
        </Box>
        <Divider sx={{ px: 2, width: '80%' }} />
        <ClassicOffers movaPackage={movaPackage} options={options} />
        <Divider sx={{ px: 2, width: '80%' }} />

        <RepaireOffers
          formFormik={formFormik}
          workforce={workforce}
          movaPackage={movaPackage}
          options={options}
          repaireSupp={repaireSupp}
          partialRepaireSupp={partialRepaireSupp}
          handleChangePartialRepaireSupp={handleChangePartialRepaireSupp}
          handleChangeRepaireSupp={handleChangeRepaireSupp}
          onlyView={onlyView}
        />
        <Divider sx={{ px: 2, width: '80%' }} />

        <VisibilityOffers
          movaPackage={movaPackage}
          giftPrintPLV={giftPrintPLV}
          printPLV={printPLV}
          formFormik={formFormik}
          giftPrint={giftPrint}
          handleChangePrintPLV={handleChangePrintPLV}
          options={options}
          onlyView={onlyView}
          initData={plv?.id}
        />

        <Divider sx={{ px: 2, width: '80%' }} />

        <FormationOffers
          formFormik={formFormik}
          initData={formation?.quantity}
          movaPackage={movaPackage}
          giftFormationSupp={giftFormationSupp}
          formationSupp={formationSupp}
          giftFormation={giftFormation}
          handleChangeFormationSupp={handleChangeFormationSupp}
          options={options}
          onlyView={onlyView}
        />
        <Divider sx={{ px: 2, width: '80%' }} />

        <WebOffers
          initData={web?.include}
          movaPackage={movaPackage}
          formFormik={formFormik}
          webPage={webPage}
          handleChangeWebPage={handleChangeWebPage}
          options={options}
          onlyView={onlyView}
        />
      </Box>
    </ThemeProvider>
  );
};
export default PricingPackage;
