import type { ChangeEvent, FunctionComponent } from 'react';
import type { PricingPackageBasicProps } from '../PricingPackage';
import { Box, Checkbox, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { styleFormation } from '../pricingTools';
import { flexCenter } from '@movalib/movalib-commons/dist/src/helpers/Tools';

interface WebOffersProps extends PricingPackageBasicProps {
  webPage: number;
  handleChangeWebPage: (event: any) => void;
  initData?: boolean;
}

export interface WebOffersData {
  id: number;
  name: string;
  description: string;
  offers: Array<{ id: number; include: boolean; optional: boolean; price: number; quantity: number }>;
}

const WebOffers: FunctionComponent<WebOffersProps> = ({
  movaPackage,
  options,
  webPage,
  handleChangeWebPage,
  onlyView,
  initData = false,
  formFormik
}: WebOffersProps) => {
  useEffect(() => {
    handleChangeWebPage({
      target: {
        name: 'webPage',
        checked: formFormik.values.webPage,
      },
    });
  
  }, [formFormik.values.webPage]);

 const handleChangeWeb = (event: ChangeEvent<HTMLInputElement>) => {
  formFormik.setFieldValue('webPage' ,!formFormik.values.webPage)
  formFormik.handleChange(event);
  handleChangeWebPage(event)
  }
  return (
    <React.Fragment>
      {options?.web.map((item: WebOffersData) => (
        <Box key={item.id} style={{ ...flexCenter, ...styleFormation }} sx={{ width: '80%', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Checkbox
              size='small'
              sx={{ p: 0 }}
              checked={formFormik.values.webPage}
              disabled={onlyView}
              onChange={handleChangeWeb}
            />
            &nbsp;Site web
          </Box>
          <Typography
            variant='body2'
            className='my-color'
            sx={{
              minHeight: '20px',
            }}
          >
            {webPage !== 0 && `${webPage.toFixed(2)}€/mois`}
          </Typography>
        </Box>
      ))}
    </React.Fragment>
  );
};

export default WebOffers;
