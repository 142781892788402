import type { FC } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, Box, IconButton, Toolbar } from '@mui/material';
import CloseRounded from '@mui/icons-material/CloseRounded';
import theme from '../../theme';

type ConflictDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const ConflictLoanVehicleDialog: FC<ConflictDialogProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>

      <Toolbar
        disableGutters
        sx={{
          display: 'block',
          background: theme.palette.grey[200],
          minHeight: 3,
          p: 0,
        }}
      >
      <DialogTitle id='search-dialog-title'>
      <Box position='relative'>
            <Typography
              variant='h6'
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
            Conflit sur le véhicule de prêt
        </Typography>


        <IconButton
              sx={{ position: 'absolute', right: 0, top: 0, zIndex: 1 }}
              size='small'
              aria-label='close'
              onClick={onClose}
              title={'Fermer'}
            >
              <CloseRounded />
            </IconButton>
            </Box>
      </DialogTitle>
      </Toolbar>

        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant='body1'>
            Attention, vous avez modifié la ou les dates du rendez-vous.<br/> Cependant, le véhicule de prêt n'est pas disponible sur cette plage de dates.
          </Typography>
          <Typography variant='body1' sx={{textAlign: 'center', fontWeight: 'bold', py: 1}}>
            L'assignation du véhicule de prêt a été retirée du rendez-vous.
          </Typography>
          <Typography variant='body1'>
            Vous devez modifier le rendez-vous pour sélectionner un véhicule de prêt disponible sur ces dates.
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="primary" onClick={onClose}>
            C'est compris !
          </Button>
        </DialogActions>
    </Dialog>
  );
};
export default ConflictLoanVehicleDialog;
