import {
  AddressFields,
  ConfirmationDialog,
  DateFormatTypes,
  DayOfWeek,
  type DaySchedule,
  type Garage,
  GarageService,
  GenderSelector,
  Logger,
  MovaAppType,
  type MovaFormField,
  Schedule,
  ScheduleFields,
  SlotAlgorithm,
  SubscriptionPaymentInterval,
  User,
  UserService,
  deleteCookie,
  formatDateByCountryCode,
  readCookie,
  validateEmail,
  validatePhoneNumber,
} from '@movalib/movalib-commons';
import {
  findScheduleByDayOfWeek,
  flexCenter,
  formatPhoneNumber,
  getFormattedSchedule,
  validateField,
} from '@movalib/movalib-commons/dist/src/helpers/Tools';
import WarningIcon from '@mui/icons-material/AnnouncementRounded';
import BackIcon from '@mui/icons-material/ArrowBackIosRounded';
import ForwardIcon from '@mui/icons-material/ArrowForwardIosRounded';
import SummaryIcon from '@mui/icons-material/Article';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/CloseRounded';
import MandateIcon from '@mui/icons-material/Gavel';
import ConfirmIcon from '@mui/icons-material/RecommendRounded';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  type AlertColor,
  Box,
  Button,
  Container,
  CssBaseline,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  TextField,
  TextareaAutosize,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  darken,
  lighten,
  useTheme,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import type React from 'react';
import { type CSSProperties, type FunctionComponent, type ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import GreenLeafImage from '../../assets/images/leaf_green_large.png';
import PinkLeafImage from '../../assets/images/leaf_pink_large.png';
import LogoProLarge from '../../assets/images/logo/logo_pro_large_border.png';
import DayPeriodImage from '../../assets/images/sreenshots/signup_dayperiod.png';
import HourImage from '../../assets/images/sreenshots/signup_hour.png';
import GarageSheetImage from '../../assets/images/sreenshots/signup_ss1.png';
import SignUpHeader from '../../components/registration/SignUpHeader';
import { COOKIE_PRO_TOKEN, COOKIE_SELECTED_GARAGE_ID } from '../../helpers/Constants';
import { capitalizeFirstLetterNew, createCookie, deleteCookies, flexEnd, formatTime } from '../../helpers/Tools';
import { setSnackbar } from '../../slices/snackbarSlice';
import './SignUp.css';
import PricingTable from '../../components/registration/PricingTable';
import { useReactToPrint } from 'react-to-print';
import { is } from 'date-fns/locale';
import PricingTools from './Pricing/pricingTools';
import  type { MovaPackage } from './Pricing/PricingPackage';
import pricingData from './Pricing/pricing.json';


const SMS_OPERATOR_UNIT: number = 250;

// Permet de centrer le contenu de l'application
const centered: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  height: 'var(--fullHeight)', // Ajustez la hauteur en fonction de vos besoins
};

enum SignupStep {
  GARAGE,
  ROI,
  SUBSCRIPTION,
  AMORTIZATION,
  ADMIN,
  SETTINGS,
  BILLING,
  SUMMARY,
}

enum SubscriptionTabs {
  ALLOWANCE = 0,
  GAIN = 1,
  LAUNCH_OFFER = 2,
}

const initialROIFormState = {
  period: { value: 'MONTH', isValid: true },
  turnover: { value: '', isValid: true },
  nbInvoice: { value: '', isValid: true },
  nbQuote: { value: '', isValid: true },
  workforce: { value: '', isValid: true },
  nbNoShow: { value: '', isValid: true },
  nbLostQuote: { value: '', isValid: true },
  moOne: { value: '', isValid: true },
  moTwo: { value: '', isValid: true },
  moThree: { value: '', isValid: true },
  moRateOne: { value: '', isValid: true },
  moRateTwo: { value: '', isValid: true },
  moRateThree: { value: '', isValid: true },
  commentRoi: { value: '', isValid: true },
};

const initialSignupFormState: MovaSignupForm = {
  adminFirstname: { value: '', isValid: true },
  adminLastname: { value: '', isValid: true },
  adminEmail: { value: '', isValid: true },
  adminPhoneNumber: { value: '', isValid: true },
  garageName: { value: '', isValid: true },
  garageCountry: { value: '', isValid: true },
  garageSchedules: { value: [], isValid: true },
  garageContactPhone: { value: '', isValid: true },
  garageContactEmail: { value: '', isValid: true },
  garageSettings: { value: null, isValid: true },
};

const initialSignupAddressFormState = {
  streetName: { value: '', isValid: true },
  additional: { value: '', isValid: true },
  postalCode: { value: '', isValid: true },
  cityName: { value: '', isValid: true },
};

const initialSignupSettingsFormState: MovaSignupSettingsForm = {
  workforce: { value: '', isValid: true },
  workforcePartial: { value: '', isValid: true },
  slotAlgorithm: { value: '', isValid: true },
  amVehicleDeposit: { value: undefined, isValid: true },
  pmVehicleDeposit: { value: undefined, isValid: true },
  appointmentRequestStart: { value: '', isValid: true },
  customerQuoteActive: { value: '', isValid: true },
  supplierOrderActive: { value: '', isValid: true },
  subscriptionRibFile: { value: '', isValid: true },
  subscriptionPaymentInterval: { value: SubscriptionPaymentInterval.YEAR, isValid: true },
  subscriptionComment: { value: '', isValid: true },
  commissioningPreference: { value: null, isValid: true },
  trainingOptionalOne: { value: null, isValid: true },
  trainingOptionalTwo: { value: null, isValid: true },

};

const initialMovaPackageState = {
  additionalRepaire: {
    id: 0,
    description: '',
    price: 0,
    quantity: 0,
  },
  additionalFormation: {
    id: 0,
    description: '',
    price: 0,
    quantity: 0,
    free: false
  },
  additionalPartialRepaire: {
    id: 0,
    description: '',
    price: 0,
    quantity: 0,
  },
  webPage: {
    include: false,
    price: 0,
  },
  plv: {
    free: false,
    id: 0,
    description: '',
    price: 0,
    quantity: 0,
  },
  totalPrice: 0,
  movaPackage: {
    id: 0,
    name: '',
    price: 0,
    engagement: 0,
    color: '',
  },
};

export type MovaPackageForm = {
  additionalRepaire: {
    id: number;
    description: string;
    price: number;
    quantity: number;
  };
  additionalPartialRepaire: {
    id: number;
    description: string;
    price: number;
    quantity: number;
  };
  additionalFormation: {
    id: number;
    description: string;
    price: number;
    quantity: number;
    free: boolean;
  };
  webPage: {
    include: boolean;
    price: number;
  };
  plv: {
    free: boolean;
    id: number;
    description: string;
    price: number;
    quantity: number;
  };
  totalPrice?: number;
  movaPackage: MovaPackage;
};
type AmortizationForm = {
  nbInvoice: MovaFormField;
  annualTurnover: MovaFormField;
}

type MovaROIForm = {
  period: MovaFormField;
  turnover: MovaFormField;
  nbInvoice: MovaFormField;
  nbQuote: MovaFormField;
  nbNoShow: MovaFormField;
  nbLostQuote: MovaFormField;
  moOne: MovaFormField;
  moTwo: MovaFormField;
  moThree: MovaFormField;
  moRateOne: MovaFormField;
  moRateTwo: MovaFormField;
  moRateThree: MovaFormField;
  commentRoi: MovaFormField;
};

type MovaSignupForm = {
  adminFirstname: MovaFormField;
  adminLastname: MovaFormField;
  adminEmail: MovaFormField;
  adminPhoneNumber: MovaFormField;
  garageName: MovaFormField;
  garageCountry: MovaFormField;
  garageSchedules: { value?: Schedule[]; error?: string; isValid?: boolean };
  garageContactPhone: MovaFormField;
  garageContactEmail: MovaFormField;
  garageSettings: MovaFormField;
};

type MovaSignupAdressForm = {
  streetName: MovaFormField;
  additional: MovaFormField;
  postalCode: MovaFormField;
  cityName: MovaFormField;
};

type MovaSignupSettingsForm = {
  workforce: MovaFormField;
  workforcePartial: MovaFormField;
  slotAlgorithm: MovaFormField;
  amVehicleDeposit: { value?: Date | undefined; error?: string; isValid?: boolean };
  pmVehicleDeposit: { value?: Date | undefined; error?: string; isValid?: boolean };
  appointmentRequestStart: MovaFormField;
  customerQuoteActive: MovaFormField;
  supplierOrderActive: MovaFormField;
  subscriptionRibFile: MovaFormField;
  subscriptionPaymentInterval: MovaFormField;
  subscriptionComment: MovaFormField;
  commissioningPreference: MovaFormField;
  trainingOptionalOne: MovaFormField;
  trainingOptionalTwo: MovaFormField;
};

const SignUp: FunctionComponent = () => {
  const [tabValue, setTabValue] = useState<SubscriptionTabs>(SubscriptionTabs.ALLOWANCE);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // Référence à l'éventuel RIB transmis
  const ribInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  // La sévérité est initialisée à "error" par défaut
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>('error');
  const [connectedUser, setConnectedUser] = useState<User | undefined>();
  const [currentStep, setCurrentStep] = useState<SignupStep>(SignupStep.ADMIN);
  const isAuthenticated: boolean = Boolean(readCookie(COOKIE_PRO_TOKEN));
  const breakDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const [openMenu, setOpenMenu] = useState<boolean>();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  //const [selectedGarage, setSelectedGarage] = useState<Garage>();
  const [selectedGarageId, setSelectedGarageId] = useState<string | undefined>(undefined);
  const [ROIForm, setROIForm] = useState<MovaROIForm>(initialROIFormState);
  const [subscriptionForm, setSubscriptionForm] = useState<MovaPackageForm>(initialMovaPackageState);
  const [tmpSubscriptionForm, setTmpSubscriptionForm] = useState<MovaPackageForm>(initialMovaPackageState);

  const [signupForm, setSignupForm] = useState<MovaSignupForm>(initialSignupFormState);
  const [signupAddressForm, setSignupAddressForm] = useState<MovaSignupAdressForm>(initialSignupAddressFormState);
  const [signupSettingsForm, setSignupSettingsForm] = useState<MovaSignupSettingsForm>(initialSignupSettingsFormState);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [dailyInvoices, setDailyInvoices] = useState<number>(6);
  const [starterPackCost, setStarterPackCost] = useState<number>();
  const [techniciansCost, setTechniciansCost] = useState<number>();
  const [subscriptionCost, setSubscriptionCost] = useState<number>();
  const [openConfirmSignupDialog, setOpenConfirmSignupDialog] = useState<boolean>(false);
  // Chargement initial de la page
  useEffect(() => {
    if (isAuthenticated) {
      // On contrôle que l'utilisateur dispose du rôle adéquat
      UserService.getCurrentUser(MovaAppType.GARAGE).then((response) => {
        if (response.success) {
          setConnectedUser(response.data);
        }
      });

      // Récupération éventuelle de l'identifiant du garage sélectionné depuis les Cookies
      setSelectedGarageId(readCookie(COOKIE_SELECTED_GARAGE_ID));
    }
  }, []);

  useEffect(() => {
    if (selectedGarageId) {
      // Chargement du garage depuis un appel API
      GarageService.getGarageAllData(selectedGarageId).then((response) => {
        if (response.success) {
          if (response.data) {
            initializeForms(response.data);
          }
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? 'Récupération des données du garage impossible, contactez Movalib',
              severity: 'error',
            }),
          );
        }
      });
    }
  }, [selectedGarageId]);

  const initializeForms = (garage: Garage) => {
    if (garage) {
      const updatedSignupFormState = {
        ...initialSignupFormState, // Copie de l'état initial
        garageName: { value: garage.name },
        adminFirstname: { value: garage.admin?.firstname },
        adminLastname: { value: garage.admin?.lastname },
        adminEmail: { value: garage.admin?.email },
        adminPhoneNumber: { value: garage.admin?.phoneNumber ? garage.admin?.phoneNumber : '' },
        garageContactEmail: { value: garage.contactEmail ? garage.contactEmail : '' },
        garageContactPhone: { value: garage.contactPhone ? garage.contactPhone : '' },
        garageSchedules: { value: garage.schedules ? garage.schedules : [] },
      };
      const updatedRoiFormState = {
        ...initialROIFormState, // Copie de l'état initial
        period: { value: garage?.subscription?.roi?.period ?? 'MONTH' },
        turnover: { value: garage?.subscription?.roi?.turnover },
        nbInvoice: { value: garage?.subscription?.roi?.nbInvoice },
        nbQuote: { value: garage?.subscription?.roi?.nbQuote },
        workforce: { value: garage?.workforce },
        nbNoShow: { value: garage?.subscription?.roi?.nbNoShow },
        nbLostQuote: { value: garage?.subscription?.roi?.nbLostQuote },
        moOne: { value: garage?.subscription?.roi?.moOne },
        moTwo: { value: garage?.subscription?.roi?.moTwo ?? 0 },
        moThree: { value: garage?.subscription?.roi?.moThree ?? 0},
        moRateOne: { value: garage?.subscription?.roi?.moRateOne },
        moRateTwo: { value: garage?.subscription?.roi?.moRateTwo ?? 0},
        moRateThree: { value: garage?.subscription?.roi?.moRateThree ?? 0},
        commentRoi: { value: garage?.subscription?.roi?.comment },
      };
      setROIForm(updatedRoiFormState);
      const emptyData = {
          id: 0,
          description: '',
          price: 0,
          quantity: 0,
        };
      
      const getMovaPackage = ()  => {
        const intervalPaiement = garage.subscriptionPaymentInterval as string;
        if (intervalPaiement === 'HALF') {
          return pricingData.offers.find((offer) => offer.id === 0) as {
            id: number;
            name: string;
            price: number;
            engagement: number;
            color: string;
        };
        } else if (intervalPaiement === 'YEAR') {
          return pricingData.offers.find((offer) => offer.id === 1) as {
            id: number;
            name: string;
            price: number;
            engagement: number;
            color: string;
        };
        } else {
          return pricingData.offers.find((offer) => offer.id === 2) as {
            id: number;
            name: string;
            price: number;
            engagement: number;
            color: string;
        };
        }
      };
      const getAdditionalFormation = () => {
        const additionalFormation = garage.subscription?.additionalFormationQuantity as number;
        const plop = (pricingData.options.formation.find((formation) => formation.id === 12)?.offers as any[]).find((offer) => offer.id === getMovaPackage()?.id);
        const values = (plop as {
          id: number;
          include: boolean;
          optional: boolean;
          values: any[]})?.values;
          if(additionalFormation >= 4) {
            return values?.find((value) => value.quantity === 4);
          } else {
            return values?.find((value) => value.quantity === additionalFormation);
          }
      }
      const getAdditionalWorkforce = () => {
        if (garage.workforce === null || garage.workforce === 1) {
          return emptyData;
        }
        const additionalFormation = garage.workforce - 1 ;
        const plop = (pricingData.options.reparateur.find((reparateur) => reparateur.id === 8)?.offers as any[]).find((offer) => offer.id === getMovaPackage()?.id);
        const values = (plop as {
          id: number;
          include: boolean;
          optional: boolean;
          values: any[]})?.values;
          if(additionalFormation >= 3) {
            return values?.find((value) => value.quantity === 3);
          } else {
            return values?.find((value) => value.quantity === additionalFormation);
          }
      }
      const getAdditionalPartialRepaire = () => {
        return (pricingData.options.reparateur.find((reparateur) => reparateur.id === 80)?.offers as any[]).find((offer) => offer.id === getMovaPackage()?.id);
       
      };
      const getPlv = () => {
        if (garage.subscription?.plvQuantity === null) {
          return {
            free: garage.subscription?.plvFree,
            id: 0,
            description: '',
            price: 0,
            quantity: 0,
          }
        }
        const data = pricingData.options.visibilite.find((visibilite) => visibilite.id === 10)?.offers.find((offer) => offer.id === getMovaPackage()?.id);
        return (data as {
          id: number;
          include: boolean;
          optional: boolean;
          values: any[]}).values.find((value) => value.quantity === garage.subscription?.plvQuantity);
      };

      const UpdatedSubscriptionForm = {
        ...initialMovaPackageState,
        additionalRepaire: {
          ...getAdditionalWorkforce(),
          quantity: garage.workforce ? garage.workforce - 1 : 0,
        },
        additionalFormation: {
          ...getAdditionalFormation(),
          free: garage.subscription?.additionalFormationFree ?? false,
          quantity: garage.subscription?.additionalFormationQuantity ?? 0,
        },
        additionalPartialRepaire: {
          ...getAdditionalPartialRepaire(),
          quantity: garage.partialWorkforce ?? 0
        },
        webPage: {
          include: garage.subscription?.webPage ?? false,
          price: 0,
        },
        plv: {
          free: garage.subscription?.plvFree ?? false,
          ...getPlv(),
        },
        totalPrice: 0,
        movaPackage: getMovaPackage(),
      }
      setSubscriptionForm(UpdatedSubscriptionForm);
      setTmpSubscriptionForm(UpdatedSubscriptionForm);

      setSignupForm(updatedSignupFormState);

      const updatedSignupAddressFormState = {
        ...initialSignupAddressFormState, // Copie de l'état initial
        streetName: { value: garage.address?.streetName, isValid: true },
        additional: { value: garage.address?.additional ? garage.address.additional : '', isValid: true },
        postalCode: { value: garage.address?.postalCode, isValid: true },
        cityName: { value: garage.address?.cityName, isValid: true },
      };

      setSignupAddressForm(updatedSignupAddressFormState);

      const today = new Date().toISOString().substring(0, 10);

      const updatedSignupSettingsFormState = {
        ...initialSignupSettingsFormState, // Copie de l'état initial
        workforce: { value: garage.workforce ? garage.workforce : null, isValid: true },
        slotAlgorithm: { value: garage.slotAlgorithm ? garage.slotAlgorithm.toString() : '', isValid: true },
        amVehicleDeposit: {
          value: garage.zonedAmVehicleDeposit ? new Date(`${today}T${garage.zonedAmVehicleDeposit!}`) : undefined,
          isValid: true,
        },
        pmVehicleDeposit: {
          value: garage.zonedPmVehicleDeposit ? new Date(`${today}T${garage.zonedPmVehicleDeposit!}`) : undefined,
          isValid: true,
        },
        subscriptionPaymentInterval: {
          value: garage.subscriptionPaymentInterval
            ? garage.subscriptionPaymentInterval.toString()
            : SubscriptionPaymentInterval.YEAR,
          isValid: true,
        },
        commissioningPreference: { value: garage.subscription?.activationDate ? new Date(garage.subscription?.activationDate as unknown as string) : null, isValid: true },
        trainingOptionalOne: { value: garage.subscription?.trainingOptionalOne ? new Date(garage.subscription?.trainingOptionalOne as unknown as string) : null, isValid: true },
        trainingOptionalTwo: { value: garage.subscription?.trainingOptionalTwo ? new Date(garage.subscription?.trainingOptionalTwo as unknown as string) : null, isValid: true },

      };

      setSignupSettingsForm(updatedSignupSettingsFormState);
    }
  };

  const handleROIInputChange = (fieldName: string, fieldValue: any): void => {

    const newField: MovaFormField = { [fieldName]: { value: fieldValue, isValid: true } };

    setROIForm({ ...ROIForm, ...newField });
  };


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const fieldName: string = e.target.name;
    let fieldValue: string = e.target.value;

    // On autorise uniquement les chiffre pour le n° de téléphone
    if (fieldName === 'adminPhoneNumber' || fieldName === 'garageContactPhone') {
      fieldValue = fieldValue.replace(/[^0-9]/g, '');
      // Limiter la longueur à 10 caractères
      if (fieldValue.length > 10) {
        fieldValue = fieldValue.substring(0, 10);
      }
    }

    // Capitalisation automatique du prénom
    if (fieldName === 'adminFirstname') {
      fieldValue = capitalizeFirstLetterNew(fieldValue);
    }

    // Uppercase automatique pour le nom
    if (fieldName === 'adminLastname') {
      fieldValue = fieldValue.toUpperCase();
    }

    const newField: MovaFormField = { [fieldName]: { value: fieldValue, isValid: true } };

    setSignupForm({ ...signupForm, ...newField });
  };

  const handleDepositTimeChange = (type: 'amVehicleDeposit' | 'pmVehicleDeposit', newValue: '' | Date | null) => {
    const fieldName: string = type;
    const fieldValue: '' | Date | null = newValue;

    const newField: MovaFormField = { [fieldName]: { value: fieldValue, isValid: true } };

    setSignupSettingsForm({ ...signupSettingsForm, ...newField });
  };

  const isSignupAuthorized = (user: User | undefined) => {
    return user && (User.isSales(user) || User.isSuperAdmin(user));
  };

  const handleSubmit = async (validate: boolean): Promise<boolean> => {
    try {
      // Préparation de la requête (utilisation d'un formData car éventuelle transmission d'un RIB)
      const formData = new FormData();

      const adminJson = {
        email: signupForm.adminEmail?.value,
        firstname: signupForm.adminFirstname?.value,
        lastname: signupForm.adminLastname?.value,
        phoneNumber: signupForm.adminPhoneNumber?.value,
      };

      const addressJson = {
        streetName: signupAddressForm.streetName?.value,
        additional: signupAddressForm.additional?.value,
        postalCode: signupAddressForm.postalCode?.value,
        cityName: signupAddressForm.cityName?.value,
      };
      const query = {
        id: selectedGarageId,
        admin: adminJson,
        name: signupForm.garageName?.value,
        address: currentStep === SignupStep.GARAGE || currentStep === SignupStep.SUMMARY ? addressJson : null,
        schedules: signupForm.garageSchedules?.value,
        contactPhone: signupForm.garageContactPhone?.value,
        contactEmail: signupForm.garageContactEmail?.value,
        workforce: 1,
        slotAlgorithm: signupSettingsForm.slotAlgorithm?.value,
        countryCode: 'FR', // Code Pays par défaut pour le moment
        amVehicleDeposit: formatDateByCountryCode(
          signupSettingsForm.amVehicleDeposit?.value,
          'FR',
          DateFormatTypes.TIME_ONLY,
        ),
        pmVehicleDeposit: formatDateByCountryCode(
          signupSettingsForm.pmVehicleDeposit?.value,
          'FR',
          DateFormatTypes.TIME_ONLY,
        ),
        subscriptionPaymentInterval: tmpSubscriptionForm.movaPackage.engagement === 24 ? 'TWO_YEARS' : tmpSubscriptionForm.movaPackage.engagement === 12 ? 'YEAR' : 'HALF',
        subscriptionComment: signupSettingsForm.subscriptionComment?.value,
        commissioningPreference: signupSettingsForm.commissioningPreference?.value,
        trainingOptionalOne: signupSettingsForm.trainingOptionalOne?.value,
        trainingOptionalTwo: signupSettingsForm.trainingOptionalTwo?.value,
        validateRegistration: validate,
        rdvRatioCost: safeCalculateTotalPrice(),
        roi: {
          period: ROIForm.period?.value,
          turnover: ROIForm.turnover?.value,
          nbInvoice: ROIForm.nbInvoice?.value,
          nbQuote: ROIForm.nbQuote?.value,
          nbNoShow: ROIForm.nbNoShow?.value,
          nbLostQuote: ROIForm.nbLostQuote?.value,
          moOne: ROIForm.moOne?.value,
          moTwo: ROIForm.moTwo?.value,
          moThree: ROIForm.moThree?.value,
          moRateOne: ROIForm.moRateOne?.value,
          moRateTwo: ROIForm.moRateTwo?.value,
          moRateThree: ROIForm.moRateThree?.value,
          comment: ROIForm.commentRoi?.value,
        },

        calculationSheet: {
        additionalRepairQuantity : tmpSubscriptionForm.additionalRepaire.quantity,
        additionalPartialRepairQuantity : tmpSubscriptionForm.additionalPartialRepaire.quantity,
        additionalFormationQuantity : tmpSubscriptionForm.additionalFormation.quantity,
        additionalFormationFree : tmpSubscriptionForm.additionalFormation.free,
        webPage : tmpSubscriptionForm.webPage.include,
        plvQuantity : tmpSubscriptionForm.plv.quantity,
        plvFree : tmpSubscriptionForm.plv.free,
        }
      };

      formData.append('garage', JSON.stringify(query));
      formData.append('rib', signupSettingsForm.subscriptionRibFile?.value);

      const response = await GarageService.salesSignUp(formData);

      if (response.success) {
        // Inscription de l'identifiant du garage dans un Cookie
        createCookie(COOKIE_SELECTED_GARAGE_ID, String(response.data));
        setSelectedGarageId(response.data ? String(response.data) : '');

        if (currentStep === SignupStep.SUMMARY) {
          dispatch(
            setSnackbar({
              open: true,
              message: 'Inscription du client terminée et validée !',
              severity: 'success',
            }),
          );
          // Reset de la page
          purgeForms();
        }

        return Promise.resolve(true);
      }
      dispatch(
        setSnackbar({
          open: true,
          message: response.error ?? 'Inscription du garage impossible, contactez Movalib',
          severity: 'error',
        }),
      );
      return Promise.resolve(false);
    } catch (error) {
      console.error('Error occurred during submission:', error);
      return Promise.resolve(false);
    }
  };

  const handleSignupSettingsChange = (fieldName: keyof MovaSignupSettingsForm, newValue: any) => {
    const newField: MovaFormField = { [fieldName]: { value: newValue, isValid: true } };

    setSignupSettingsForm({ ...signupSettingsForm, ...newField });
  };

  const handleSignupChange = (fieldName: keyof MovaSignupForm, newValue: any) => {
    // Maj AUTO si nom du garage ou nom de l'administrateur
    if (fieldName === 'garageName') {
      newValue = String(newValue).toUpperCase();
    }

    const newField: MovaFormField = { [fieldName]: { value: newValue, isValid: true } };

    setSignupForm({ ...signupForm, ...newField });
  };

  const handleSignupAddressChange = (fieldName: keyof MovaSignupAdressForm, newValue: any) => {
    // Upper case automatique sur le nom de la ville
    if (fieldName === 'cityName') {
      newValue = (newValue as string).toUpperCase();
    }

    const newField: MovaFormField = { [fieldName]: { value: newValue, isValid: true } };

    setSignupAddressForm({ ...signupAddressForm, ...newField });
  };

  const handleScheduleChange = (schedules: DaySchedule[]) => {
    if (schedules) {
      // On contrôle l'absence d'erreur dans le tableau de schedule
      const hasErrors = schedules.some((day) => day.intervals.some((interval) => interval.error !== null));

      Logger.info(schedules);

      if (!hasErrors) {
        // On crée un objet Schedule sur la base du DaySchedule reçu de ScheduleFields
        const newSchedules = new Array<Schedule>();
        schedules.forEach((s) => {
          // Seuls les journées actives (cochées) sont envoyées
          if (s.checked) {
            newSchedules.push(
              new Schedule(
                s.day,
                s.intervals.map(({ startTime, endTime, countryCode }) => ({
                  startTime: formatTime(startTime),
                  endTime: formatTime(endTime),
                  countryCode,
                })),
                true,
              ),
            );
          }
        });

        // On sauvegarde les objets dans le formulaire
        setSignupForm((prevForm) => ({
          ...prevForm,
          ['garageSchedules']: { ...prevForm['garageSchedules'], value: newSchedules },
        }));
      } else {
      }
    }
  };

  const validateSignupBillingStep = (): boolean => {
    const newSettingsForm: MovaSignupSettingsForm = { ...signupSettingsForm };
      // console.log('newSettingsForm', newSettingsForm.commissioningPreference.value, !!signupSettingsForm.commissioningPreference, newSettingsForm.commissioningPreference.value.toString() !== 'Invalid Date');
    newSettingsForm.commissioningPreference = validateField(
      signupSettingsForm.commissioningPreference,
      (value) => !!value ,
      'Champ obligatoire',
    );

    setSignupSettingsForm(newSettingsForm);
    return !Boolean(newSettingsForm.subscriptionPaymentInterval.error || newSettingsForm.commissioningPreference.error);
  };

  const validateSignupSettingsStep = (): boolean => {
    const newSettingsForm: MovaSignupSettingsForm = { ...signupSettingsForm };

    // Validator pour les champs obligatoires
    newSettingsForm.slotAlgorithm = validateField(
      signupSettingsForm.slotAlgorithm,
      (value) => !!value,
      'Champ obligatoire',
    );

    // Validator pour les horaires de dépôts le cas échéant
    if (newSettingsForm.slotAlgorithm.value === SlotAlgorithm.DAY_PERIOD) {
      if (
        !(newSettingsForm.amVehicleDeposit.value instanceof Date) &&
        !(newSettingsForm.pmVehicleDeposit.value instanceof Date)
      ) {
        // On positionne l'erreur uniquement sur le créneau AM, le helper text sera valable pour les deux
        newSettingsForm.amVehicleDeposit.isValid = false;
        newSettingsForm.amVehicleDeposit.error = 'Vous devez définir au moins un horaire de dépôt véhicule';
      } else {
        newSettingsForm.amVehicleDeposit.isValid = true;
        newSettingsForm.amVehicleDeposit.error = undefined;
      }
    } else {
      newSettingsForm.amVehicleDeposit.isValid = true;
      newSettingsForm.amVehicleDeposit.error = undefined;
    }

    setSignupSettingsForm(newSettingsForm);

    return !Boolean(
       newSettingsForm.slotAlgorithm.error || newSettingsForm.amVehicleDeposit.error,
    );
  };

  const purgeForms = () => {
    //localStorage.removeItem('selectedGarage');
    deleteCookie(COOKIE_SELECTED_GARAGE_ID);
    setSelectedGarageId(undefined);
    setSignupForm(initialSignupFormState);
    setSignupAddressForm(initialSignupAddressFormState);
    setSignupSettingsForm(initialSignupSettingsFormState);
  };

  const validateSignupAdminStep = (): boolean => {
    const newGarageForm: MovaSignupForm = { ...signupForm };

    // Validator pour les champs obligatoires
    newGarageForm.garageName = validateField(signupForm.garageName, (value) => !!value, 'Champ obligatoire');
    newGarageForm.adminFirstname = validateField(signupForm.adminFirstname, (value) => !!value, 'Champ obligatoire');
    newGarageForm.adminLastname = validateField(signupForm.adminLastname, (value) => !!value, 'Champ obligatoire');

    /*         newGarageForm.adminBirthDate = validateField(signupForm.adminBirthDate, value => {
            // Vérifie que la valeur existe
            if (!value) return false;

            // Tente de convertir la valeur en date et vérifie si ce n'est pas une 'Invalid Date'
            const date = new Date(value);
            return !isNaN(date.getTime());
        }, 'Champ obligatoire');
        newGarageForm.gender = validateField(signupForm.gender, value => !!value, 'Champ obligatoire'); */

    newGarageForm.adminPhoneNumber = validateField(
      signupForm.adminPhoneNumber,
      (value) => !!value,
      'Champ obligatoire',
    );

    // Validator pour l'email
    if (newGarageForm.adminEmail.isValid)
      newGarageForm.adminEmail = validateField(signupForm.adminEmail, validateEmail, 'Adresse email invalide');

    // Validator 'phoneNumber'
    if (newGarageForm.adminPhoneNumber.isValid)
      newGarageForm.adminPhoneNumber = validateField(
        signupForm.adminPhoneNumber,
        validatePhoneNumber,
        'Le n° de téléphone est invalide.',
      );

    setSignupForm(newGarageForm);

    return !Boolean(
      newGarageForm.garageName.error ||
      newGarageForm.adminFirstname.error ||
      newGarageForm.adminLastname.error ||
      newGarageForm.adminEmail.error ||
      newGarageForm.adminPhoneNumber.error,
    );
  };

  const validateSignupGarageStep = (): boolean => {
    const newGarageForm: MovaSignupForm = { ...signupForm };
    const newAddressForm: MovaSignupAdressForm = { ...signupAddressForm };

    // Validator pour les champs obligatoires
    newGarageForm.garageContactEmail = validateField(
      signupForm.garageContactEmail,
      (value) => !!value,
      'Champ obligatoire',
    );
    newGarageForm.garageContactPhone = validateField(
      signupForm.garageContactPhone,
      (value) => !!value,
      'Champ obligatoire',
    );
    newAddressForm.streetName = validateField(signupAddressForm.streetName, (value) => !!value, 'Champ obligatoire');
    newAddressForm.postalCode = validateField(signupAddressForm.postalCode, (value) => !!value, 'Champ obligatoire');
    newAddressForm.cityName = validateField(signupAddressForm.cityName, (value) => !!value, 'Champ obligatoire');

    // Validator pour l'email
    if (newGarageForm.garageContactEmail.isValid)
      newGarageForm.garageContactEmail = validateField(
        signupForm.garageContactEmail,
        validateEmail,
        'Adresse email invalide',
      );

    // Validator 'phoneNumber'
    if (newGarageForm.garageContactPhone.isValid)
      newGarageForm.garageContactPhone = validateField(
        signupForm.garageContactPhone,
        validatePhoneNumber,
        'Le n° de téléphone est invalide.',
      );

    // Validator pour les horaires d'ouverture
    newGarageForm.garageSchedules = validateField(
      signupForm.garageSchedules,
      (value) => !!value && value.length > 0,
      'Vous devez au moins définir une journée de travail',
    );

    setSignupForm(newGarageForm);
    setSignupAddressForm(newAddressForm);

    return !Boolean(
      newGarageForm.garageContactEmail.error ||
      newGarageForm.garageContactPhone.error ||
      newAddressForm.streetName.error ||
      newAddressForm.cityName.error ||
      newAddressForm.postalCode.error
    );
  };

  const [ROIFormIsValid, setROIFormIsValid] = useState<boolean>(false);
  const validateSignupROIStep = () => {
    const newROIForm: MovaROIForm = { ...ROIForm };

    // Validator pour les champs obligatoires
    newROIForm.period = validateField(ROIForm.period, (value) => !!value, 'Champ obligatoire');
    newROIForm.turnover = validateField(ROIForm.turnover, (value) => !!value, 'Champ obligatoire');
    newROIForm.nbInvoice = validateField(ROIForm.nbInvoice, (value) => !!value, 'Champ obligatoire');
    newROIForm.nbQuote = validateField(ROIForm.nbQuote, (value) => !!value, 'Champ obligatoire');
    newROIForm.nbNoShow = validateField(ROIForm.nbNoShow, (value) => !!value, 'Champ obligatoire');
    newROIForm.nbLostQuote = validateField(ROIForm.nbLostQuote, (value) => !!value, 'Champ obligatoire');
    newROIForm.moOne = validateField(ROIForm.moOne, (value) => !!value, 'Champ obligatoire');
    newROIForm.moRateOne = validateField(ROIForm.moRateOne, (value) => !!value, 'Champ obligatoire');



    setROIForm(newROIForm);
    const isvalid = !Boolean(
      newROIForm.period.error ||
      newROIForm.turnover.error ||
      newROIForm.nbInvoice.error ||
      newROIForm.nbQuote.error ||
      newROIForm.nbNoShow.error ||
      newROIForm.nbLostQuote.error ||
      newROIForm.moOne.error ||
      newROIForm.moRateOne.error
    )
    setROIFormIsValid(isvalid)
    return isvalid;
  };

 
  useEffect(() => {
    console.log('signupSettingsForm', signupSettingsForm);
  }, [signupSettingsForm]);

  const handleSlotAlgorithmChange = (e: SelectChangeEvent<string>): void => {
    const fieldName: string = e.target.name;
    const fieldValue: string = e.target.value;
    const newField: MovaFormField = { [fieldName]: { value: fieldValue, isValid: true } };

    setSignupSettingsForm({ ...signupSettingsForm, ...newField });

    if (fieldValue === SlotAlgorithm.HOUR) {
      setSignupSettingsForm((prevForm) => ({
        ...prevForm,
        ['amVehicleDeposit']: { ...prevForm['amVehicleDeposit'], value: undefined },
      }));
      setSignupSettingsForm((prevForm) => ({
        ...prevForm,
        ['pmVehicleDeposit']: { ...prevForm['pmVehicleDeposit'], value: undefined },
      }));
    }
  };

  const handlePaymentIntervalChange = (e: SelectChangeEvent<string>): void => {
    const fieldName: string = e.target.name;
    const fieldValue: string = e.target.value;
    const newField: MovaFormField = { [fieldName]: { value: fieldValue, isValid: true } };

    // Si engagement mensuel on force la valeur du tabValue à 1 (onglet Amortissement)
    if (fieldValue === SubscriptionPaymentInterval.MONTH && tabValue === SubscriptionTabs.LAUNCH_OFFER) {
      setTabValue(SubscriptionTabs.ALLOWANCE);
    }

    setSignupSettingsForm({ ...signupSettingsForm, ...newField });
  };



  const handleSignupNext = async () => {
    switch (currentStep) {
      case SignupStep.ADMIN:
        if (validateSignupAdminStep()) {
          setCurrentStep(SignupStep.ROI);
          // On pré-rempli les informations de contact Garage avec celles de l'admin
          const fieldName: string = 'garageContactEmail';
          const newEmailField: MovaFormField = { [fieldName]: { value: signupForm.adminEmail.value, isValid: true } };
          /* const fieldName2: string = "garageContactPhone";
                    const newPhoneField: MovaFormField = { [fieldName2]: { value: signupForm.adminPhoneNumber.value, isValid: true } };  */
          setSignupForm({ ...signupForm, ...newEmailField });
          if (isSignupAuthorized(connectedUser)) handleSubmit(false);
        }
        break;

      case SignupStep.GARAGE:
        if (validateSignupGarageStep()) {
          setCurrentStep(SignupStep.SETTINGS);
          if (isSignupAuthorized(connectedUser)) handleSubmit(false);
        }
        break;

      case SignupStep.ROI:
        if (validateSignupROIStep()) {
          setCurrentStep(SignupStep.SUBSCRIPTION);
          if (isSignupAuthorized(connectedUser)) handleSubmit(false);
        }
        break;

      case SignupStep.SUBSCRIPTION:
        setSubscriptionForm(tmpSubscriptionForm);
        setCurrentStep(SignupStep.AMORTIZATION);
        if (isSignupAuthorized(connectedUser)) handleSubmit(false);

        break;

      case SignupStep.AMORTIZATION:
          setCurrentStep(SignupStep.GARAGE);
          if (isSignupAuthorized(connectedUser)) handleSubmit(false);
        break;

      case SignupStep.SETTINGS:
        if (validateSignupSettingsStep()) {
          setCurrentStep(SignupStep.BILLING);
          if (isSignupAuthorized(connectedUser)) handleSubmit(false);
        }
        break;

      case SignupStep.BILLING:
        if(validateSignupBillingStep()){
          setOpenConfirmSignupDialog(true);
        }
        break;


      case SignupStep.SUMMARY:
        if (isSignupAuthorized(connectedUser)) {
          const response = await handleSubmit(true);

          if (response) {
            // Redirection vers le listing des inscriptions en cours
            history.push(`/registrations?key=${Date.now()}`);
            purgeForms();
            setCurrentStep(SignupStep.ADMIN);
          }
        }

        break;
    }
  };
  const handleSignupPrev = () => {
    switch (currentStep) {
      case SignupStep.GARAGE:
        setCurrentStep(SignupStep.AMORTIZATION);
        break;

      case SignupStep.ROI:
        setCurrentStep(SignupStep.ADMIN);
        break;

      case SignupStep.SUBSCRIPTION:
        setSubscriptionForm(tmpSubscriptionForm);
        setCurrentStep(SignupStep.ROI);
        break;

      case SignupStep.AMORTIZATION:
        setCurrentStep(SignupStep.SUBSCRIPTION);
        break;

      case SignupStep.SETTINGS:
        setCurrentStep(SignupStep.GARAGE);
        break;
      case SignupStep.BILLING:
        setCurrentStep(SignupStep.SETTINGS);
        break;
      case SignupStep.SUMMARY:
        setCurrentStep(SignupStep.BILLING);
        break;
    }
  };
  const handleCloseConfirmSignup = () => {
    setOpenConfirmSignupDialog(false);
  };

  const handleConfirmSignup = () => {
    setOpenConfirmSignupDialog(false);
    if (validateSignupBillingStep()) {
      setCurrentStep(SignupStep.SUMMARY);
      if (isSignupAuthorized(connectedUser)) handleSubmit(false);
    }
  };

  const handleSendMandate = async () => {
    setLoading(true);

    try {
      if (selectedGarageId) {
        // On commence par pousser les éventuelles modifications sur les données de pré-inscription
        const submit = await handleSubmit(false);

        if (submit) {
          const response = await GarageService.sendGarageMandate(selectedGarageId);

          if (!response.success || !response.data) {
            setLoading(false);
            dispatch(
              setSnackbar({ open: true, message: response.error ?? 'Une erreur est survenue', severity: 'error' }),
            );
          } else {
            setLoading(false);
            dispatch(
              setSnackbar({
                open: true,
                message: response.data ?? "Le mandat a été envoyé par email au client, il doit désormais l'accepter.",
                severity: 'success',
              }),
            );
          }
        } else {
          setLoading(false);
          dispatch(setSnackbar({ open: true, message: 'Une erreur est survenue', severity: 'error' }));
        }
      }
    } catch (error) {
      Logger.error('Error occurred during submission:', error);
      setLoading(false);
    }
  };


  const handleCloseMenu = () => {
    setAnchorElNav(null);
  };

  const handlePageClick = (page: string) => {
    //Fermeture du menu
    handleCloseMenu();
    if (page) {
      // Cas particulier du Logout
      switch (page) {
        case 'PRICING':
          deleteCookie(COOKIE_SELECTED_GARAGE_ID);
          // Redirection vers le formulaire d'inscription
          history.push('/pricing');
          break;
        case 'LOGOUT':
          // Suppression des cookies
          deleteCookies();
          // Suppression de l'utilisateur connecté
          setConnectedUser(undefined);
          setCurrentStep(SignupStep.ADMIN);
          purgeForms();
          // Redirection vers le login
          history.push('/login');
          break;

        case 'REGISTRATIONS':
          setCurrentStep(SignupStep.ADMIN);
          // Redirection vers le login
          history.push(`/registrations?key=${Date.now()}`);
          break;

        case 'SIGNUP':
          setCurrentStep(SignupStep.ADMIN);
          purgeForms();
          // Redirection vers le login
          //history.push('/signup?key=' + Date.now());
          break;
      }
    }
  };

  const getActionButtons = (): ReactNode => {
    return (
      <>
        {currentStep !== SignupStep.ADMIN && (
          <Button variant='contained' color='inherit' sx={{ width: '200px', mr: 4 }} onClick={handleSignupPrev}>
            <BackIcon style={{ fontSize: '1rem', marginRight: 2 }} />
            &nbsp;PRÉCÉDENT
          </Button>
        )}
        <Button
          variant='contained'
          sx={{ width: currentStep !== SignupStep.SUMMARY ? '200px' : '250px' }}
          onClick={handleSignupNext}
        >
          {currentStep === SignupStep.SUMMARY ? (
            <>
              VALIDER L'INSCRIPTION&nbsp;
              <CheckIcon style={{ fontSize: '1rem', marginLeft: 2 }} />
            </>
          ) : currentStep === SignupStep.BILLING ? (
            <>
              PASSER AU RECAP&nbsp;
              <SummaryIcon style={{ fontSize: '1rem', marginLeft: 2 }} />
            </>
          ) : (
            <>
              SUIVANT&nbsp;
              <ForwardIcon style={{ fontSize: '1rem', marginLeft: 2 }} />
            </>
          )}
        </Button>
      </>
    );
  };



  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef, documentTitle: `Audit de création de valeur - ${signupForm.garageName.value}` });
  const getValueByPeriodOnMonth = (value: string): number => {
    if (value === '') return 0;
    if (ROIForm.period.value === 'MONTH') {
      return parseFloat(value);
    } else {
      return parseFloat(value) / 3;
    }
  }
  // Fonction utilitaire pour valider les résultats et éviter la division par zéro
  function isValidNumber(value: number) {
    return !isNaN(value) && value !== Infinity && value !== -Infinity;
  }
  function safeCalculation(value: string, ratio: number) {
    const calculatedValue = parseFloat(value) * (ratio / 100);
    return isNaN(calculatedValue) || !isFinite(calculatedValue) ? '0.00' : calculatedValue.toFixed(2);
  }
  // Fonction utilitaire pour vérifier les divisions par zéro
  function safeDivide(numerator: number, denominator: number) {
    return denominator === 0 || isNaN(denominator) ? 0 : numerator / denominator;
  }

  const getGrowQuote = () => {
    const turnover = getValueByPeriodOnMonth(ROIForm.turnover.value);
    const nbQuote = getValueByPeriodOnMonth(ROIForm.nbQuote.value);
    const nbLostQuote = ROIForm.nbLostQuote.value;

    const result = (safeDivide(turnover, nbQuote) * nbLostQuote).toFixed(2);
    return isValidNumber(Number(result)) ? result : '0';
  }

  const getGrowNoShow = () => {
    const turnover = getValueByPeriodOnMonth(ROIForm.turnover.value);
    const nbInvoice = getValueByPeriodOnMonth(ROIForm.nbInvoice.value);
    const nbNoShow = ROIForm.nbNoShow.value;

    const result = (safeDivide(turnover, nbInvoice) * nbNoShow).toFixed(2);
    return isValidNumber(Number(result)) ? result : '0';
  }

  const getCAInvoice = () => {
    const turnover = getValueByPeriodOnMonth(ROIForm.turnover.value);
    const nbInvoice = getValueByPeriodOnMonth(ROIForm.nbInvoice.value);

    const result = safeDivide(turnover, nbInvoice).toFixed(2);
    return isValidNumber(Number(result)) ? result : '0.00';
  }

  const getCAQuote = () => {
    const nbQuote = ROIForm.nbQuote.value;
    if (nbQuote === '' || nbQuote === '0') return '0.00';

    const turnover = getValueByPeriodOnMonth(ROIForm.turnover.value);
    const result = safeDivide(turnover, getValueByPeriodOnMonth(nbQuote)).toFixed(2);

    return isValidNumber(Number(result)) ? result : '0.00';
  }

  const totalLost = () => {
    const turnover = getValueByPeriodOnMonth(ROIForm.turnover.value);
    const nbQuote = getValueByPeriodOnMonth(ROIForm.nbQuote.value);
    const nbInvoice = getValueByPeriodOnMonth(ROIForm.nbInvoice.value);

    const lostQuotePart = getValueByPeriodOnMonth(ROIForm.nbLostQuote.value) * safeDivide(turnover, nbQuote);
    const noShowPart = getValueByPeriodOnMonth(ROIForm.nbNoShow.value) * safeDivide(turnover, nbInvoice);

    const result = (lostQuotePart + noShowPart).toFixed(2);
    return isValidNumber(Number(result)) ? result : '0.00';
  }

  const totalGain = () => {
    const growNoShow = parseFloat(getGrowNoShow()) * (ratioGrowNoShow / 100);
    const growQuote = parseFloat(getGrowQuote()) * (ratioGrowQuote / 100);
    const growM01 = getGrowM01();
    const growM02 = getGrowM02();
    const growM03 = getGrowM03();

    const result = (growNoShow + growQuote + growM01 + growM02 + growM03).toFixed(2);
    return isValidNumber(Number(result)) ? result : '0.00';
  }

  const getGrowM03 = () => {
    return (ROIForm.moRateThree.value * ROIForm.moThree.value * (ratioGrowMO3 / 100))
  }
  const getGrowM02 = () => {
    return (ROIForm.moRateTwo.value * ROIForm.moTwo.value * (ratioGrowMO2 / 100))
  }
  const getGrowM01 = () => {
    return (ROIForm.moRateOne.value * ROIForm.moOne.value * (ratioGrowMO1 / 100))
  }
  function formatDate(date: Date) {
    const day = String(date.getDate()).padStart(2, '0'); // Ajoute un zéro devant si le jour est inférieur à 10
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Le mois commence à 0, donc on ajoute 1
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  }
  const safeCalculateTotalPrice = () => {
    const totalPrice = subscriptionForm.totalPrice;
    const period = ROIForm.period.value;
    const nbInvoice = parseFloat(ROIForm.nbInvoice.value);

    // Vérification si le nombre de factures est valide
    const denominator = (period === 'MONTH') ? nbInvoice : nbInvoice / 3;

    // Utilisation de la fonction `safeDivide` pour éviter la division par zéro
    const result = safeDivide(totalPrice ?? 0, denominator).toFixed(2);

    return isValidNumber(Number(result)) ? result : '0.00';
  }
  const [ratioGrowQuote, setRatioGrowQuote] = useState<number>(20);
  const [ratioGrowNoShow, setRatioGrowNoShow] = useState<number>(20);
  const [ratioGrowMO1, setRatioGrowMO1] = useState<number>(15);
  const [ratioGrowMO2, setRatioGrowMO2] = useState<number>(15);
  const [ratioGrowMO3, setRatioGrowMO3] = useState<number>(15);

  const getSignUpForm = (): ReactNode => {
    return (
      <Box>

        {currentStep === SignupStep.ADMIN && (
          <Box>
            <Grid container columnSpacing={4} sx={{ pt: 2, pb: 2, mt: 12 }}>
              <Grid item lg={7} xs={12} order={{ xs: 2, lg: 1 }} sx={{ pr: 6 }}>
                <Grid container columnSpacing={4}>
                  <Grid item xs={12}>
                    <TextField
                      id='settings-garage-name'
                      label='NOM DU GARAGE'
                      value={signupForm.garageName.value}
                      required
                      name='garageName'
                      autoFocus
                      onChange={(e) => handleSignupChange('garageName', e.target.value)}
                      error={Boolean(signupForm.garageName.error)}
                      helperText={signupForm.garageName.error}
                      sx={{ width: '100%', mb: 3 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6' color={theme.palette.text.secondary}>
                      <b>Responsable du garage</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      margin='normal'
                      required
                      fullWidth
                      id='adminFirstname'
                      label='Prénom'
                      name='adminFirstname'
                      autoComplete='given-name'
                      onChange={(e) => handleInputChange(e)}
                      value={signupForm.adminFirstname?.value}
                      error={Boolean(signupForm.adminFirstname?.error)}
                      helperText={signupForm.adminFirstname?.error}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      margin='normal'
                      required
                      fullWidth
                      id='adminLastname'
                      label='Nom'
                      name='adminLastname'
                      autoComplete='family-name'
                      onChange={(e) => handleInputChange(e)}
                      value={signupForm.adminLastname?.value}
                      error={Boolean(signupForm.adminLastname?.error)}
                      helperText={signupForm.adminLastname?.error}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      margin='normal'
                      required
                      fullWidth
                      id='adminEmail'
                      label='Adresse email'
                      name='adminEmail'
                      autoComplete='email'
                      onChange={(e) => handleInputChange(e)}
                      value={signupForm.adminEmail?.value}
                      error={Boolean(signupForm.adminEmail?.error)}
                      helperText={signupForm.adminEmail?.error}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      type='tel'
                      margin='normal'
                      required
                      fullWidth
                      id='adminPhoneNumber'
                      label='N° de portable'
                      name='adminPhoneNumber'
                      autoComplete='tel'
                      onChange={(e) => handleInputChange(e)}
                      value={signupForm.adminPhoneNumber?.value}
                      error={Boolean(signupForm.adminPhoneNumber?.error)}
                      helperText={signupForm.adminPhoneNumber?.error}
                      placeholder='06 ...'
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 6, mb: 2 }}>
                  <Grid item xs={12} style={flexEnd}>
                    {getActionButtons()}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                lg={5}
                order={{ xs: 1, lg: 2 }}
                sx={{
                  backgroundColor: lighten(theme.palette.primary.light, 0.7),
                  borderRadius: '24px',
                  pb: 2,
                  mx: breakDownLg ? 6 : 0,
                  mb: breakDownLg ? 6 : 0,
                }}
              >
                <Box sx={{ height: '100%' }}>
                  <Grid container style={flexCenter}>
                    <Grid item xs={6} style={flexCenter} sx={{ pt: 2 }}>
                      <img src={LogoProLarge} style={{ width: '50%' }} />
                    </Grid>
                    <Grid item xs={6}>
                      <Alert
                        severity='success'
                        icon={<br />}
                        style={flexCenter}
                        sx={{ width: '100%', textTransform: 'none', textAlign: 'center', px: 1 }}
                      >
                        <b>RESPONSABLE GARAGE</b>
                        <br />
                        <Typography variant='body2' style={flexCenter} sx={{ mt: 1 }}>
                          Étape n°1 / 7
                        </Typography>
                      </Alert>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 3, pr: 3 }}>
                      <Typography variant='h6' color={theme.palette.text.secondary}>
                        Grâce à toi, une fois ces étapes terminées, l'activation du service Movalib PRO pour ton client
                        prendra quelques minutes 😊🚀 !<br />
                        <br />
                        Déroule le formulaire, valide l'abonnement <b>AVEC</b> ton client, enregistre l'inscription et
                        on s'occupe de la mise en service. 🏁
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        {currentStep === SignupStep.ROI && (
          <Box>
            <Grid container sx={{ pt: 2, pb: 2, mt: 12 }} columnSpacing={4}>
              <Grid item lg={5} xs={12} order={{ xs: 2, lg: 1 }} sx={{ pr: 6 }}>
                <Grid item xs={12}>
                  <Typography variant='h6' color={theme.palette.text.secondary}>
                    <b>Audit de création de valeur</b>
                  </Typography>
                  <Box sx={{ flexDirection: 'column', display: 'flex', gap: 2 }}>
                    <Typography variant='body1' color={theme.palette.text.secondary} sx={{ textAlign: 'center', mt: 5 }}>
                      <b>Analyse des performances par période</b>
                    </Typography>
                    <Divider sx={{ mt: 1, mb: 1 }} />
                    <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                      <ToggleButtonGroup size="large" sx={{ display: 'flex', }}
                        onChange={(e, value) => handleROIInputChange('period', value)} color='primary' value={ROIForm.period.value} exclusive={true} aria-label="Large sizes">
                        <ToggleButton value="MONTH" key="mois">
                          MOIS
                        </ToggleButton>
                        <ToggleButton value="QUARTER" key="quarter">
                          TRIMESTRE
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>

                    <TextField
                      id='settings-garage-turnover'
                      label="Chiffre d'affaires"
                      value={ROIForm.turnover.value}
                      required
                      type='number'
                      name='turnover'
                      size='small'
                      onChange={(e) => handleROIInputChange('turnover', e.target.value)}
                      error={Boolean(ROIForm.turnover?.error)}
                      helperText={ROIForm.turnover.error}
                      sx={{ width: '55%' }}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, flex: 1 }}>

                      <TextField
                        id='settings-garage-nbInvoice'
                        label="Nombre de factures"
                        value={ROIForm.nbInvoice.value}
                        required
                        type='number'
                        name='nbInvoice'
                        size='small'
                        onChange={(e) => handleROIInputChange('nbInvoice', e.target.value)}
                        error={Boolean(ROIForm.nbInvoice?.error)}
                        helperText={ROIForm.nbInvoice.error}
                        sx={{ width: '50%' }}
                      />

                      <TextField
                        id='settings-garage-nbQuote'
                        label="Nombre de devis"
                        value={ROIForm.nbQuote.value}
                        required
                        type='number'
                        name='nbQuote'
                        size='small'
                        onChange={(e) => handleROIInputChange('nbQuote', e.target.value)}
                        error={Boolean(ROIForm.nbQuote?.error)}
                        helperText={ROIForm.nbQuote.error}
                        sx={{ width: '50%' }}

                      />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, flex: 1 }}>

                      <TextField
                        id='settings-garage-nbNoShow'
                        label="Nombre de RDV non honorés"
                        value={ROIForm.nbNoShow.value}
                        required
                        type='number'
                        name='nbNoShow'
                        size='small'
                        onChange={(e) => handleROIInputChange('nbNoShow', e.target.value)}
                        error={Boolean(ROIForm.nbNoShow?.error)}
                        helperText={ROIForm.nbNoShow.error}
                        sx={{ width: '50%' }}
                      />

                      <TextField
                        id='settings-garage-nbLostQuote'
                        label="Nombre de devis perdus"
                        value={ROIForm.nbLostQuote.value}
                        required
                        type='number'
                        name='nbLostQuote'
                        size='small'
                        onChange={(e) => handleROIInputChange('nbLostQuote', e.target.value)}
                        error={Boolean(ROIForm.nbLostQuote?.error)}
                        helperText={ROIForm.nbLostQuote.error}
                        sx={{ width: '50%' }}
                      />
                    </Box>

                    <Typography variant='body1' color={theme.palette.text.secondary} sx={{ textAlign: 'center', mt: 5 }}>
                      <b>Suivi opérationnel</b>
                    </Typography>
                    <Divider sx={{ mt: 1, mb: 1 }} />
                    <Box sx={{ flexDirection: 'row', display: 'flex', flex: '55%', gap: 2 }}>
                      <TextField
                        id='settings-garage-moRate'
                        label="Taux T1"
                        value={ROIForm.moRateOne.value}
                        required
                        type='number'
                        name='moRate'
                        size='small'
                        onChange={(e) => handleROIInputChange('moRateOne', e.target.value)}
                        error={Boolean(ROIForm.moRateOne?.error)}
                        helperText={ROIForm.moRateOne.error}
                        sx={{ width: '55%' }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">HT / h</InputAdornment>,
                        }}
                      />
                       <TextField
                        id='settings-garage-moRate'
                        label="Taux T2"
                        value={ROIForm.moRateTwo.value}
                        type='number'
                        name='moRate'
                        size='small'
                        onChange={(e) => handleROIInputChange('moRateTwo', e.target.value)}
                        error={Boolean(ROIForm.moRateTwo?.error)}
                        helperText={ROIForm.moRateTwo.error}
                        sx={{ width: '55%' }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">HT / h</InputAdornment>,
                        }}
                      /> <TextField
                      id='settings-garage-moRate'
                      label="Taux T3"
                      value={ROIForm.moRateThree.value}
                      type='number'
                      name='moRate'
                      size='small'
                      onChange={(e) => handleROIInputChange('moRateThree', e.target.value)}
                      error={Boolean(ROIForm.moRateThree?.error)}
                      helperText={ROIForm.moRateThree.error}
                      sx={{ width: '55%' }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">HT / h</InputAdornment>,
                      }}
                    />

                    </Box>
                    <Box sx={{ flexDirection: 'row', display: 'flex', flex: '55%', gap: 2, }} >

                      <TextField
                        id='settings-garage-moOne'
                        label="MO T1"
                        value={ROIForm.moOne.value}
                        required
                        type='number'
                        name='moOne'
                        size='small'
                        onChange={(e) => handleROIInputChange('moOne', e.target.value)}
                        error={Boolean(ROIForm.moOne?.error)}
                        helperText={ROIForm.moOne.error}
                        sx={{ width: '33%' }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">h</InputAdornment>,
                        }}
                      />

                      <TextField
                        id='settings-garage-moTwo'
                        label="MO T2"
                        value={ROIForm.moTwo.value}
                        type='number'
                        name='moTwo'
                        size='small'
                        onChange={(e) => handleROIInputChange('moTwo', e.target.value)}
                        error={Boolean(ROIForm.moTwo?.error)}
                        helperText={ROIForm.moTwo.error}
                        sx={{ width: '33%' }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">h</InputAdornment>,
                        }}
                      />

                      <TextField
                        id='settings-garage-moThree'
                        label="MO T3"
                        value={ROIForm.moThree.value}
                        type='number'
                        name='moThree'
                        size='small'
                        onChange={(e) => handleROIInputChange('moThree', e.target.value)}
                        error={Boolean(ROIForm.moThree?.error)}
                        helperText={ROIForm.moThree.error}
                        sx={{ width: '33%' }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">h</InputAdornment>,
                        }}
                      />
                    </Box>
                    <label htmlFor='comment-roi' style={{ color:theme.palette.text.secondary}}  >
                      <b>Commentaire</b>
                      </label>
                    <textarea
                      id='comment-roi'
                      name='commentRoi'
                      aria-label="commentaire"
                      value={ROIForm.commentRoi.value}
                      onChange={(e) => handleROIInputChange('commentRoi', e.target.value)}
                      rows={3}
                      style={{
                        width: '96%',
                        fontSize: '1rem',
                        fontFamily: 'Outfit, sans-serif',
                        padding: '8px',
                        //resize: 'none', // Désactive le redimensionnement manuel
                      }}
                    />
                  </Box>
                </Grid>
                <Grid container sx={{ mt: 6, mb: 2 }}>
                  <Grid item xs={12} style={flexEnd}>
                    {getActionButtons()}
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                lg={7}
                order={{ xs: 1, lg: 2 }}
                sx={{
                  backgroundColor: lighten(theme.palette.primary.light, 0.7),
                  borderRadius: '24px',
                  pb: 2,
                  pl: '0px !important',
                  mx: breakDownLg ? 6 : 0,
                  mb: breakDownLg ? 6 : 0,
                }}
              >
                <Button
                  variant='contained'
                  color='primary'
                  sx={{ mt: 2, position: 'absolute' }}
                  onClick={() => reactToPrintFn()}>Télécharger</Button>
                <Box sx={{ height: '100%' }}>
                  <Grid container style={flexCenter}>
                    <Grid item xs={6} style={flexCenter} sx={{ pt: 2 }}>
                      <img src={LogoProLarge} style={{ width: '50%' }} />
                    </Grid>
                    <Grid item xs={6}>
                      <Alert
                        severity='success'
                        icon={<br />}
                        style={flexCenter}
                        sx={{ width: '100%', textTransform: 'none', textAlign: 'center', px: 1 }}
                      >
                        <b>ANALYSE ROI DU GARAGE</b>
                        <br />
                        <Typography variant='body2' style={flexCenter} sx={{ mt: 1 }}>
                          Étape n°2 / 7
                        </Typography>
                      </Alert>

                    </Grid>

                    <Grid item xs={12} sx={{ mt: 1, px: 4 }}>
                      <Typography variant='h6' color={theme.palette.text.secondary} sx={{ textAlign: 'center' }}>
                        Les informations saisies permettront de générer un <b>audit de création de valeur</b> sur une période de 1 mois</Typography>
                      <Divider sx={{ mt: 1, mb: 1 }} />

                    </Grid>

                    <Grid item xs={12} sx={{ p: 0, px: 4, backgroundColor: lighten(theme.palette.primary.light, 0.7), }}>
                      <Box sx={{ flexDirection: 'row', justifyContent: 'center', display: 'flex', pb: 1 }}>
                        <Typography sx={{ color: theme.palette.text.secondary }}>
                          <b>Audit de création de valeur</b>
                        </Typography>
                      </Box>

                      <Box sx={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', pb: 1 }}>
                        <Typography sx={{ color: theme.palette.text.secondary }}>
                          <b>Garage</b> : {signupForm.garageName.value}
                        </Typography>
                        <Typography sx={{ color: theme.palette.text.secondary }}>
                          Movalib SAS
                        </Typography>
                      </Box>

                      <Box sx={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', border: '2px solid gray', px: 1 }}>
                        <Typography sx={{ color: theme.palette.text.secondary }}>
                          <b>Expert étude</b> : {connectedUser?.firstname} {connectedUser?.lastname}
                        </Typography>
                        <Typography sx={{ color: theme.palette.text.secondary }}>
                          Date : {new Date().toLocaleDateString('fr-CA').split('-').reverse().join('/')}
                        </Typography>
                      </Box>
                      <Box sx={{ border: '2px solid gray', my: 1, position: 'relative' }}>

                        <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', px: 1 }}>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
                            <b>Analyse du garage</b>
                          </Typography>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '33%' }}>
                          </Typography>
                        </Box>

                        <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', backgroundColor: lighten(theme.palette.primary.main, 0.7), px: 1 }}>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
                            CA
                          </Typography>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
                            {getValueByPeriodOnMonth(ROIForm.turnover.value).toFixed(2)} €
                          </Typography>
                        </Box>

                        <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', px: 1 }}>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray', }}>
                            MO T1
                          </Typography>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
                            {(ROIForm.moRateOne.value * ROIForm.moOne.value).toFixed(2)} €
                          </Typography>
                        </Box>

                        {ROIForm.moTwo.value !== '' && <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', px: 1, backgroundColor: lighten(theme.palette.primary.main, 0.7) }}>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
                            MO T2
                          </Typography>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
                            {(ROIForm.moRateTwo.value * ROIForm.moTwo.value).toFixed(2)} €
                          </Typography>
                        </Box>}

                        {ROIForm.moThree.value !== '' && <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', px: 1 }}>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
                            MO T3
                          </Typography>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
                            {(ROIForm.moRateThree.value * ROIForm.moThree.value).toFixed(2)} €
                          </Typography>
                        </Box>
                        }
                        <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', px: 1, backgroundColor: lighten(theme.palette.primary.main, 0.7) }}>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
                            Factures
                          </Typography>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
                            {getValueByPeriodOnMonth(ROIForm.nbInvoice.value).toFixed(0)}
                          </Typography>
                        </Box>

                        <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', px: 1 }}>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
                            CA / Factures
                          </Typography>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
                            {getCAInvoice()} €
                          </Typography>
                        </Box>
                        <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', px: 1, backgroundColor: lighten(theme.palette.primary.main, 0.7) }}>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
                            Devis
                          </Typography>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
                            {getValueByPeriodOnMonth(ROIForm.nbQuote.value).toFixed(0)}
                          </Typography>
                        </Box>
                        <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', px: 1 }}>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
                            CA / Devis
                          </Typography>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
                            {getCAQuote()} €
                          </Typography>
                        </Box>

                        <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', px: 1, backgroundColor: lighten(theme.palette.primary.main, 0.7) }}>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
                            RDV non honorés
                          </Typography>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
                            {getValueByPeriodOnMonth(ROIForm.nbNoShow.value).toFixed(0)}
                          </Typography>
                        </Box>

                        <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', px: 1 }}>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
                            Devis perdus
                          </Typography>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
                            {getValueByPeriodOnMonth(ROIForm.nbLostQuote.value).toFixed(0)}
                          </Typography>
                        </Box>
                        <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', maxHeight: '24px', px: 1 }}>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray', textAlign: 'end', }}>
                            Total perte mensuelle&nbsp;
                          </Typography>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
                            <b> {totalLost()} €</b>
                          </Typography>
                        </Box>
                        <Divider />

                        <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', minHeight: '24px', px: 1 }}>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
                            <b> Leviers de croissance CA / Mois</b>
                          </Typography>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
                          </Typography>
                        </Box>

                        <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', display: 'flex', px: 1, height: '45px' }}>
                          <TextField value={ratioGrowNoShow} sx={{ flex: '15%', }} type='number' size='small' onChange={(e) => setRatioGrowNoShow(parseFloat(e.target.value))} InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} />
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '62%', borderRight: '1px solid gray', height: '100%', alignContent: 'center' }}>
                            &nbsp; Réduction des RDV non honorés
                          </Typography>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
                            {safeCalculation(getGrowNoShow(), ratioGrowNoShow)} €
                          </Typography>
                        </Box>

                        <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', display: 'flex', px: 1, backgroundColor: lighten(theme.palette.primary.main, 0.7), height: '45px' }}>
                          <TextField value={ratioGrowQuote} sx={{ flex: '15%', }} type='number' size='small' onChange={(e) => setRatioGrowQuote(parseFloat(e.target.value))} InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} />
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '62%', borderRight: '1px solid gray', height: '100%', alignContent: 'center' }}>
                            &nbsp; Augmentation du taux de transformation des devis
                          </Typography>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
                            {safeCalculation(getGrowQuote(), ratioGrowQuote)} €
                          </Typography>
                        </Box>


                        <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', px: 1 }}>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
                            Augmentation du nombre d'heures MO produites
                          </Typography>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>

                          </Typography>
                        </Box>
                        <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', display: 'flex', px: 1, backgroundColor: lighten(theme.palette.primary.main, 0.7), height: '45px' }}>
                          <TextField value={ratioGrowMO1} sx={{ flex: '15%', }} type='number' size='small' onChange={(e) => setRatioGrowMO1(parseFloat(e.target.value))} InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} />
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '62%', borderRight: '1px solid gray', height: '100%', alignContent: 'center' }}>
                            &nbsp;T1
                          </Typography>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
                            {getGrowM01().toFixed(2)} €
                          </Typography>
                        </Box>

                        {ROIForm.moTwo.value !== '' && <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', display: 'flex', px: 1, height: '45px' }}>
                          <TextField value={ratioGrowMO2} sx={{ flex: '15%', }} type='number' size='small' onChange={(e) => setRatioGrowMO2(parseFloat(e.target.value))} InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} />
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '62%', borderRight: '1px solid gray', height: '100%', alignContent: 'center' }}>
                            &nbsp;T2
                          </Typography>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
                            {getGrowM02().toFixed(2)} €
                          </Typography>
                        </Box>}

                        {ROIForm.moThree.value !== '' && <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', display: 'flex', px: 1, backgroundColor: lighten(theme.palette.primary.main, 0.7), height: '45px' }}>
                          <TextField value={ratioGrowMO3} sx={{ flex: '15%', }} type='number' size='small' onChange={(e) => setRatioGrowMO3(parseFloat(e.target.value))} InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} />
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '62%', borderRight: '1px solid gray', height: '100%', alignContent: 'center' }}>
                            &nbsp;T3
                          </Typography>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
                            {getGrowM03().toFixed(2)} €
                          </Typography>
                        </Box>}

                        {/* empty */}
                        <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', minHeight: '24px', px: 1 }}>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
                          </Typography>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
                          </Typography>
                        </Box>

                        <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', maxHeight: '24px', px: 1 }}>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray', textAlign: 'end', }}>
                            Total gain mensuel&nbsp;
                          </Typography>
                          <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
                            <b> {totalGain()} €</b>
                          </Typography>
                        </Box>

                      </Box>
                    </Grid>


                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        {currentStep === SignupStep.SUBSCRIPTION && (
          <Box sx={{ width: '70vw', pt: 9, margin: 'auto', height: '85vh', minHeight: '850px' }}>
            <PricingTools amortization={false} handleOfferChange={(e) => setTmpSubscriptionForm(e)} formData={subscriptionForm} />
            <Box sx={{ position: 'absolute', left: '8px', bottom: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: 'calc(100% - 16px)' }}>
              {getActionButtons()}
            </Box>


          </Box>
        )}

        {currentStep === SignupStep.AMORTIZATION && (
          <Box>
            <Grid container columnSpacing={4} sx={{ pt: 2, pb: 2, mt: 12 }}>
              <Grid item lg={7} xs={12} order={{ xs: 2, lg: 1 }} sx={{ pr: 6 }}>
                <Grid container columnSpacing={4}>
                  <Grid item xs={12}>
                    <Typography variant='h6' color={theme.palette.text.secondary} sx={{ mb: 2 }}>
                      <b>Calcul de l’amortissement</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                  <Typography variant='h6' color={theme.palette.text.secondary} sx={{ mb: 2 }}>
                      Le coût mensuel est de  <b>{subscriptionForm.totalPrice} €</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                      <ToggleButtonGroup size="large" sx={{ display: 'flex', }}
                        disabled={true} color='primary' value={ROIForm.period.value} exclusive={true} aria-label="Large sizes">
                        <ToggleButton value="MONTH" key="mois">
                          MOIS
                        </ToggleButton>
                        <ToggleButton value="QUARTER" key="quarter">
                          TRIMESTRE
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                  </Grid>


                  <Grid item xs={12}>
                    <TextField
                      margin='normal'
                      required
                      fullWidth
                      id='nbInvoice'
                      label='Nombre de factures verifiées'
                      name='nbInvoice'
                      type='number'
                      disabled={true}
                      value={ROIForm.nbInvoice?.value}
                    />
                  </Grid>
                  <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <Grid item sx={{ border: `2px solid ${theme.palette.primary.main}`, borderRadius: 25, display: 'flex', p: '16px !important', }}>
                      <Typography variant='h5' component='h2' style={{ color: darken(theme.palette.text.secondary, 0.4), textAlign: 'center' }}>
                        <b>
                          {safeCalculateTotalPrice()}   €
                        </b>
                      </Typography>
                      <Typography
                        variant='h6'
                        component='h3'
                        style={{ marginLeft: 5, color: darken(theme.palette.text.secondary, 0.4) }}
                      >
                        /rdv <b>*</b>
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
                <Grid container sx={{ mt: 6, mb: 2 }}>
                  <Grid item xs={12} style={flexEnd}>
                    {getActionButtons()}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                lg={5}
                order={{ xs: 1, lg: 2 }}
                sx={{
                  backgroundColor: lighten(theme.palette.primary.light, 0.7),
                  borderRadius: '24px',
                  pb: 2,
                  mx: breakDownLg ? 6 : 0,
                  mb: breakDownLg ? 6 : 0,
                }}
              >
                <Box sx={{ height: '100%' }}>
                  <Grid container style={flexCenter}>
                    <Grid item xs={6} style={flexCenter} sx={{ pt: 2 }}>
                      <img src={LogoProLarge} style={{ width: '50%' }} />
                    </Grid>
                    <Grid item xs={6}>
                      <Alert
                        severity='success'
                        icon={<br />}
                        style={flexCenter}
                        sx={{ width: '100%', textTransform: 'none', textAlign: 'center', px: 1 }}
                      >
                        <b>Amortissement</b>
                        <br />
                        <Typography variant='body2' style={flexCenter} sx={{ mt: 1 }}>
                          Étape n°4 / 7
                        </Typography>
                      </Alert>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 3, pr: 3 }}>
                      <Typography variant='h6' color={theme.palette.text.secondary}>
                      Les clients attendent désormais des services numériques auprès de leur coiffeur, dans les restaurants et dans leurs garages. 
                      c'est la raison pour laquelle, les clients paient pour ce service. <br /><br />

                      Movalib <b>s'engage</b> à suivre l'amortissement ainsi que le retour sur investissement (ROI) des factures, afin de garantir que votre <b>rentabilité</b> soit préservée.<br /><br />

                      Grâce au volume de factures générées, nous serons en mesure de déterminer le coût réel de l'abonnement.<br />
                        <br />
                        Cerise sur le capot, le garage pourra facilement répercuter ce montant sur chaque facture, permettant ainsi <b>d’amortir</b> son abonnement. <br />
                        <br />
                        En effet, les clients bénéficient directement de ces services (prise et suivi des RDV 24h/24, envoi de SMS, digitalisation des devis et factures, etc.), justifiant cet investissement.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        {currentStep === SignupStep.GARAGE && (
          <Box>
            <Grid container sx={{ pt: 2, pb: 2, mt: 9 }} columnSpacing={4}>
              <Grid item lg={7} xs={12} order={{ xs: 2, lg: 1 }} sx={{ pr: 6 }}>
                <Grid item xs={12}>
                  <Typography variant='h6' color={theme.palette.text.secondary}>
                    <b>Adresse du garage</b>
                  </Typography>
                  <AddressFields
                    form={signupAddressForm}
                    size='small'
                    handleChange={(fieldName, newValue) => handleSignupAddressChange(fieldName, newValue)}
                  />
                </Grid>
                <Typography variant='h6' color={theme.palette.text.secondary} sx={{ mt: 2, mb: 3 }}>
                  <b>Coordonnées du garage</b>
                </Typography>
                <Grid item xs={12}>
                  <TextField
                    id='settings-garage-contact-email'
                    label='EMAIL'
                    value={signupForm.garageContactEmail.value}
                    required
                    name='garageContactEmail'
                    size='small'
                    onChange={(e) => handleInputChange(e)}
                    error={Boolean(signupForm.garageContactEmail?.error)}
                    helperText={signupForm.garageContactEmail.error}
                    sx={{ width: '55%' }}
                  />
                  <TextField
                    id='settings-garage-contact-phone'
                    label='N° DE TÉLÉPHONE'
                    value={signupForm.garageContactPhone.value}
                    required
                    name='garageContactPhone'
                    size='small'
                    onChange={(e) => handleInputChange(e)}
                    error={Boolean(signupForm.garageContactPhone?.error)}
                    helperText={signupForm.garageContactPhone.error}
                    sx={{ width: '40%', ml: '5%' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='h6' color={theme.palette.text.secondary} sx={{ mt: 3, mb: 3 }}>
                    <b>Horaires d'ouverture</b>
                  </Typography>
                  <ScheduleFields
                    schedules={signupForm.garageSchedules.value}
                    size='small'
                    onChange={handleScheduleChange}
                    timePickerStep={30}
                  />
                  <FormHelperText sx={{ color: theme.palette.error.main, mt: 1 }}>
                    {signupForm.garageSchedules.error}
                  </FormHelperText>
                </Grid>
                <Grid container sx={{ mt: 6, mb: 2 }}>
                  <Grid item xs={12} style={flexEnd}>
                    {getActionButtons()}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                lg={5}
                order={{ xs: 1, lg: 2 }}
                sx={{
                  backgroundColor: lighten(theme.palette.primary.light, 0.7),
                  borderRadius: '24px',
                  pb: 2,
                  mx: breakDownLg ? 6 : 0,
                  mb: breakDownLg ? 6 : 0,
                }}
              >
                <Box sx={{ height: '100%' }}>
                  <Grid container style={flexCenter}>
                    <Grid item xs={6} style={flexCenter} sx={{ pt: 2 }}>
                      <img src={LogoProLarge} style={{ width: '50%' }} />
                    </Grid>
                    <Grid item xs={6}>
                      <Alert
                        severity='success'
                        icon={<br />}
                        style={flexCenter}
                        sx={{ width: '100%', textTransform: 'none', textAlign: 'center', px: 1 }}
                      >
                        <b>DESCRIPTION DU GARAGE</b>
                        <br />
                        <Typography variant='body2' style={flexCenter} sx={{ mt: 1 }}>
                          Étape n°5 / 7
                        </Typography>
                      </Alert>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 3, pr: 3 }}>
                      <Typography variant='h6' color={theme.palette.text.secondary}>
                        Ces informations seront reprises sur la <b>fiche du garage</b>&nbsp; depuis l'application
                        Movalib, application que les clients utiliseront lors de leur prise de rendez-vous. 🚘 🔧
                      </Typography>
                      <Box style={flexCenter} sx={{ mt: 4, mb: 1 }}>
                        <img src={GarageSheetImage} style={{ width: '350px' }} />
                      </Box>
                    </Grid>
                    <Grid item xs={12} style={flexCenter} sx={{ p: 0 }}>
                      <Typography sx={{ color: theme.palette.text.secondary }}>
                        <i>Exemple de fiche garage</i>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        {currentStep === SignupStep.SETTINGS && (
          <Box>
            <Grid container sx={{ pt: 2, pb: 2, mt: 9 }} columnSpacing={4}>
              <Grid item lg={7} xs={12} order={{ xs: 2, lg: 1 }} sx={{ pr: 6 }}>
                <Grid container columnSpacing={4}>
                  <Grid item xs={12}>
                    <Typography variant='h6' color={theme.palette.text.secondary} sx={{ mt: 3, mb: 1 }}>
                      <b>Mode de prise de rendez-vous</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth margin='normal' required size='small'>
                      <InputLabel id='slot-algorithm-label'>CHOIX DES CRÉNEAUX</InputLabel>
                      <Select
                        labelId='slot-algorithm-label'
                        id='slotAlgorithm'
                        name='slotAlgorithm'
                        size='small'
                        label='Détermination des créneaux'
                        value={signupSettingsForm.slotAlgorithm.value}
                        onChange={(e) => handleSlotAlgorithmChange(e)}
                        error={Boolean(signupSettingsForm.slotAlgorithm.error)}
                      >
                        <MenuItem value={SlotAlgorithm.DAY_PERIOD}>
                          Le client dépose son véhicule le matin et/ou l'après-midi
                        </MenuItem>
                        <MenuItem value={SlotAlgorithm.HOUR}>Le client choisit l'heure précise</MenuItem>
                      </Select>
                      <FormHelperText sx={{ color: theme.palette.error.main }}>
                        {signupSettingsForm.slotAlgorithm.error}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  {signupSettingsForm && signupSettingsForm.slotAlgorithm.value === SlotAlgorithm.DAY_PERIOD && (
                    <Grid container columnSpacing={4} sx={{ pl: 4 }}>
                      <Grid item xs={5}>
                        <Typography variant='subtitle1' color={theme.palette.text.secondary} sx={{ mt: 1, mb: 1 }}>
                          <b>Heure de dépot matin :</b>
                        </Typography>
                        <TimePicker
                          views={['hours', 'minutes']}
                          minutesStep={30} // Valeur par défaut de 30 minutes
                          value={signupSettingsForm.amVehicleDeposit.value}
                          formatDensity='dense'
                          onChange={(value, context) => handleDepositTimeChange('amVehicleDeposit', value)}
                          slotProps={{
                            textField: {
                              size: 'small',
                              sx: {
                                width: '150px',
                                minWidth: '100px',
                                padding: 0,
                              },
                            },
                            actionBar: {
                              sx: { display: 'none' },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant='subtitle1' color={theme.palette.text.secondary} sx={{ mt: 1, mb: 1 }}>
                          <b>Heure de dépot après-midi :</b>
                        </Typography>
                        <TimePicker
                          views={['hours', 'minutes']}
                          minutesStep={30} // Valeur par défaut de 30 minutes
                          value={signupSettingsForm.pmVehicleDeposit.value}
                          formatDensity='dense'
                          onChange={(value, context) => handleDepositTimeChange('pmVehicleDeposit', value)}
                          slotProps={{
                            textField: {
                              size: 'small',
                              sx: {
                                width: '150px',
                                minWidth: '100px',
                                padding: 0,
                              },
                            },
                            actionBar: {
                              sx: { display: 'none' },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormHelperText sx={{ color: theme.palette.error.main, pl: 2 }}>
                          {signupSettingsForm.amVehicleDeposit.error}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid container sx={{ mt: 6, mb: 2 }}>
                  <Grid item xs={12} style={flexEnd}>
                    {getActionButtons()}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                lg={5}
                order={{ xs: 1, lg: 2 }}
                sx={{
                  backgroundColor: lighten(theme.palette.primary.light, 0.7),
                  borderRadius: '24px',
                  pb: 2,
                  mx: breakDownLg ? 6 : 0,
                  mb: breakDownLg ? 6 : 0,
                }}
              >
                <Box sx={{ height: '100%' }}>
                  <Grid container style={flexCenter}>
                    <Grid item xs={6} style={flexCenter} sx={{ pt: 2 }}>
                      <img src={LogoProLarge} style={{ width: '50%' }} />
                    </Grid>
                    <Grid item xs={6}>
                      <Alert
                        severity='success'
                        icon={<br />}
                        style={flexCenter}
                        sx={{ width: '100%', textTransform: 'none', textAlign: 'center', px: 1 }}
                      >
                        <b>PRISE DE RENDEZ-VOUS</b>
                        <br />
                        <Typography variant='body2' style={flexCenter} sx={{ mt: 1 }}>
                          Étape n°6 / 7
                        </Typography>
                      </Alert>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 3, pr: 3 }}>
                      <Typography variant='h6' color={theme.palette.text.secondary}>
                        Le garage <b>choisit</b> de quelle façon ses clients <b>prennent rendez-vous</b>.
                      </Typography>
                      <Grid container style={flexCenter} sx={{ mt: 4, mb: 1 }}>
                        <Grid item xs={6} style={flexCenter} direction={'column'}>
                          <Typography color={theme.palette.text.primary}>
                            <b>Horaires de dépôts (ex: 9h / 14h)</b>
                          </Typography>
                          <br />
                          <img src={DayPeriodImage} style={{ width: '270px' }} />
                        </Grid>
                        <Grid item xs={6} style={flexCenter} direction={'column'}>
                          <Typography color={theme.palette.text.primary}>
                            <b>À l'heure précise</b>
                          </Typography>
                          <br />
                          <img src={HourImage} style={{ width: '280px' }} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        {currentStep === SignupStep.BILLING && (
          <Box>
            <Grid container sx={{ pt: 2, pb: 2, mt: 24 }} columnSpacing={4}>
              <Grid item lg={6} xs={12} order={{ xs: 2, lg: 1 }} sx={{ pr: 6 }}>
                <Grid container columnSpacing={4}>
                  <Grid item xs={8}>
                    <Typography variant='h6' color={theme.palette.text.secondary}>
                      <b>Souscription</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ pt: 1 }}>
                    <TextField
                      id='subscription-comment'
                      label='Commentaire ...'
                      value={signupSettingsForm.subscriptionComment?.value}
                      onChange={(e) => handleSignupSettingsChange('subscriptionComment', e.target.value)}
                      multiline
                      rows={4}
                      variant='outlined'
                      size='small'
                      error={Boolean(signupSettingsForm.subscriptionComment?.error)}
                      helperText={signupSettingsForm.subscriptionComment?.error}
                      fullWidth
                      sx={{ width: '97%' }}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ pt: 2 }}>
                    <Typography variant='h6' color={theme.palette.text.secondary}>
                      <b>Mandat de prélèvement</b>
                    </Typography>
                    <Typography color={theme.palette.primary.main} sx={{ fontFamily: 'CarterOne', fontSize: '0.9rem' }}>
                      Votre client va recevoir un mail d'acceptation du mandat SEPA (son RIB sera nécessaire)
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={flexCenter} sx={{ mt: 2 }}>
                    <LoadingButton
                      loading={loading}
                      variant='contained'
                      color='inherit'
                      sx={{ width: '50%', mr: 4 }}
                      onClick={handleSendMandate}
                    >
                      <MandateIcon style={{ fontSize: '1rem', marginRight: 3 }} />
                      &nbsp;ENVOYER LE MANDAT AU CLIENT (EMAIL)
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={12} sx={{ pt: 4 }}>
                    <Typography variant='h6' color={theme.palette.text.secondary}>
                      <b>Onboarding - Prochains rendez-vous planifiés</b>
                    </Typography>
                    <Typography color={theme.palette.primary.main} sx={{ fontFamily: 'CarterOne', fontSize: '0.9rem' }}>
                      Visio d'une demi-heure avec le client, gérée par Movalib
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <DatePicker
                      label={"Date de mise en service"}
                      name={'startDate'}
                      format='dd/MM/yyyy'
                      value={signupSettingsForm.commissioningPreference?.value}
                      onChange={(e) => handleSignupSettingsChange('commissioningPreference', e)}
                      formatDensity='dense'
                      views={['day']}
                      displayWeekNumber
                      slotProps={{
                        textField: {
                          helperText: signupSettingsForm.commissioningPreference?.error,
                          error: !!signupSettingsForm.commissioningPreference?.error,
                          required: true,
                          sx: {
                            padding: 0,
                          },
                        },
                      }}
                    />
                    <DatePicker
                      label={"Date de formation 1"}
                      name={'startDate'}
                      format='dd/MM/yyyy'
                      formatDensity='dense'
                      value={signupSettingsForm.trainingOptionalOne?.value}
                      onChange={(e) => handleSignupSettingsChange('trainingOptionalOne', e)}
                      views={['day']}
                      displayWeekNumber
                      slotProps={{
                        textField: {
                          error: !!signupSettingsForm.trainingOptionalOne?.error,
                          sx: {
                            padding: 0,
                          },
                        },
                      }}
                    />

                    <DatePicker
                      label={"Date de formation 2"}
                      name={'startDate'}
                      format='dd/MM/yyyy'
                      formatDensity='dense'
                      value={signupSettingsForm.trainingOptionalTwo?.value}
                      onChange={(e) => handleSignupSettingsChange('trainingOptionalTwo', e)}
                      views={['day']}
                      displayWeekNumber
                      slotProps={{
                        textField: {
                          required: false,
                          error: !!signupSettingsForm.trainingOptionalTwo?.error,
                          sx: {
                            padding: 0,
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 6, mb: 2 }}>
                  <Grid item xs={12} style={flexEnd}>
                    {getActionButtons()}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                lg={6}
                order={{ xs: 1, lg: 2 }}
                sx={{
                  backgroundColor: lighten(theme.palette.primary.light, 0.7),
                  borderRadius: '24px',
                  pb: 2,
                  mx: breakDownLg ? 6 : 0,
                  mb: breakDownLg ? 6 : 0,
                  mt: breakDownLg ? 34 : 0,
                }}
              >
                <PricingTable
                  formData={subscriptionForm}
                />
              </Grid>
            </Grid>
          </Box>
        )}

        {currentStep === SignupStep.SUMMARY && (
          <Box>
            <Grid container sx={{ pb: 2, mt: 12 }}>
              <Grid item xs={12}>
                <Grid item xs={12} sx={{ backgroundColor: 'transparen', borderRadius: '24px', pb: 2 }}>
                  <Box>
                    <Grid container style={flexCenter}>
                      <Grid item xs={6} style={flexCenter} sx={{ pt: 2 }}>
                        <img src={LogoProLarge} style={{ width: '30%' }} />
                      </Grid>
                      <Grid item xs={6}>
                        <Alert
                          severity='success'
                          style={flexCenter}
                          icon={<SummaryIcon />}
                          className='styled-alert'
                          sx={{ width: '80%', textTransform: 'none', textAlign: 'center', px: 1, mt: 2 }}
                        >
                          <b>RÉCAPITULATIF</b>
                        </Alert>
                      </Grid>
                      <Grid item xs={5} style={flexEnd} sx={{ pr: 6, mt: 4 }}>
                        <Typography variant='h6' color={theme.palette.text.secondary}>
                          <b>Administrateur du compte</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={7} sx={{ mt: 4 }}>
                        <Typography variant='body1' color={theme.palette.text.secondary}>
                          <b>
                            {signupForm.adminFirstname?.value}&nbsp;&nbsp;{signupForm.adminLastname?.value}
                          </b>
                          <br />
                          N° de téléphone : <b>{formatPhoneNumber(signupForm.adminPhoneNumber?.value)}</b>
                          <br />
                          Email de connexion : <b>{signupForm.adminEmail?.value}</b>
                          <br />
                        </Typography>
                      </Grid>
                      <Grid item xs={10} sx={{ border: '1px solid #F29ABA', mt: 3 }} />
                      <Grid item xs={5} style={flexEnd} sx={{ pr: 6, mt: 4 }}>
                        <Typography variant='h6' color={theme.palette.text.secondary}>
                          <b>Description du garage</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={7} sx={{ mt: 4 }}>
                        <Typography variant='body1' color={theme.palette.text.secondary}>
                          <b>{signupForm.garageName?.value}</b>
                          <br />
                          <Typography variant='body1' color='text.secondary'>
                            {signupAddressForm && (
                              <>
                                <b>
                                  {signupAddressForm.streetName && `${signupAddressForm.streetName.value} `} <br />
                                  {signupAddressForm.additional && `${signupAddressForm.additional.value} `}
                                  {signupAddressForm.postalCode && `${signupAddressForm.postalCode.value} `}
                                  {signupAddressForm.cityName && `${signupAddressForm.cityName.value}`}
                                </b>
                              </>
                            )}
                          </Typography>
                          <div style={{ paddingBottom: 10 }}>
                            Contact (email / tel) :{' '}
                            <b>
                              {signupForm.garageContactEmail?.value} /{' '}
                              {formatPhoneNumber(signupForm.garageContactPhone?.value)}{' '}
                            </b>
                            <br />
                          </div>
                          <div style={{ paddingBottom: 10 }}>
                            Horaires d'ouverture : <br />
                          </div>
                          {Object.keys(DayOfWeek).map((day, index) => (
                            <Typography variant='body1' sx={{ textAlign: 'left' }}>
                              <b>
                                {signupForm.garageSchedules.value &&
                                  getFormattedSchedule(
                                    findScheduleByDayOfWeek(signupForm.garageSchedules.value, index),
                                    index,
                                  )}
                              </b>
                            </Typography>
                          ))}
                          <br />
                        </Typography>
                      </Grid>
                      <Grid item xs={10} sx={{ border: '1px solid #F29ABA', mt: 1 }} />
                      <Grid item xs={5} style={flexEnd} sx={{ pr: 6, mt: 4 }}>
                        <Typography variant='h6' color={theme.palette.text.secondary}>
                          <b>Configuration du garage</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={7} sx={{ mt: 4 }}>
                        <Typography variant='body1' color={theme.palette.text.secondary}>
                          Effectif à l'atelier : <b>{signupSettingsForm.workforce?.value}</b>
                          <br />
                          {signupSettingsForm.workforcePartial?.value && (
                            <>
                              Nombre d’alternants / carrossiers : <b>{signupSettingsForm.workforcePartial.value}</b>
                              <br />
                            </>
                          )}

                      
                              CA annuel : <b>{ROIForm.turnover.value * (ROIForm.period.value === 'MONTH' ? 12 : 4)} €</b>
                              <br />
                          
                          Nombre de factures : <b>{ROIForm.nbInvoice?.value}</b> par <b>{ROIForm.period.value === 'MONTH' ? 'mois' : 'trimestre'}</b>
                          <br />
                          Mode de prise de rendez-vous :{' '}
                          {signupSettingsForm.slotAlgorithm?.value === SlotAlgorithm.DAY_PERIOD ? (
                            <>
                              <b> Le client dépose son véhicule le matin et/ou l'après-midi &nbsp;&nbsp;- </b>
                              {signupSettingsForm.amVehicleDeposit?.value && (
                                <>
                                  &nbsp;&nbsp;(
                                  <b>
                                    {formatDateByCountryCode(
                                      signupSettingsForm.amVehicleDeposit?.value,
                                      'FR',
                                      DateFormatTypes.TIME_ONLY,
                                    )}
                                    )
                                  </b>
                                </>
                              )}
                              {signupSettingsForm.pmVehicleDeposit?.value && (
                                <>
                                  &nbsp;&nbsp;/&nbsp;&nbsp; (
                                  <b>
                                    {formatDateByCountryCode(
                                      signupSettingsForm.pmVehicleDeposit?.value,
                                      'FR',
                                      DateFormatTypes.TIME_ONLY,
                                    )}
                                    )
                                  </b>
                                </>
                              )}
                            </>
                          ) : (
                            <b>Le client choisit l'heure précise</b>
                          )}{' '}
                          <br />
                        </Typography>
                      </Grid>
                      <Grid item xs={10} sx={{ border: '1px solid #F29ABA', mt: 4 }} />
                      <Grid item xs={3} style={flexEnd} sx={{ pr: 6, mt: 4 }}>
                        <Typography variant='h6' color={theme.palette.text.secondary}>
                          <b>Souscription abonnement</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={9} sx={{ mt: 4 }}>
                        <Box sx={{ height: '850px', }}>

                          <PricingTools selectable={false} amortization={false} withIcon={false} onlyOne={true} formData={subscriptionForm} />
                        </Box>
                      </Grid>

                      <Grid item xs={10} sx={{ border: '1px solid #F29ABA', mt: 1 }} />
                      <Grid item xs={5} style={flexEnd} sx={{ pr: 6, mt: 4 }}>
                        <Typography variant='h6' color={theme.palette.text.secondary}>
                          <b>Prochaines étapes</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={7} sx={{ mt: 4 }}>
                        <Typography variant='body1' color={theme.palette.text.secondary}>
                          Mise en service : <b>{formatDate(signupSettingsForm.commissioningPreference.value)}</b>
                          <br />
                          {signupSettingsForm.trainingOptionalOne?.value && (
                            <>
                              Formation n°1 : <b>{formatDate(signupSettingsForm.trainingOptionalOne.value)}</b>
                              <br />
                            </>
                          )}
                          {signupSettingsForm.trainingOptionalTwo?.value && (
                            <>
                              Formation n°2 : <b>{formatDate(signupSettingsForm.trainingOptionalTwo.value)}</b>
                              <br />
                            </>
                          )}

                        </Typography>
                      </Grid>



                      <Alert
                        icon={<br />}
                        style={flexCenter}
                        severity='success'
                        sx={{ mt: 6, width: '90%', textAlign: 'center', fontSize: 18 }}
                        className='styled-alert'
                      >
                        Et voilà, tu n'as plus rien à faire, Movalib s'occupe de la <b>mise en service</b>. <br />
                        Merci, beau travail partenaire 😉
                      </Alert>
                      <Grid item xs={12} style={flexCenter} sx={{ mt: 6, pb: 2 }}>
                        {getActionButtons()}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    );
  };

  const printableROISheet = () => {
    return (<Grid item xs={12} sx={{ p: 0, px: 4, backgroundColor: lighten(theme.palette.primary.light, 0.7), width: '100vw', height: '100vh', justifyContent: 'center', display: 'flex', flexDirection: 'column' }} className='printable-content' ref={contentRef}>
    <Box sx={{ flexDirection: 'row', justifyContent: 'center', display: 'flex', pb: 1 }}>
      <Typography sx={{ color: theme.palette.text.secondary }}>
        <b>Audit de création de valeur</b>
      </Typography>
    </Box>

    <Box sx={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', pb: 1 }}>
      <Typography sx={{ color: theme.palette.text.secondary }}>
        <b>Garage</b> : {signupForm.garageName.value}
      </Typography>
      <Typography sx={{ color: theme.palette.text.secondary }}>
        Movalib SAS
      </Typography>
    </Box>

    <Box sx={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', border: '2px solid gray', px: 1 }}>
      <Typography sx={{ color: theme.palette.text.secondary }}>
        <b>Expert étude</b> : {connectedUser?.firstname} {connectedUser?.lastname}
      </Typography>
      <Typography sx={{ color: theme.palette.text.secondary }}>
        Date : {new Date().toLocaleDateString('fr-CA').split('-').reverse().join('/')}
      </Typography>
    </Box>
    <Box sx={{ border: '2px solid gray', my: 1, position: 'relative' }}>

      <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', px: 1 }}>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
          <b>Analyse du garage</b>
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '33%' }}>
        </Typography>
      </Box>

      <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', backgroundColor: lighten(theme.palette.primary.main, 0.7), px: 1, marginRight :'1px' }}>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray',  }}>
          CA
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
          {getValueByPeriodOnMonth(ROIForm.turnover.value).toFixed(2)} €
        </Typography>
      </Box>

      <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', px: 1 }}>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray', }}>
          MO T1
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
          {(ROIForm.moRateOne.value * ROIForm.moOne.value).toFixed(2)} €
        </Typography>
      </Box>

      {ROIForm.moTwo.value !== '' && <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', px: 1, backgroundColor: lighten(theme.palette.primary.main, 0.7), marginLeft: '1px', marginRight :'1px' }}>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
          MO T2
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
          {(ROIForm.moRateTwo.value * ROIForm.moTwo.value).toFixed(2)} €
        </Typography>
      </Box>}

      {ROIForm.moThree.value !== '' && <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', px: 1 }}>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
          MO T3
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
          {(ROIForm.moRateThree.value * ROIForm.moThree.value).toFixed(2)} €
        </Typography>
      </Box>
      }
      <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', px: 1, backgroundColor: lighten(theme.palette.primary.main, 0.7), marginLeft: '1px', marginRight :'1px' }}>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
          Factures
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
          {getValueByPeriodOnMonth(ROIForm.nbInvoice.value).toFixed(0)}
        </Typography>
      </Box>

      <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', px: 1 }}>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
          CA / Factures
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
          {getCAInvoice()} €
        </Typography>
      </Box>
      <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', px: 1, backgroundColor: lighten(theme.palette.primary.main, 0.7), marginLeft: '1px',  marginRight :'1px' }}>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
          Devis
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
          {getValueByPeriodOnMonth(ROIForm.nbQuote.value).toFixed(0)}
        </Typography>
      </Box>
      <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', px: 1 }}>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
          CA / Devis
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
          {getCAQuote()} €
        </Typography>
      </Box>

      <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', px: 1, backgroundColor: lighten(theme.palette.primary.main, 0.7), marginLeft: '1px',  marginRight :'1px' }}>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
          RDV non honorés
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
          {getValueByPeriodOnMonth(ROIForm.nbNoShow.value).toFixed(0)}
        </Typography>
      </Box>

      <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', px: 1 }}>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
          Devis perdus
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
          {getValueByPeriodOnMonth(ROIForm.nbLostQuote.value).toFixed(0)}
        </Typography>
      </Box>
      <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', maxHeight: '24px', px: 1 }}>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray', textAlign: 'end', }}>
          Total perte mensuelle&nbsp;
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
          <b> {totalLost()} €</b>
        </Typography>
      </Box>
      <Divider />

      <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', minHeight: '24px', px: 1 }}>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
          <b> Leviers de croissance CA / Mois</b>
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
        </Typography>
      </Box>

      <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', display: 'flex', px: 1, height: '45px' }}>

        <Typography sx={{ flex: '15%'}} > {ratioGrowNoShow} % </Typography>

        <Typography sx={{ color: theme.palette.text.secondary, flex: '62%', borderRight: '1px solid gray', height: '100%', alignContent: 'center' }}>
          &nbsp; Réduction des RDV non honorés
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
          {safeCalculation(getGrowNoShow(), ratioGrowNoShow)} €
        </Typography>
      </Box>

      <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', display: 'flex', px: 1,backgroundColor: lighten(theme.palette.primary.main, 0.7), height: '45px', marginLeft: '1px',  marginRight :'1px' }}>

        <Typography sx={{ flex: '15%' }} > {ratioGrowQuote} % </Typography>

        <Typography sx={{ color: theme.palette.text.secondary, flex: '62%', borderRight: '1px solid gray', height: '100%', alignContent: 'center' }}>
          &nbsp; Augmentation du taux de transformation des devis
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
          {safeCalculation(getGrowQuote(), ratioGrowQuote)} €
        </Typography>
      </Box>


      <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', px: 1 }}>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
          Augmentation du nombre d'heures MO produites
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>

        </Typography>
      </Box>
      <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', display: 'flex', px: 1, backgroundColor: lighten(theme.palette.primary.main, 0.7), height: '45px', marginLeft: '1px', marginRight :'1px' }}>
      <Typography sx={{ flex: '15%'}} > {ratioGrowMO1} % </Typography>
      <Typography sx={{ color: theme.palette.text.secondary, flex: '62%', borderRight: '1px solid gray', height: '100%', alignContent: 'center' }}>
          &nbsp;T1
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
          {getGrowM01().toFixed(2)} €
        </Typography>
      </Box>

      {ROIForm.moTwo.value !== '' && <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', display: 'flex', px: 1, height: '45px' }}>
 
        <Typography sx={{ flex: '15%'}} > {ratioGrowMO2} % </Typography>

        <Typography sx={{ color: theme.palette.text.secondary, flex: '62%', borderRight: '1px solid gray', height: '100%', alignContent: 'center' }}>
          &nbsp;T2
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
          {getGrowM02().toFixed(2)} €
        </Typography>
      </Box>}

      {ROIForm.moThree.value !== '' && <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', display: 'flex', px: 1, backgroundColor: lighten(theme.palette.primary.main, 0.7), height: '45px',  marginRight :'1px' }}>
        <Typography sx={{ flex: '15%'}} > {ratioGrowMO3} % </Typography>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '62%', borderRight: '1px solid gray', height: '100%', alignContent: 'center' }}>
          &nbsp;T3
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
          {getGrowM03().toFixed(2)} €
        </Typography>
      </Box>}

      {/* empty */}
      <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', minHeight: '24px', px: 1 }}>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray' }}>
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
        </Typography>
      </Box>

      <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex', maxHeight: '24px', px: 1 }}>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '77%', borderRight: '1px solid gray', textAlign: 'end', }}>
          Total gain mensuel&nbsp;
        </Typography>
        <Typography sx={{ color: theme.palette.text.secondary, flex: '33%', textAlign: 'end' }}>
          <b> {totalGain()} €</b>
        </Typography>
      </Box>

    </Box>
  </Grid>);
  }

  return (
    <>
      <div style={flexCenter}>
        <img
          src={GreenLeafImage}
          style={{
            position: 'fixed',
            float: 'left',
            width: '250px',
            height: '400px',
            top: '-20%',
            left: '0%',
            opacity: '0.3',
            display: connectedUser ? 'block' : 'none',
            zIndex: -8,
          }}
          alt='Feuille Verte Movalib'
        />

        <Container
          component='main'
          maxWidth='xl'
          style={
            currentStep !== SignupStep.GARAGE &&
              currentStep !== SignupStep.SETTINGS &&
              currentStep !== SignupStep.SUMMARY
              ? centered
              : {}
          }
        >
          <CssBaseline />

          {connectedUser && isSignupAuthorized(connectedUser) && (
            <>
              <SignUpHeader
                title={selectedGarageId ? `${signupForm.garageName.value}` : 'Nouvelle inscription'}
                connectedUser={connectedUser}
                onPageClick={handlePageClick}
              />
              {getSignUpForm()}
              {printableROISheet()}
            </>
          )}
        </Container>

        <img
          src={PinkLeafImage}
          style={{
            position: 'fixed',
            float: 'right',
            width: '250px',
            height: '400px',
            bottom: '-20%',
            right: '0%',
            opacity: '0.3',
            display: connectedUser ? 'block' : 'none',
            zIndex: '-10',
          }}
          alt='Feuille Rose Movalib'
        />
      </div>

      <ConfirmationDialog
        open={openConfirmSignupDialog}
        onClose={handleCloseConfirmSignup}
        onConfirm={handleConfirmSignup}
        message={
          <Grid container style={flexCenter}>
            <Grid item xs={12}>
              <ConfirmIcon style={{ fontSize: '2.5rem', color: darken(theme.palette.primary.main, 0.1) }} />
            </Grid>
            <Typography variant='h6'>
              Tu confirmes que <b>le mandat a bien été accepté</b> par le client ?
            </Typography>
          </Grid>
        }
      />
    </>
  );
};

export default SignUp;
