import { OrderPreference } from "@movalib/movalib-commons";

const PurchaseOrder = (params: any) => {
    params = params?.param ?? {};
    return (
        <table cellSpacing="0" cellPadding="0" role="presentation" className="nl2go-body-table" width="100%" style={{ backgroundColor: "#fff", width: "100%", marginRight: '24px' }}>
            <tr>
                <td>
                    <table
                        cellSpacing="0"
                        cellPadding="0"
                        role="presentation"

                        align="center"
                        className="r0-o"
                        style={{ tableLayout: "fixed", width: "800px" }}
                    >
                        <tr>
                            <td valign="top" className="r1-i" style={{ backgroundColor: "#fff" }}>
                                <table
                                    cellSpacing="0"
                                    cellPadding="0"
                                    role="presentation"
                                    align="center"
                                    className="r3-o"
                                    style={{ tableLayout: "fixed", width: "100%" }}
                                >                                        <tr>
                                        <td className="r4-i" style={{ backgroundColor: "#fff", paddingTop: "20px" }}>
                                            <table cellSpacing="0" cellPadding="0" role="presentation" style={{ width: "100%" }}>
                                                <tr>
                                                    <th


                                                        className="r5-c"
                                                        style={{ backgroundColor: "#fff", fontWeight: 400, width: "100%", verticalAlign: 'top' }}>
                                                        <table
                                                            cellSpacing="0"
                                                            cellPadding="0"
                                                            role="presentation"
                                                            width="100%"
                                                            className="r6-o"
                                                            style={{ tableLayout: "fixed", width: "100%" }}
                                                        >                                                                <tr>
                                                                <td className="r7-i" style={{ backgroundColor: "#fff", verticalAlign: 'top' }}>
                                                                    <table width="100%" cellSpacing="0" cellPadding="0" role="presentation">
                                                                        <tr>
                                                                            <td
                                                                                className="r8-c nl2go-default-textstyle"
                                                                                style={{
                                                                                    color: "#3b3f44",
                                                                                    fontFamily: "Outfit, Arial",
                                                                                    fontSize: "14px",
                                                                                    lineHeight: "14px",
                                                                                    wordBreak: "break-word",
                                                                                }}
                                                                            >
                                                                                <center>
                                                                                    <div
                                                                                        style={{
                                                                                            textAlign: "center",
                                                                                            width: "95%",
                                                                                            borderBottom: "8px solid #bcd46c",
                                                                                            borderTop: "8px solid #bcd46c",
                                                                                            borderRadius: "20px",

                                                                                        }}
                                                                                    >
                                                                                        <p style={{ fontSize: "20px", lineHeight: '20px', marginBlockEnd: 0, marginBlockStart: '8px' }}>
                                                                                            <b>COMMANDE</b>
                                                                                        </p>
                                                                                        <p style={{ fontSize: "18px", lineHeight: '18px', marginBlockStart: 0, marginBlockEnd: '8px' }}>
                                                                                            Par {params?.garageName}
                                                                                        </p>                                                                                    </div>
                                                                                </center>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </th>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                <table cellSpacing="0" cellPadding="0" role="presentation" width="100%" align="center" className="r3-o" style={{ tableLayout: "fixed", width: "95%" }}
                                >
                                    <tr>
                                        <td className="r10-i" style={{ backgroundColor: "#fff", paddingTop: "20px" }}
                                        >
                                            <table width="100%" cellSpacing="0" cellPadding="0" role="presentation">
                                                <tr>
                                                    <th
                                                        className="r11-c"
                                                        style={{ fontWeight: 400, width: '100%', verticalAlign: 'middle' }}
                                                    >
                                                        <table
                                                            cellSpacing="0"
                                                            cellPadding="0"
                                                            role="presentation"
                                                            width="100%"
                                                            align="left"
                                                            className="r12-o"
                                                            style={{ tableLayout: "fixed", width: "100%" }}
                                                        >
                                                            <tr>
                                                                <td
                                                                    align="left"
                                                                    valign="top"
                                                                    className="r13-i nl2go-default-textstyle"
                                                                    style={{
                                                                        color: "#3b3f44",
                                                                        fontFamily: "Outfit, Arial",
                                                                        fontSize: "14px",
                                                                        wordBreak: "break-word",
                                                                        lineHeight: "14px",
                                                                        paddingTop: "5px",
                                                                        textAlign: "left",
                                                                    }}
                                                                >
                                                                    <div>
                                                                        <div
                                                                            className="sib_className_20_black_roboto"
                                                                            style={{
                                                                                color: "#000",
                                                                                fontFamily: "Outfit, Arial",
                                                                                fontSize: "20px",
                                                                                wordBreak: "break-word",
                                                                            }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color: "#404049",
                                                                                    fontFamily: "Outfit, Arial",
                                                                                    fontSize: "18px",
                                                                                    lineHeight: '18px',
                                                                                    display: "block",
                                                                                }}
                                                                            >
                                                                                Hello partenaire de {params?.garageName} 👋
                                                                            </span>
                                                                            <br />
                                                                            <span
                                                                                style={{
                                                                                    color: "#404049",
                                                                                    fontFamily: "Outfit, Arial",
                                                                                    fontSize: "16px",
                                                                                    lineHeight: '20px',
                                                                                    display: "block",
                                                                                }}
                                                                            >
                                                                                Ce <strong>BON DE COMMANDE</strong> vous est adressé via{" "}
                                                                                <strong>Movalib Pro,</strong> solution utilisée par le garage
                                                                                pour l'accompagner dans son quotidien.
                                                                            </span>
                                                                            <br />
                                                                            <span
                                                                                style={{
                                                                                    color: "#404049",
                                                                                    fontFamily: "Outfit, Arial",
                                                                                    fontSize: "16px",
                                                                                    lineHeight: '20px',

                                                                                }}
                                                                            >
                                                                                Contactez-nous pour profiter de nos fonctionnalités et devenir{" "}
                                                                                <strong>distributeur</strong> officiel de notre solution, les
                                                                                contrats sont prêts ! 😉
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </th>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                <table
                                    cellSpacing="0"
                                    cellPadding="0"
                                    role="presentation"
                                    width="100%"
                                    align="center"
                                    className="r3-o"
                                    style={{ tableLayout: "fixed", width: "100%", marginLeft: '24px' }}
                                >
                                    <tr>
                                        <td
                                            className="r15-i"
                                            style={{ backgroundColor: "#fff", paddingTop: "20px" }}
                                        >
                                            <table width="100%" cellSpacing="0" cellPadding="0" role="presentation">
                                                <tr>
                                                    <th
                                                        className="r5-c"
                                                        style={{ fontWeight: 400, width: "100%", verticalAlign: "top" }}
                                                    >
                                                        <table
                                                            cellSpacing="0"
                                                            cellPadding="0"
                                                            role="presentation"
                                                            width="100%"
                                                            className="r6-o"
                                                            style={{ tableLayout: "fixed", width: "100%" }}
                                                        >
                                                            <tr>
                                                                <td
                                                                    valign="top"
                                                                    className="r16-i"
                                                                    style={{ paddingLeft: "15px", paddingRight: "15px" }}
                                                                >
                                                                    <table
                                                                        width="100%"
                                                                        cellSpacing="0"
                                                                        cellPadding="0"
                                                                        role="presentation"
                                                                    >
                                                                        <tr>
                                                                            <td
                                                                                className="r17-c nl2go-default-textstyle"
                                                                                align="left"
                                                                                style={{
                                                                                    color: "#3b3f44",
                                                                                    fontFamily: "Outfit, Arial",
                                                                                    fontSize: "14px",
                                                                                    wordBreak: "break-word",
                                                                                    lineHeight: "14px",
                                                                                    textAlign: "left",
                                                                                    verticalAlign: "top",
                                                                                }}
                                                                            >
                                                                                <div>
                                                                                    <h3
                                                                                        className="default-heading3"
                                                                                        style={{
                                                                                            margin: 0,
                                                                                            color: "#000",
                                                                                            fontFamily: "Outfit, Arial",
                                                                                            fontSize: "18px",
                                                                                            wordBreak: "break-word",
                                                                                        }}
                                                                                    >
                                                                                        <span style={{ color: "#404049" }}>
                                                                                            📦 Bon de commande
                                                                                        </span>
                                                                                    </h3>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </th>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>

                                <table
                                    cellSpacing="0"
                                    cellPadding="0"
                                    role="presentation"
                                    width="100%"
                                    align="center"
                                    className="r18-o"
                                    style={{ tableLayout: "fixed", width: "100%", marginLeft: '24px', }}
                                >
                                    <tr>
                                        <td className="r19-i" style={{ backgroundColor: "#fff" }}>
                                            <table
                                                width="100%"
                                                cellSpacing="0"
                                                cellPadding="0"
                                                role="presentation"
                                            >
                                                <tr>
                                                    <th
                                                        className="r5-c"
                                                        style={{ fontWeight: 400, width: "100%", verticalAlign: "top" }}
                                                    >
                                                        <table
                                                            cellSpacing="0"
                                                            cellPadding="0"
                                                            role="presentation"
                                                            className="r6-o"
                                                            style={{ tableLayout: "fixed", width: "100%" }}
                                                        >
                                                            <tr>
                                                                <td
                                                                    className="r20-i nl2go-default-textstyle"
                                                                    style={{
                                                                        color: "#3b3f44",
                                                                        fontFamily: "Outfit, Arial",
                                                                        fontSize: "16px",
                                                                        lineHeight: "16px",
                                                                        wordBreak: "break-word",
                                                                    }}
                                                                >
                                                                    <center>
                                                                        <div
                                                                            style={{
                                                                                textAlign: "center",
                                                                                width: "95%",
                                                                                borderBottom: "8px solid #f29aba",
                                                                                borderRadius: "20px",
                                                                                padding: 0,
                                                                                paddingBottom: "15px",
                                                                                marginRight: '36px' 
                                                                            }}
                                                                        />
                                                                    </center>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </th>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr className="nl2go-responsive-hide">
                                        <td
                                            height="15"
                                            style={{
                                                fontSize: "20px",
                                                lineHeight: "20px",
                                            }}
                                        >
                                            ­
                                        </td>
                                    </tr>
                                </table>


                                {params?.customerID &&
                                    <table
                                        cellSpacing="0"
                                        cellPadding="0"
                                        role="presentation"
                                        width="100%"
                                        align="center"
                                        className="r3-o"
                                        style={{ tableLayout: "fixed", width: "95%" }}
                                    >
                                        <tr>
                                            <td
                                                className="r21-i"
                                                style={{
                                                    backgroundColor: "#fff",
                                                }}
                                            >
                                                <table
                                                    width="100%"
                                                    cellSpacing="0"
                                                    cellPadding="0"
                                                    role="presentation"
                                                >
                                                    <tr>
                                                        <th
                                                            className="r5-c"
                                                            style={{ fontWeight: 400, width: "100%", verticalAlign: "top" }}
                                                        >
                                                            <table
                                                                cellSpacing="0"
                                                                cellPadding="0"
                                                                role="presentation"
                                                                align="left"
                                                                className="r12-o"
                                                                style={{ tableLayout: "fixed", width: "100%" }}
                                                            >
                                                                <tr>
                                                                    <td
                                                                        align="left"
                                                                        valign="top"
                                                                        className="r22-i nl2go-default-textstyle"
                                                                        style={{
                                                                            color: "#3b3f44",
                                                                            fontFamily: "Outfit, Arial",
                                                                            fontSize: "14px",
                                                                            lineHeight: "14px",
                                                                            wordBreak: "break-word",
                                                                            textAlign: "left",
                                                                        }}
                                                                    >
                                                                        <div>
                                                                            <p style={{ margin: 0 }}>
                                                                                Numéro client : <strong>{params?.customerID}</strong>
                                                                            </p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </th>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>

                                }
                                <table cellSpacing="0" cellPadding="0" role="presentation" align="center" className="r3-o" style={{ tableLayout: "fixed", width: "95%" }}>
                                    <tr>
                                        <td className="r23-i" style={{ backgroundColor: "#fff", paddingTop: "5px" }}>
                                            <table width="100%" cellSpacing="0" cellPadding="0" role="presentation">
                                                <tr>
                                                    <th className="r11-c" style={{ fontWeight: 400, width: '60%', verticalAlign: 'middle' }}>
                                                        <table cellSpacing="0" cellPadding="0" role="presentation" width="100%" className="r6-o" style={{ tableLayout: "fixed", width: "100%" }}>
                                                            <tr>
                                                                <td valign="top" className="r16-i">
                                                                    <table width="100%" cellSpacing="0" cellPadding="0" role="presentation">
                                                                        <tr>
                                                                            <td className="r24-c" align="left">
                                                                                <table
                                                                                    cellSpacing="0"
                                                                                    cellPadding="0"
                                                                                    role="presentation"
                                                                                    className="r25-o"
                                                                                    style={{ tableLayout: "fixed", width: "100%" }}
                                                                                >
                                                                                    <tr>
                                                                                        <td

                                                                                            className="r22-i nl2go-default-textstyle"
                                                                                            style={{
                                                                                                color: "#3b3f44",
                                                                                                fontFamily: "Outfit, Arial",
                                                                                                fontSize: "14px",
                                                                                                wordBreak: "break-word",
                                                                                                lineHeight: "18px",
                                                                                                textAlign: "left",
                                                                                                verticalAlign: "top",
                                                                                            }}
                                                                                        >
                                                                                            <div>
                                                                                                {params?.requestProducts?.map((product: any, index: number) => (
                                                                                                    <div key={`product-${index}`}>
                                                                                                        <p style={{ margin: 0 }}>
                                                                                                            <strong>
                                                                                                                {product.name} {product.frenchApplication}
                                                                                                            </strong>
                                                                                                        </p>
                                                                                                    </div>
                                                                                                ))}
                                                                                                <br />

                                                                                                {params?.requestOthers?.map((other: any, index: number) => (
                                                                                                    <div key={`other-${index}`}>
                                                                                                        <p style={{ margin: 0 }}>
                                                                                                            <strong>{other}</strong>
                                                                                                        </p>
                                                                                                    </div>
                                                                                                ))}

                                                                                                <p style={{ margin: 0 }}>
                                                                                                    Niveau de gamme souhaité: {' '}<br />
                                                                                                    {params?.requestOrderPreference === OrderPreference.LOW_RANGE && <strong>Low cost</strong>}
                                                                                                    {params?.requestOrderPreference === OrderPreference.MID_RANGE && <strong>Intermédiaire</strong>}
                                                                                                    {params?.requestOrderPreference === OrderPreference.HIGH_RANGE && <strong>Haut de gamme</strong>}
                                                                                                </p>
                                                                                                <br />
                                                                                                {params?.requestComment && <p style={{ margin: 0 }}>
                                                                                                    Commentaire du garage:<br /> <strong>{params?.requestComment}</strong>
                                                                                                </p>}
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr className="nl2go-responsive-hide">
                                                                                        <td height="15" style={{ fontSize: "15px", lineHeight: "15px" }}>­</td>
                                                                                    </tr>
                                                                                </table>

                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </th>
                                                    <th className="r11-c" style={{ fontWeight: 400, width: '40%', verticalAlign: 'middle' }}>
                                                        <table cellSpacing="0" cellPadding="0" role="presentation" width="100%" className="r6-o" style={{ tableLayout: "fixed", width: "100%" }}>
                                                            <tr>
                                                                <td valign="top" className="r26-i" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                                                                    <table width="100%" cellSpacing="0" cellPadding="0" role="presentation">
                                                                        <tr>
                                                                            <td className="r2-c" align="left">
                                                                                <table
                                                                                    cellSpacing="0"
                                                                                    cellPadding="0"
                                                                                    role="presentation"
                                                                                    className="r3-o"
                                                                                    style={{ tableLayout: "fixed", width: "150px" }}
                                                                                >
                                                                                    <tr>
                                                                                        <td
                                                                                            className="r27-i"
                                                                                            style={{ fontSize: "0", lineHeight: "0", paddingBottom: "15px", paddingTop: "15px" }}
                                                                                        >
                                                                                            <img
                                                                                                src="https://img.mailinblue.com/6381523/images/content_library/original/661d18b6ad812a318f12173b.png"
                                                                                                width="150px"
                                                                                                style={{ display: "block", width: "100%" }}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </th>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                <table cellSpacing="0" cellPadding="0" role="presentation" width="100%" align="center" className="r3-o" style={{ tableLayout: "fixed", width: "100%" }}>
                                    <tr>
                                        <td className="r28-i" style={{ backgroundColor: "#fff", paddingBottom: "20px" }}>
                                            <table width="100%" cellSpacing="0" cellPadding="0" role="presentation">
                                                <tr>
                                                    <th className="r11-c" style={{ fontWeight: 400, width: '66.67%', verticalAlign: 'middle' }}>
                                                        <table cellSpacing="0" cellPadding="0" role="presentation" width="100%" className="r6-o" style={{ tableLayout: "fixed", width: "100%" }}>
                                                            <tr>
                                                                <td valign="top" className="r16-i">
                                                                    <table width="100%" cellSpacing="0" cellPadding="0" role="presentation">
                                                                        <tbody>
                                                                            <tr >
                                                                                <td
                                                                                    className="r29-c nl2go-default-textstyle"
                                                                                    align="left"
                                                                                    style={{
                                                                                        color: "#3b3f44",
                                                                                        fontFamily: "Outfit, arial",
                                                                                        fontSize: "14px",
                                                                                        wordBreak: "break-word",
                                                                                        lineHeight: "14px",
                                                                                        paddingLeft: "15px",
                                                                                        textAlign: "left",
                                                                                        verticalAlign: "top",
                                                                                    }}
                                                                                >
                                                                                    <div>
                                                                                        <h3
                                                                                            className="default-heading3"
                                                                                            style={{
                                                                                                margin: "0",
                                                                                                color: "#000",
                                                                                                fontFamily: "Outfit, arial",
                                                                                                fontSize: "18px",
                                                                                                wordBreak: "break-word",
                                                                                                marginLeft: '24px'
                                                                                            }}
                                                                                        >
                                                                                            <span style={{ color: "#404049" }}>🚘 Détails du rendez-vous</span>
                                                                                        </h3>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="r30-c">
                                                                                    <table
                                                                                        cellSpacing="0"
                                                                                        cellPadding="0"
                                                                                        role="presentation"
                                                                                        width="400"
                                                                                        className="r31-o"
                                                                                        style={{
                                                                                            tableLayout: "fixed",
                                                                                            width: "100%",
                                                                                            marginLeft: '24px'
                                                                                        }}
                                                                                    >
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td
                                                                                                    className="r20-i nl2go-default-textstyle"
                                                                                                    style={{
                                                                                                        color: "#3b3f44",
                                                                                                        fontFamily: "Outfit, arial",
                                                                                                        fontSize: "14px",
                                                                                                        lineHeight: "14px",
                                                                                                        wordBreak: "break-word",
                                                                                                    }}
                                                                                                >
                                                                                                    <center>
                                                                                                        <div
                                                                                                            style={{
                                                                                                                textAlign: "center",
                                                                                                                width: "95%",
                                                                                                                borderBottom: "8px solid #ffd25c",
                                                                                                                borderRadius: "20px",
                                                                                                                padding: "0",
                                                                                                                paddingBottom: "15px",
                                                                                                            }}
                                                                                                        />
                                                                                                    </center>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr className="nl2go-responsive-hide">
                                                                                                <td
                                                                                                    height="20"
                                                                                                    style={{ fontSize: "20px", lineHeight: "20px" }}
                                                                                                >
                                                                                                    &nbsp;
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="r24-c" align="center">
                                                                                    <table
                                                                                        cellSpacing="0"
                                                                                        cellPadding="0"
                                                                                        role="presentation"
                                                                                        className="r25-o"
                                                                                        style={{ tableLayout: "fixed", width: "95%" }}
                                                                                    >
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td
                                                                                                    align="left"
                                                                                                    valign="top"
                                                                                                    className="r22-i nl2go-default-textstyle"
                                                                                                    style={{
                                                                                                        color: "#3b3f44",
                                                                                                        fontFamily: "Outfit, arial",
                                                                                                        fontSize: "14px",
                                                                                                        wordBreak: "break-word",
                                                                                                        lineHeight: "20px",
                                                                                                        textAlign: "left",
                                                                                                        wordWrap: "break-word",
                                                                                                    }}
                                                                                                >
                                                                                                    <div>
                                                                                                        <p style={{ margin: "0" }}>
                                                                                                            Date du rdv :{" "}
                                                                                                            <strong>{params?.eventStartDate}</strong>
                                                                                                        </p>
                                                                                                        <p style={{ margin: "0", display: 'block' }}>
                                                                                                            Véhicule :{" "}
                                                                                                            <strong>{params?.eventVehicleLabel}</strong>
                                                                                                            <br />
                                                                                                            Immat :{" "}
                                                                                                            <strong>{params?.eventVehiclePlate}</strong>
                                                                                                        </p>
                                                                                                        <br />

                                                                                                        <p style={{ margin: "0" }}>Prestations engagées :</p>
                                                                                                        {params?.eventPrestations?.map((prestation: any, index: number) => (
                                                                                                            <p style={{ margin: "0" }} key={index}>
                                                                                                                <strong>{prestation.name}</strong>
                                                                                                            </p>
                                                                                                        ))}
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr className="nl2go-responsive-hide">
                                                                                                <td
                                                                                                    height="15"
                                                                                                    style={{ fontSize: "15px", lineHeight: "15px" }}
                                                                                                >
                                                                                                    &nbsp;
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </th>
                                                    <th className="r11-c" align="left" style={{ fontWeight: 400, width: '33.33%', verticalAlign: 'middle' }}>
                                                        <table
                                                            cellSpacing="0"
                                                            cellPadding="0"
                                                            role="presentation"
                                                            align="center"
                                                            className="r32-o"
                                                            style={{ tableLayout: "fixed", width: "150px" }}
                                                        >
                                                            <tbody>
                                                                <tr>
                                                                    <td
                                                                        style={{
                                                                            fontSize: "0",
                                                                            lineHeight: "0",
                                                                            paddingBottom: "15px",
                                                                            paddingTop: "15px",
                                                                        }}
                                                                    >

                                                                        <img
                                                                            src="https://img.mailinblue.com/6381523/images/content_library/original/6615a1d0800843bfc164fba5.png"
                                                                            style={{ display: "block", width: "100%" }}
                                                                        />

                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                    </th>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                                <table
                                    cellSpacing="0"
                                    cellPadding="0"
                                    role="presentation"
                                    width="100%"
                                    align="center"
                                    className="r33-o"
                                    style={{ tableLayout: "fixed", width: "100%" }}
                                >
                                    <tbody>
                                        <tr>
                                            <td className="r19-i" style={{ backgroundColor: "#fff" }}>
                                                <table
                                                    width="100%"
                                                    cellSpacing="0"
                                                    cellPadding="0"
                                                    role="presentation"
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <th
                                                                className="r5-c"
                                                                style={{ fontWeight: 400, width: "100%", verticalAlign: "top" }}
                                                            >
                                                                <table
                                                                    cellSpacing="0"
                                                                    cellPadding="0"
                                                                    role="presentation"
                                                                    width="100%"
                                                                    className="r6-o"
                                                                    style={{ tableLayout: "fixed", width: "100%" }}
                                                                >
                                                                    <tbody>
                                                                        <tr>
                                                                            <td valign="top" className="r16-i">
                                                                                <table
                                                                                    width="100%"
                                                                                    cellSpacing="0"
                                                                                    cellPadding="0"
                                                                                    role="presentation"
                                                                                >
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td
                                                                                                className="r29-c nl2go-default-textstyle"
                                                                                                align="left"
                                                                                                style={{
                                                                                                    color: "#3b3f44",
                                                                                                    fontFamily: "Outfit, arial",
                                                                                                    fontSize: "14px",
                                                                                                    wordBreak: "break-word",
                                                                                                    lineHeight: "14px",
                                                                                                    paddingLeft: "15px",
                                                                                                    textAlign: "left",
                                                                                                    verticalAlign: "top",
                                                                                                }}
                                                                                            >
                                                                                                <div>
                                                                                                    <h3
                                                                                                        className="default-heading3"
                                                                                                        style={{
                                                                                                            margin: "0",
                                                                                                            color: "#000",
                                                                                                            fontFamily: "Outfit, arial",
                                                                                                            fontSize: "18px",
                                                                                                            wordBreak: "break-word",
                                                                                                            marginLeft: "24px",
                                                                                                        }}
                                                                                                    >
                                                                                                        <span style={{ color: "#404049" }}>
                                                                                                            🚚 Adresse de livraison
                                                                                                        </span>
                                                                                                    </h3>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="r30-c">
                                                                                                <table
                                                                                                    cellSpacing="0"
                                                                                                    cellPadding="0"
                                                                                                    role="presentation"
                                                                                                    className="r31-o"
                                                                                                    style={{
                                                                                                        tableLayout: "fixed",
                                                                                                        width: "100%",
                                                                                                        marginLeft: '34px'
                                                                                                    }}
                                                                                                >
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td
                                                                                                                className="r34-i nl2go-default-textstyle"
                                                                                                                style={{
                                                                                                                    color: "#3b3f44",
                                                                                                                    fontFamily: "Outfit, arial",
                                                                                                                    fontSize: "16px",
                                                                                                                    lineHeight: "16px",
                                                                                                                    wordBreak: "break-word",
                                                                                                                }}
                                                                                                            >
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        textAlign: "center",
                                                                                                                        width: "95%",
                                                                                                                        borderBottom:
                                                                                                                            "8px solid #bcd46c",
                                                                                                                        borderRadius: "20px",
                                                                                                                        padding: "0",
                                                                                                                        paddingBottom: "15px",
                                                                                                                    }}
                                                                                                                />
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr className="nl2go-responsive-hide">
                                                                                                            <td
                                                                                                                height="20"
                                                                                                                style={{
                                                                                                                    fontSize: "20px",
                                                                                                                    lineHeight: "20px",
                                                                                                                }}
                                                                                                            >
                                                                                                                &nbsp;
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="r24-c" align="left">
                                                                                                <table
                                                                                                    cellSpacing="0"
                                                                                                    cellPadding="0"
                                                                                                    role="presentation"
                                                                                                    width="100%"
                                                                                                    className="r12-o"
                                                                                                    style={{
                                                                                                        tableLayout: "fixed",
                                                                                                        width: "100%",
                                                                                                    }}
                                                                                                >
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td
                                                                                                                align="left"
                                                                                                                valign="middle"
                                                                                                                className="r35-i nl2go-default-textstyle"
                                                                                                                style={{
                                                                                                                    color: "#3b3f44",
                                                                                                                    fontFamily: "Outfit, arial",
                                                                                                                    fontSize: "16px",
                                                                                                                    wordBreak: "break-word",
                                                                                                                    lineHeight: "20px",
                                                                                                                    paddingTop: "5px",
                                                                                                                    textAlign: "center",
                                                                                                                    wordWrap: "break-word",
                                                                                                                    paddingBottom: '16px',
                                                                                                                }}
                                                                                                            >
                                                                                                                <div>
                                                                                                                    <p style={{ margin: "0" }}>
                                                                                                                        <strong>
                                                                                                                            {params?.garageName}
                                                                                                                        </strong>
                                                                                                                        <br />
                                                                                                                        {params?.garageAddress}
                                                                                                                        <br />
                                                                                                                        {params?.garageContactEmail}
                                                                                                                    </p>
                                                                                                                    <a
                                                                                                                        href={`tel:${params?.garageContactPhone}`}
                                                                                                                        className="r39-r default-button"
                                                                                                                        target="_blank"
                                                                                                                        rel="noopener noreferrer"
                                                                                                                        title="Contacter le garage"
                                                                                                                        data-btn="1"
                                                                                                                        style={{
                                                                                                                            fontStyle: "normal",
                                                                                                                            fontWeight: 400,
                                                                                                                            lineHeight: "1.15",
                                                                                                                            textDecoration: "none",
                                                                                                                            wordBreak: "break-word",
                                                                                                                            borderStyle: "solid",
                                                                                                                            wordWrap: "break-word",
                                                                                                                            display: "block",
                                                                                                                            WebkitTextSizeAdjust: "none",
                                                                                                                            backgroundColor: "#bcd46c",
                                                                                                                            borderBottomWidth: 0,
                                                                                                                            borderColor: "#bcd46c",
                                                                                                                            borderLeftWidth: 0,
                                                                                                                            borderRadius: "25px",
                                                                                                                            borderRightWidth: 0,
                                                                                                                            borderTopWidth: 0,
                                                                                                                            color: "#000",
                                                                                                                            fontFamily: "Outfit, arial",
                                                                                                                            fontSize: "13px",
                                                                                                                            height: "14px",
                                                                                                                            paddingBottom: "12px",
                                                                                                                            paddingTop: "12px",
                                                                                                                            width: "360px",
                                                                                                                            marginTop: '24px',
                                                                                                                            marginLeft: '24px'
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span>{params?.garageContactPhone}</span>
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td
                                                                                                                align="left"
                                                                                                                valign="top"
                                                                                                                className="r35-i nl2go-default-textstyle"
                                                                                                                style={{
                                                                                                                    color: "#3b3f44",
                                                                                                                    fontFamily: "Outfit, arial",
                                                                                                                    fontSize: "16px",
                                                                                                                    wordBreak: "break-word",
                                                                                                                    lineHeight: "20px",
                                                                                                                    paddingTop: "5px",
                                                                                                                    textAlign: "center",
                                                                                                                    wordWrap: "break-word",
                                                                                                                    paddingBottom: '16px',
                                                                                                                }}
                                                                                                            >
                                                                                                                <div>
                                                                                                                    <table
                                                                                                                        width="100%"
                                                                                                                        cellSpacing="0"
                                                                                                                        cellPadding="0"
                                                                                                                        role="presentation"
                                                                                                                    >
                                                                                                                        <tbody>
                                                                                                                            <tr>
                                                                                                                                <td className="r2-c" align="center">
                                                                                                                                    <table
                                                                                                                                        cellSpacing="0"
                                                                                                                                        cellPadding="0"
                                                                                                                                        role="presentation"
                                                                                                                                        width="250"
                                                                                                                                        className="r41-o"
                                                                                                                                        style={{
                                                                                                                                            borderCollapse: "separate",
                                                                                                                                            borderRadius: "1px",
                                                                                                                                            tableLayout: "fixed",
                                                                                                                                            width: "250px",
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <tbody>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    className="r34-i"
                                                                                                                                                    style={{
                                                                                                                                                        borderRadius: "1px",
                                                                                                                                                        fontSize: 0,
                                                                                                                                                        lineHeight: 0,
                                                                                                                                                    }}
                                                                                                                                                >

                                                                                                                                                    <img
                                                                                                                                                        src="https://img.mailinblue.com/6381523/images/content_library/original/65782099878abec96d5d99f5.png"
                                                                                                                                                        width="250"
                                                                                                                                                        style={{ display: "block", width: "100%", borderRadius: "1px" }}
                                                                                                                                                    />

                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        </tbody>
                                                                                                                                    </table>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <td className="r24-c" align="left">
                                                                                                                                    <table
                                                                                                                                        cellSpacing="0"
                                                                                                                                        cellPadding="0"
                                                                                                                                        role="presentation"
                                                                                                                                        width="100%"
                                                                                                                                        className="r42-o"
                                                                                                                                        style={{ tableLayout: "fixed", width: "100%" }}
                                                                                                                                    >
                                                                                                                                        <tbody>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    align="center"
                                                                                                                                                    valign="top"
                                                                                                                                                    className="r43-i nl2go-default-textstyle"
                                                                                                                                                    style={{
                                                                                                                                                        color: "#3b3f44",
                                                                                                                                                        fontFamily: "Outfit,arial",
                                                                                                                                                        fontSize: "14px",
                                                                                                                                                        lineHeight: "14px",
                                                                                                                                                        wordBreak: "break-word",
                                                                                                                                                        paddingTop: "10px",
                                                                                                                                                        textAlign: "center",
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <div>
                                                                                                                                                        <p
                                                                                                                                                            style={{
                                                                                                                                                                margin: 0,
                                                                                                                                                                color: "#677876",
                                                                                                                                                                fontSize: "26px",
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span
                                                                                                                                                                style={{
                                                                                                                                                                    color: "#404049",
                                                                                                                                                                    fontSize: "14px",
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                Movalib Pro, l'assistant digital
                                                                                                                                                                conçu par des garages, pour des
                                                                                                                                                                garages.
                                                                                                                                                            </span>
                                                                                                                                                            <br />
                                                                                                                                                            <a
                                                                                                                                                                href="mailto:contact@movalib.com"
                                                                                                                                                                style={{
                                                                                                                                                                    color: "#bcd46c",
                                                                                                                                                                    textDecoration: "none",
                                                                                                                                                                }}
                                                                                                                                                            >
                                                                                                                                                                <span style={{ fontSize: "16px" }}>
                                                                                                                                                                    <strong>
                                                                                                                                                                        contact@movalib.com
                                                                                                                                                                    </strong>
                                                                                                                                                                </span>
                                                                                                                                                            </a>
                                                                                                                                                        </p>
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                            <tr className="nl2go-responsive-hide">
                                                                                                                                                <td
                                                                                                                                                    height="10"
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: "10px",
                                                                                                                                                        lineHeight: "10px",
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    &nbsp;
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        </tbody>
                                                                                                                                    </table>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>


                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table >

    );
};
export default PurchaseOrder;