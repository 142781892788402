import {
  ConfirmationDialog,
  type Customer,
  CustomerType,
  type Vehicle,
  formatFrenchVehiclePlate,
  formatPhoneNumber,
} from '@movalib/movalib-commons';
import { Delete, Edit, ImportExportOutlined } from '@mui/icons-material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  debounce,
} from '@mui/material';
import { format } from 'date-fns';
import type React from 'react';
import { memo } from 'react';
import { type FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux'; // Import du thème personnalisé
import * as XLSX from 'xlsx';
import { AddCustomer } from '../dialogs/customers/AddCustomer';
import { CustomerDialog } from '../dialogs/customers/CustomerDialog';
import { EditVehicle } from '../dialogs/customers/vehicle/EditVehicle';
import { CUSTOMERS_PER_PAGE } from '../helpers/Constants';
import { StyledTableCell, StyledTableRow } from '../helpers/Styled';
import { flexEnd, flexStart } from '../helpers/Tools';
import { useBoolState } from '../helpers/hooks/useBoolState';
import { useCustomersInfos } from '../hooks/useCustomersInfos';
import { useDeleteCustomer } from '../query/customer/CustomerQuery';
import { setSnackbar } from '../slices/snackbarSlice';
import theme from '../theme';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import { BusinessCustomerDialog } from '../dialogs/customers/BusinessCustomerDialog';

interface CustomersProps {
  garageId: string;
}

const Customers = memo(( {garageId}: CustomersProps) => {
  const dispatch = useDispatch();

  const { mutateAsync: deleteCustomer } = useDeleteCustomer();

  const [customerIdToDelete, setCustomerIdToDelete] = useState<Customer['id'] | undefined>();
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(null);
  const [vehicleOwnerId, setVehicleOwnerId] = useState<Customer['id'] | undefined>();
  const [vehicleId, setVehicleId] = useState<Vehicle['id'] | undefined>();
  const [search, setSearch] = useState<string>('');

  const [page, setPage] = useState(0);

  const { isAddCustomerModalOpen, toggleAddCustomerModalOpen } = useBoolState(false, 'addCustomerModalOpen');

  const { isConfirmationDialogOpen, toggleConfirmationDialogOpen } = useBoolState(false, 'confirmationDialogOpen');
  const { isEditVehicleModalOpen, toggleEditVehicleModalOpen } = useBoolState(false, 'editVehicleModalOpen');

  const { customers, customersCount, searchedCustomers } = useCustomersInfos({
    garageId: garageId,
    query: search,
    page,
  });

  const handleUserClick = useCallback((customer: Customer) => {
    setSelectedCustomerId(customer.id);
  }, []);

  const handleCloseSelectedCustomer = useCallback(() => {
    setSelectedCustomerId(null);
  }, []);

  const handleExport = useCallback(() => {
    if (customers) {
      // Filtrer les données de 'users' avant le json_to_sheet pour réduire le périmètre de l'exporter
      const ws = XLSX.utils.json_to_sheet(customers);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Fichier client MOVALIB');
      XLSX.writeFile(wb, `movalib_clients_${format(new Date(), 'ddMMyyyy_HHmm')}.xlsx`);
    }
  }, [customers]);

  const handleChangePage = useCallback((_event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const onChangeDebouncedSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target?.value;
    setSearch(value);
  }, 300);

  const onSelectCustomerToDelete = useCallback(
    (customerId: Customer['id']) => {
      setCustomerIdToDelete(customerId);
      toggleConfirmationDialogOpen();
    },
    [toggleConfirmationDialogOpen],
  );

  const handleDeleteCustomer = useCallback(() => {
    if (!(garageId && customerIdToDelete)) {
      return;
    }
    deleteCustomer({ garageId: garageId, customerId: customerIdToDelete }).then((response) => {
      if (response.success) {
        dispatch(
          setSnackbar({
            open: true,
            message: response.data ?? 'Le client a bien été supprimé',
            severity: 'success',
          }),
        );
      } else {
        dispatch(
          setSnackbar({
            open: true,
            message: response.error ?? 'Erreur lors de la suppression du client',
            severity: 'error',
          }),
        );
      }
    });
    setCustomerIdToDelete(undefined);
    toggleConfirmationDialogOpen();
  }, [deleteCustomer, customerIdToDelete, dispatch, garageId, toggleConfirmationDialogOpen]);

  const onOpenEditVehicleDialog = useCallback(
    (vehicleOwnerId: Customer['id'], vehicleId: Vehicle['id']) => {
      setVehicleOwnerId(vehicleOwnerId);
      setVehicleId(vehicleId);
      toggleEditVehicleModalOpen();
    },
    [toggleEditVehicleModalOpen],
  );

  const onCloseEditVehicleDialog = useCallback(() => {
    setVehicleOwnerId(undefined);
    setVehicleId(undefined);
    toggleEditVehicleModalOpen();
  }, [toggleEditVehicleModalOpen]);

  const displayedCustomers = search ? searchedCustomers : customers;

  const selectedCustomer = useMemo(
    () => (!selectedCustomerId ? null : displayedCustomers.find((c) => c.id === selectedCustomerId)),
    [displayedCustomers, selectedCustomerId],
  );

  const customerToDelete = useMemo(
    () => displayedCustomers.find((c) => c.id === customerIdToDelete),
    [displayedCustomers, customerIdToDelete],
  );

  const vehicleOwner = useMemo(
    () => displayedCustomers.find((c) => c.id === vehicleOwnerId),
    [displayedCustomers, vehicleOwnerId],
  );

  const vehicleToEdit = useMemo(
    () => vehicleOwner?.vehicles.find((v) => v.id === vehicleId),
    [vehicleOwner, vehicleId],
  );

  return (
    <Box sx={{ pt: 11, px: 2 }}>
      <Grid container sx={{ alignItems: 'center', position: 'static' }}>
        <Grid item xs={12} md={3} sx={{pb: {xs:2, md:0}}}>
          <Button
            variant='contained'
            size='small'
            startIcon={<AddRoundedIcon />}
            color='primary'
            onClick={toggleAddCustomerModalOpen}
            sx={{
              pr: 2,
              mr: 4,
              textTransform: 'none',
            }}
          >
            Nouveau client
          </Button>
        </Grid>
        <Grid item xs={12} md={7} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
          <TextField
            size='small'
            label='Nom, prénom, numéro, immat ...'
            sx={{ width: '60%', minWidth: '200px' }}
            onChange={onChangeDebouncedSearch}
          />
          <Typography sx={{ ml: 2 }}>
            <b>{customersCount}</b> clients (total)
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: 'end', pt: {xs: 2, md:0} }}>
          <Button
            variant='contained'
            size='small'
            startIcon={<ImportExportOutlined />}
            color='inherit'
            onClick={handleExport}
            sx={{
              pr: 2,
              mr: 4,
              textTransform: 'none',
              backgroundColor: 'white',
            }}
          >
            Exporter
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Card} sx={{ mt: 2 }}>
        <Table stickyHeader size='small' aria-label='customers table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell>Identité</StyledTableCell>
              <StyledTableCell>Contact</StyledTableCell>
              <StyledTableCell>Véhicule(s)</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedCustomers && displayedCustomers.length === 0 && (
              <TableRow key={0}>
                <TableCell colSpan={5} align='center' sx={{ color: theme.palette.text.secondary, p: 4 }}>
                  AUCUN RESULTAT
                </TableCell>
              </TableRow>
            )}
            {displayedCustomers?.map((customer) => (
              <StyledTableRow key={customer.id} hover>
                <TableCell width='10%' onClick={() => handleUserClick(customer)} sx={{ cursor: 'pointer' }}>
                  {customer.type === CustomerType.INDIVIDUAL && (
                    <Typography style={flexStart} sx={{ fontSize: '0.8rem' }}>
                      <PersonIcon sx={{ pr: 1 }} /> PARTICULIER
                    </Typography>
                  )}
                  {customer.type === CustomerType.PROFESSIONAL && (
                    <Typography style={flexStart} sx={{ fontSize: '0.8rem' }}>
                      <BusinessIcon sx={{ pr: 1 }} /> PROFESSIONNEL
                    </Typography>
                  )}
                </TableCell>
                <TableCell width='20%' onClick={() => handleUserClick(customer)} sx={{ cursor: 'pointer' }}>
                  {customer.type === CustomerType.INDIVIDUAL && (
                    <strong>
                      {customer.firstname} {customer.lastname?.toUpperCase()}
                    </strong>
                  )}
                  {customer.type === CustomerType.PROFESSIONAL && (
                    <strong>{customer.companyName?.toUpperCase()}</strong>
                  )}
                </TableCell>

                <TableCell width='20%' onClick={() => handleUserClick(customer)} sx={{ cursor: 'pointer' }}>
                  <Typography variant='body2' color='textSecondary' style={flexStart} sx={{ fontWeight: 600 }}>
                    <PhoneIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                    {customer.type === CustomerType.PROFESSIONAL && (
                      <>
                        {customer.companyPhoneNumber && formatPhoneNumber(customer.companyPhoneNumber)}
                        {customer.companyPhoneNumber && customer.phoneNumber && ' / '}
                        {customer.phoneNumber && formatPhoneNumber(customer.phoneNumber)}
                      </>
                    )}
                    {customer.type === CustomerType.INDIVIDUAL &&
                      customer.phoneNumber &&
                      formatPhoneNumber(customer.phoneNumber)}
                  </Typography>
                  <Typography variant='body2' color='textSecondary' style={flexStart}>
                    {customer.email}
                  </Typography>
                </TableCell>
                <TableCell size='small' width='50%'>
                  {customer.vehicles.length === 0
                    ? '-'
                    : customer.vehicles?.map((vehicle) => {
                        return (
                          <Chip
                            key={vehicle.plate}
                            label={
                              <Typography variant='body2' sx={{ p: 1 }}>
                                <b>{formatFrenchVehiclePlate(vehicle.plate)}</b>
                                <br />
                                {vehicle.brand}&nbsp;{vehicle.model}&nbsp;{vehicle.version}
                              </Typography>
                            }
                            onClick={() => onOpenEditVehicleDialog(customer.id, vehicle.id)}
                            sx={{ my: 0.5, borderRadius: 2, height: '100%', mr: 1, cursor: 'pointer' }}
                          />
                        );
                      })}
                </TableCell>
                <TableCell size='small' width='10%'>
                  <IconButton onClick={() => handleUserClick(customer)} title='Modifier la fiche client'>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => onSelectCustomerToDelete(customer.id)} title='Supprimer la fiche client'>
                    <Delete />
                  </IconButton>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {customersCount > CUSTOMERS_PER_PAGE && (
        <Box sx={{ mb: 2, mt: 2, width: '100%', p: 0 }} style={flexEnd}>
          <Pagination
            count={Math.round(customersCount / CUSTOMERS_PER_PAGE) + 1}
            size='small'
            color='primary'
            // Here we do +1 because our back-end pagination starts at 0
            page={page + 1}
            onChange={handleChangePage}
            siblingCount={0}
            boundaryCount={2}
          />
        </Box>
      )}

      {selectedCustomer && selectedCustomer.type === CustomerType.INDIVIDUAL && garageId && (
        <CustomerDialog open onClose={handleCloseSelectedCustomer} customer={selectedCustomer} garageId={garageId} />
      )}

      {selectedCustomer && selectedCustomer.type === CustomerType.PROFESSIONAL && garageId &&  (
        <BusinessCustomerDialog open onClose={handleCloseSelectedCustomer} customer={selectedCustomer} garageId={garageId} />
      )}

      { garageId && <AddCustomer open={isAddCustomerModalOpen} onClose={toggleAddCustomerModalOpen} garageId={garageId} />}

      {vehicleToEdit && vehicleOwner && (
        <EditVehicle
          garageId={garageId}
          vehicle={vehicleToEdit}
          customer={vehicleOwner}
          open={isEditVehicleModalOpen}
          onClose={onCloseEditVehicleDialog}
        />
      )}
      <ConfirmationDialog
        showConfirm
        open={isConfirmationDialogOpen}
        onClose={toggleConfirmationDialogOpen}
        onConfirm={handleDeleteCustomer}
        title={'Suppression du client'}
        message={
          <span>
            Êtes-vous sûr de vouloir supprimer&nbsp;
            <b>
              {customerToDelete?.firstname} {customerToDelete?.lastname}
            </b>
            &nbsp;de votre fichier client ?
          </span>
        }
      />
    </Box>
  );
});

export default Customers;
