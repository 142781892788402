import type { FunctionComponent } from 'react';
import type { PricingPackageBasicProps } from '../PricingPackage';
import { Box } from '@mui/material';
import React from 'react';
import { flexCenterCol } from '../../../../helpers/Tools';
import { styleClassique } from '../pricingTools';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
export interface ClassicOffersProps {
  id: number;
  name: string;
  description: string;
  offers: Array<{ id: number; include: boolean; optional: boolean }>;
}

const ClassicOffers: FunctionComponent<PricingPackageBasicProps> = ({ movaPackage, options }: PricingPackageBasicProps) => {
  return (
    <Box sx={{ minHeight: '20%', width: '100%', flexDirection: 'column', display: 'flex' }}>
      {options?.classique?.map((item: ClassicOffersProps) => (
        <React.Fragment key={item.id}>
          <Box style={{ ...flexCenterCol, ...styleClassique }} sx={{}}>
            {item.offers.filter((offre: ClassicOffersProps['offers'][0]) => offre.id === movaPackage.id)[0].include ? (
              <CheckIcon sx={{height: '1rem', width: '1rem', fontSize: '1rem'}} className="CheckIcon"/>
            ) : (
              <CloseIcon className='CloseIcon' />
            )}
          </Box>
        </React.Fragment>
      ))}
    </Box>
  );
};

export default ClassicOffers;
