import { CustomerType, validatePhoneNumber } from '@movalib/movalib-commons';
import CloseIcon from '@mui/icons-material/CloseRounded';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tab,
  TextField,
  type TextFieldProps,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { type FormikConfig, useFormik } from 'formik';
import { startCase } from 'lodash';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useCreateCustomer, useSearchCustomers } from '../../query/customer/CustomerQuery';
import {  useGarageAddCustomerVehicle } from '../../query/garage/GarageQuery';
import { setSnackbar } from '../../slices/snackbarSlice';
import theme from '../../theme';
import { VehicleForm } from './vehicle/vehicleForm';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import { TabContext, TabList, TabPanel, type TabListProps } from '@mui/lab';
import { flexStart } from '../../helpers/Tools';
import { cleanString } from '../../helpers/Tools';

interface FormValues {
  email?: string | null;
  firstname: string;
  lastname: string;
  phoneNumber?: string | null;
  type: CustomerType;
  companyName?: string | null;
  companyPhoneNumber?: string | null;
  notes?: string | null;
}

const formSchema = Yup.object({
  email: Yup.string().email('Email invalide').optional().nullable(),
  firstname: Yup.string()
    .nullable()
    .test('firstname-required-for-individuals', 'Prénom obligatoire pour les particuliers', function (value) {
      const { type } = this.parent; // Accède au type du client
      if (type === CustomerType.INDIVIDUAL && (!value || value.trim() === '')) {
        return this.createError({ path: 'firstname', message: 'Prénom obligatoire pour les particuliers' });
      }
      return true;
    }),
  lastname: Yup.string()
    .nullable()
    .test('lastname-required-for-individuals', 'Nom obligatoire pour les particuliers', function (value) {
      const { type } = this.parent; // Accède au type du client
      if (type === CustomerType.INDIVIDUAL && (!value || value.trim() === '')) {
        return this.createError({ path: 'lastname', message: 'Nom obligatoire pour les particuliers' });
      }
      return true;
    }),
  type: Yup.mixed<CustomerType>().oneOf([CustomerType.INDIVIDUAL, CustomerType.PROFESSIONAL]).required('Type requis'),
  phoneNumber: Yup.string()
    .nullable()
    .test('phone-or-companyPhone', 'Un n° de téléphone est obligatoire pour les particuliers', function (value) {
      const { type, companyPhoneNumber } = this.parent; // Récupère les autres champs du formulaire
      if (type === CustomerType.INDIVIDUAL && (!value || value.length !== 10)) {
        return this.createError({ path: 'phoneNumber', message: 'Le n° de téléphone doit comporter 10 chiffres' });
      }
      return true;
    }),
  companyPhoneNumber: Yup.string()
    .nullable()
    .test('phone-or-companyPhone', 'Un n° de téléphone est obligatoire pour les professionnels', function (value) {
      const { type, phoneNumber } = this.parent; // Récupère les autres champs du formulaire
      if (type === CustomerType.PROFESSIONAL && (!value || value.length !== 10)) {
        return this.createError({
          path: 'companyPhoneNumber',
          message: 'Le n° de téléphone doit comporter 10 chiffres',
        });
      }
      return true;
    }),
  companyName: Yup.string()
    .nullable()
    .test('companyName-required-for-professionals', "Le nom de l'entreprise est obligatoire", function (value) {
      const { type } = this.parent;
      if (type === CustomerType.PROFESSIONAL && (!value || value.trim() === '')) {
        return this.createError({
          path: 'companyName',
          message: "Le nom de l'entreprise est obligatoire",
        });
      }
      return true;
    }),
  notes: Yup.string().optional().nullable(),
}).test('at-least-one-phone', 'Un n° de téléphone est requis', function (values) {
  const { type, phoneNumber, companyPhoneNumber } = values as FormValues;

  // Si le type est professionnel, au moins un des deux numéros doit être rempli
  if (type === CustomerType.PROFESSIONAL && !phoneNumber && !companyPhoneNumber) {
    return this.createError({
      path: 'companyPhoneNumber',
      message: "Le n° de téléphone ou celui de l'entreprise est requis pour les professionnels",
    });
  }
  return true;
});

type Form = Yup.InferType<typeof formSchema>;

export type AddCustomerProps = {
  open: boolean;
  onClose: (customerLastName?: string | null | undefined) => void;
  onEntered?: () => void;
  garageId: string;
};

export const AddCustomer = memo(({ open, onClose, garageId }: AddCustomerProps) => {
  const dispatch = useDispatch();
  const [vehicle, setVehicle] = useState<VehicleForm | undefined>(undefined);
  const [phoneNumber, setPhoneNumber] = useState<string | null | undefined>('');

  const { mutateAsync: createCustomer } = useCreateCustomer();
  const { mutateAsync: createVehicle } = useGarageAddCustomerVehicle();
  const inputNameRef = useRef<HTMLInputElement>(null);

  const { data: customers } = useSearchCustomers(garageId, phoneNumber ?? '');

  useEffect(() => {
    createVehicleByCustomer();
  }, [customers]);

  useEffect(() => {
    setTimeout(() => {
      inputNameRef.current?.focus();
    });
  }, [open]);

  const createVehicleByCustomer = async () => {
    if (vehicle && customers && customers.length > 0) {
      setPhoneNumber(''); // for disabled loop effect with useSearchCustomers
      const { plate, averageMileagePerYear, currentMileage, tireInfos } = vehicle;
      const response = await createVehicle({
        garageId: garageId,
        customerId: customers[0].id,
        plate,
        currentMileage,
        averageMileagePerYear,
        tireWidth: tireInfos.width || undefined,
        tireHeight: tireInfos.height || undefined,
        tireDiameter: tireInfos.diameter || undefined,
        tireSpeedIndex: tireInfos.speedIndex || undefined,
      });

      if (response.success) {
        dispatch(
          setSnackbar({
            open: true,
            message: 'Le client a bien été ajouté avec son véhicule !',
            severity: 'success',
          }),
        );
      } else {
        dispatch(
          setSnackbar({
            open: true,
            message: "Le client a bien été ajouté mais une erreur est survenue lors de l'ajout du véhicule",
            severity: 'error',
          }),
        );
      }
      onClose(customers[0].type === CustomerType.INDIVIDUAL ? customers[0].lastname : customers[0].companyName);
      resetForm();
    }
  };

  const handleFormChange = (values: VehicleForm) => {
    setVehicle(values);
  };

  const onSubmit: FormikConfig<Form>['onSubmit'] = useCallback(
    async (values) => {
      if (!garageId) {
        return;
      }
      values.firstname = cleanString(values.firstname!);
      values.lastname = cleanString(values.lastname!);
      if(values.companyName) values.companyName = cleanString(values.companyName);
      if(values.email) values.email =  cleanString(values.email);
      const response = await createCustomer({
        garageId: garageId,
        payload: { ...values, email: values?.email || null },
      });
      if (response.success) {
        if (vehicle?.plate) {
          setPhoneNumber(values.type === CustomerType.INDIVIDUAL ? values.phoneNumber : values.companyPhoneNumber);
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.data ?? 'Le client a bien été ajouté',
              severity: 'success',
            }),
          );
          onClose(values.type === CustomerType.INDIVIDUAL ? values.lastname : values.companyName);
          resetForm();
        }
      } else {
        dispatch(
          setSnackbar({
            open: true,
            message: response.error ?? "Erreur lors de l'ajout du client",
            severity: 'error',
          }),
        );
      }
    },
    [garageId, createCustomer, dispatch, onClose, vehicle],
  );

  const { values, errors, isValid, dirty, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik<Form>({
    initialValues: {
      email: null,
      firstname: '',
      lastname: '',
      phoneNumber: '',
      type: CustomerType.INDIVIDUAL,
      companyPhoneNumber: '',
      companyName: '',
      notes: '',
    },
    validationSchema: formSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const handleChangeType = useCallback<NonNullable<TabListProps['onChange']>>(
    (e, newValue) => {
      setFieldValue('type', newValue);
    },
    [setFieldValue],
  );

  const handleChangeCompanyName = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      setFieldValue('companyName', e.target.value?.toUpperCase());
    },
    [setFieldValue],
  );

  const handleChangeLastname = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      setFieldValue('lastname', e.target.value?.toUpperCase());
    },
    [setFieldValue],
  );

  const handleChangeFirstName = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      // uppercase each 1rst letter on each word (Just Like This You Know)
      const words = e.target.value.split(/(\s+|-+)/g).map((word) => {
        // Only capitalize words that are not spaces or hyphens
        if (word.trim() === '' || word === '-') {
          return word;
        }
        // Ensure only the first character is capitalized, and others remain as they are
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      });

      setFieldValue('firstname', words.join(''));
      
    },
    [setFieldValue],
  );

  const handleChangeCompanyPhoneNumber = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      if (e.target.value !== '' && !validatePhoneNumber(e.target.value)) {
        return;
      }
      setFieldValue('companyPhoneNumber', e.target.value);
    },
    [setFieldValue],
  );

  const handleChangePhoneNumber = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      if (e.target.value !== '' && !validatePhoneNumber(e.target.value)) {
        return;
      }
      setFieldValue('phoneNumber', e.target.value);
    },
    [setFieldValue],
  );

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth='sm' scroll='body'>
      <Toolbar
        disableGutters
        variant='dense'
        sx={{
          minHeight: 3,
          backgroundColor: theme.palette.grey[200],
          py: 0,
        }}
      >
        <DialogTitle sx={{ flexGrow: 1 }} component={'div'}>
          <Typography
            py={0}
            my={0}
            sx={{
              pl: '34px',
              color: theme.palette.text.primary,
              textAlign: 'center',
            }}
          >
            <span>
              AJOUTER <b>UN CLIENT</b>
            </span>
          </Typography>
        </DialogTitle>
        <Tooltip title='Fermer'>
          <IconButton sx={{ mr: 1 }} size='small' aria-label='close' onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>

      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Box sx={{ width: '100%', typography: 'body1', mb: 1 }}>
            <TabContext value={values.type}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChangeType}
                  aria-label='customer type selection'
                  sx={{
                    color: 'rgba(255, 255, 255, 0.7)',
                    '&.Mui-selected': {
                      color: '#fff',
                    },
                    '&.Mui-focusVisible': {
                      backgroundColor: 'rgba(100, 95, 228, 0.32)',
                    },
                  }}
                >
                  <Tab
                    value={CustomerType.INDIVIDUAL}
                    label={
                      <div style={flexStart}>
                        <PersonIcon sx={{ pr: 1 }} /> PARTICULIER
                      </div>
                    }
                    sx={{
                      '&.Mui-selected': {
                        color: theme.palette.primary.dark,
                      },
                    }}
                  />
                  <Tab
                    value={CustomerType.PROFESSIONAL}
                    label={
                      <div style={flexStart}>
                        <BusinessIcon sx={{ pr: 1 }} /> PROFESSIONNEL
                      </div>
                    }
                    sx={{
                      '&.Mui-selected': {
                        color: theme.palette.primary.dark,
                      },
                    }}
                  />
                </TabList>
              </Box>
            </TabContext>
          </Box>
          <Grid container spacing={1} sx={{ my: 1 }}>
            {values.type === CustomerType.PROFESSIONAL && (
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  margin='dense'
                  fullWidth
                  required
                  id='companyName'
                  label="NOM DE L'ENTREPRISE"
                  name='companyName'
                  onChange={handleChangeCompanyName}
                  value={values.companyName}
                  error={!!errors.companyName}
                  helperText={errors.companyName}
                />
              </Grid>
            )}
            {values.type === CustomerType.INDIVIDUAL && (
              <>
                <Grid item xs={6}>
                  <TextField
                    inputRef={inputNameRef}
                    margin='dense'
                    autoFocus
                    required
                    fullWidth
                    id='lastname'
                    label='NOM'
                    name='lastname'
                    autoComplete='family-name'
                    onChange={handleChangeLastname}
                    value={values.lastname}
                    error={!!errors.lastname}
                    helperText={errors.lastname}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    margin='dense'
                    required
                    fullWidth
                    id='firstname'
                    label='Prénom'
                    name='firstname'
                    autoComplete='given-name'
                    onChange={handleChangeFirstName}
                    value={values.firstname}
                    error={!!errors.firstname}
                    helperText={errors.firstname}
                  />
                </Grid>
              </>
            )}
            {values.type === CustomerType.PROFESSIONAL && (
              <Grid item xs={12} md={6}>
                <TextField
                  type='tel'
                  margin='dense'
                  fullWidth
                  required
                  id='companyPhoneNumber'
                  label='N° téléphone principal'
                  name='companyPhoneNumber'
                  autoComplete='tel'
                  onChange={handleChangeCompanyPhoneNumber}
                  value={values.companyPhoneNumber}
                  error={!!errors.companyPhoneNumber}
                  helperText={errors.companyPhoneNumber}
                />
              </Grid>
            )}
            <Grid item xs={12} md={values.type === CustomerType.PROFESSIONAL ? 6 : 12}>
              <TextField
                type='tel'
                margin='dense'
                fullWidth
                required={values.type === CustomerType.INDIVIDUAL}
                id='phoneNumber'
                label={values.type === CustomerType.PROFESSIONAL ? 'N° téléphone secondaire' : 'N° téléphone portable'}
                name='phoneNumber'
                autoComplete='tel'
                onChange={handleChangePhoneNumber}
                value={values.phoneNumber}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber ?? (values.type === CustomerType.PROFESSIONAL ? 'Champ facultatif' : '')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin='dense'
                fullWidth
                id='email'
                label={values.type === CustomerType.PROFESSIONAL ? "Adresse email de l'entreprise" : 'Adresse email'}
                name='email'
                autoComplete='email'
                onChange={handleChange}
                value={values.email}
                error={!!errors.email}
                helperText={
                  errors.email ??
                  "L'email est facultatif, mais on vous encourage à le demander pour réduire le papier 🙏🌿 !"
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='notes'
                margin='dense'
                label='Notes internes'
                onChange={handleChange}
                multiline
                rows={3}
                variant='outlined'
                size='small'
                value={values.notes}
                error={!!errors.notes}
                helperText={errors.notes}
                fullWidth
              />
            </Grid>
          </Grid>
          <VehicleForm onCancelForm={onClose} onFormChange={handleFormChange} />
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: theme.palette.grey[200],
            justifyContent: 'space-between',
          }}
        >
          <Button variant='contained' color='inherit' onClick={() => onClose()}>
            Annuler
          </Button>
          <Button disabled={dirty ? !isValid : true} type='submit' variant='contained'>
            Enregistrer
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
});
