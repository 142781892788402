import { type FC, type SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Chip,
  debounce,
  Dialog,
  DialogContent,
  DialogTitle,
  type FilterOptionsState,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import type { Event, Garage } from '@movalib/movalib-commons';
import PastEventsIcon from '@mui/icons-material/History';
import { getFullFormatedEventDate } from '../../helpers/Tools';
import { MyEventDialog } from '../../components/calendar/MyCalendarTypes';
import { setSnackbar } from '../../slices/snackbarSlice';
import { useBoolState } from '../../helpers/hooks/useBoolState';
import { useDispatch } from 'react-redux';
import GarageService from '../../services/GarageService';
import type { AppDispatch } from '../../store';
import type { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import theme from '../../theme';
import { CloseRounded } from '@mui/icons-material';
import { set } from 'lodash';

type CalendarToolbarSearchDialogProps = {
  garageId: Garage['id'];
  isSearchOpen: boolean;
  toggleSearchOpen: () => void;
  onSelectEvent: (event: Event, dialogType: MyEventDialog) => void;
};
type SearchAutoCompleteProps = AutocompleteProps<Event, false, true, false>;

export const CalendarToolbarSearchDialog: FC<CalendarToolbarSearchDialogProps> = ({
  garageId,
  isSearchOpen,
  toggleSearchOpen,
  onSelectEvent,
}: CalendarToolbarSearchDialogProps) => {
  const dispatch = useDispatch();
  const { isHistoryIncluded, toggleHistoryIncluded, setHistoryIncludedFalse } = useBoolState(false, 'historyIncluded');
  const [searchedEvents, setSearchedEvents] = useState<Event[]>([]);
  const queryRef = useRef<string>('');

  const handleChangeAutocomplete = useCallback(
    (_event: SyntheticEvent, newEvent: Event | null) => {
      if (newEvent) {
        onSelectEvent(newEvent, MyEventDialog.DETAILS);
        setSearchedEvents([]);
        queryRef.current = '';
        toggleSearchOpen();
      }
    },
    [onSelectEvent, toggleSearchOpen],
  );
  useEffect(() => {
    if(queryRef.current.length >= 2) {
    debouncedEventsSearch(dispatch, garageId, setSearchedEvents, setSnackbar,  queryRef.current);
    }
  }, [isHistoryIncluded]);

  const handleCloseSearch = useCallback(() => {
    toggleSearchOpen();
    setHistoryIncludedFalse();
  }, [setHistoryIncludedFalse, toggleSearchOpen]);

  const debouncedEventsSearch = debounce(
    (dispatch: AppDispatch, garageId: string, setSearchedEvents: Function, setSnackbar: Function, query: string) => {
      queryRef.current = query;
      GarageService.searchGarageEvents(dispatch, garageId, query, isHistoryIncluded)
        .then((events) => {
          setSearchedEvents(events ? events : []);
        })
        .catch((error) => {
          console.error(error);
          dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
        });
      // Durée du debounce en MS
    },
    300,
  );

  const handleAutocompleteInputChange = useCallback<NonNullable<SearchAutoCompleteProps['onInputChange']>>(
    (_, newInputValue) => {
      if (newInputValue.length >= 2) {
        debouncedEventsSearch(dispatch, garageId, setSearchedEvents, setSnackbar, newInputValue);
      } else {
        queryRef.current = '';
        setSearchedEvents([]);
      }
    },
    [debouncedEventsSearch, dispatch, garageId],
  );

  const getOptionLabel = useCallback<NonNullable<SearchAutoCompleteProps['getOptionLabel']>>((option) => {
    return `${(option.customer?.lastname ?? option.customer?.companyName) || ''} ${option.vehicle?.plate || ''} ${option.quoteId || ''}`.trim();
  }, []);

  const filterOptions = useCallback<NonNullable<SearchAutoCompleteProps['filterOptions']>>(
    (options: Event[], state: FilterOptionsState<Event>) => {
      if (state.inputValue.length < 2) {
        return [];
      } else {
        const filtered = options
          .filter((option) => {
            const label =
              `${(option.customer?.lastname ?? option.customer?.companyName) || ''} ${option.vehicle?.plate || ''} ${option.quoteId || ''}`.trim();
            return label.toLowerCase().includes(state.inputValue.toLowerCase());
          })
          .sort((a, b) => {
            // Convertit les dates en timestamps pour le tri; les dates manquantes sont traitées comme étant '0'
            const dateA = a.start ? new Date(a.start).getTime() : 0;
            const dateB = b.start ? new Date(b.start).getTime() : 0;

            return dateB - dateA; // Tri descendant
          });

        return filtered.slice(0, 50); // Limite le nombre de résultats visibles à 50
      }
    },
    [],
  );

  const AutoCompleteInput = useCallback<SearchAutoCompleteProps['renderInput']>(
    (params) => (
      <TextField
        {...params}
        autoFocus
        label='Nom, immat ...'
        variant='outlined'
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {params.InputProps.endAdornment}
              <Chip
                size='small'
                icon={<PastEventsIcon />}
                onClick={toggleHistoryIncluded}
                variant={isHistoryIncluded ? 'filled' : 'outlined'}
                color={isHistoryIncluded ? 'primary' : 'default'}
                label='ANCIENS RDV'
              />
            </>
          ),
        }}
      />
    ),
    [isHistoryIncluded, toggleHistoryIncluded],
  );

  const AutocompleteOption = useCallback<NonNullable<SearchAutoCompleteProps['renderOption']>>(
    (props, option, state) => {
      // Créez le texte sans JSX
      const text = `${(option.customer?.lastname ?? option.customer?.companyName) || ''}${
        option.vehicle?.plate ? ` - ${option.vehicle.plate}` : ''
      }${option.quoteId ? ` ${option.quoteId}` : ''}`.trim();

      // Effectuez le split
      const parts = text.split(new RegExp(`(${state.inputValue})`, 'gi'));

      return (
        <Box component='li' {...props} sx={{ height: '100%' }}>
          <Grid container>
            {/* Partie 1 : Rendu du texte avec surlignage */}
            <Grid item xs={12}>
              {parts.map((part, index) =>
                part.toLowerCase() === state.inputValue.toLowerCase() ? (
                  <Typography
                    variant='subtitle1'
                    key={`${part}-${index}`}
                    style={{
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap',
                      display: 'inline',
                    }}
                  >
                    {part}
                  </Typography>
                ) : (
                  <Typography
                    variant='subtitle1'
                    key={`${part}-${index}`}
                    style={{
                      fontWeight: 'normal',
                      whiteSpace: 'nowrap',
                      display: 'inline',
                    }}
                  >
                    {part}
                  </Typography>
                ),
              )}
              {/* Ajout de l'icône si le véhicule est présent */}
              {option.vehicle?.plate && (
                <i
                  className='fas fa-solid fa-car'
                  style={{
                    color: theme.palette.grey[800],
                    marginLeft: '3px',
                    marginTop: '5.5px',
                    fontSize: '0.9rem',
                  }}
                />
              )}
            </Grid>

            {/* Partie 2 : Informations additionnelles */}
            <Grid item xs={12}>
              <Typography variant='button'>{option.title}</Typography>
              <Typography variant='body2'>
                <b>{getFullFormatedEventDate(option.start, option.end)}</b>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      );
    },
    [],
  );

  return (
    <Dialog
      open={isSearchOpen}
      onClose={handleCloseSearch}
      fullWidth
      maxWidth='sm'
      sx={{ height: { md: '40%', xs: 'auto' } }}
    >
      <Toolbar
        disableGutters
        sx={{
          display: 'block',
          background: theme.palette.grey[200],
          minHeight: 3,
          p: 0,
        }}
      >
        <DialogTitle id='search-dialog-title'>
          <Box position='relative'>
            <Typography
              variant='h6'
              sx={{
                textTransform: 'uppercase',
                fontStyle: 'bold',
                textAlign: 'center',
              }}
            >
              Rechercher un <b>rendez-vous</b>
            </Typography>

            <IconButton
              sx={{ position: 'absolute', right: 0, top: 0, zIndex: 1 }}
              size='small'
              aria-label='close'
              onClick={handleCloseSearch}
              title={'Fermer'}
            >
              <CloseRounded />
            </IconButton>
          </Box>
        </DialogTitle>
      </Toolbar>
      <DialogContent>
        <FormControl sx={{ width: '100%', mt: 1 }}>
          <Autocomplete
            id='search-autocomplete'
            autoFocus
            options={searchedEvents}
            onChange={handleChangeAutocomplete}
            onInputChange={handleAutocompleteInputChange}
            getOptionLabel={getOptionLabel}
            filterOptions={filterOptions}
            renderInput={AutoCompleteInput}
            renderOption={AutocompleteOption}
            clearIcon={false} // Désactive l'icône de nettoyage
            popupIcon={null} // Désactive l'icône d'ouverture et de fermeture
            noOptionsText='Aucun résultat(s)' // Cache le message "No options"
          />
        </FormControl>
      </DialogContent>
    </Dialog>
  );
};
