import type { FunctionComponent } from 'react';
import type { PricingPackageBasicProps } from '../PricingPackage';
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, type SelectChangeEvent, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { flexCenterCol } from '../../../../helpers/Tools';
import { stylePLV } from '../pricingTools';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
interface VisibilityOffersProps extends PricingPackageBasicProps {
  giftPrintPLV: boolean;
  printPLV: number;
  giftPrint: () => void;
  handleChangePrintPLV: (e: SelectChangeEvent<number>) => void;
  initData?: number;
}

export interface VisibilityOffersData {
  id: number;
  name: string;
  description: string;
  offers: [{ id: number, include: boolean; optional: boolean, defaultValueId: number, 
    values: [{ id: number, description: string, price: number, quantity: number}] 
  }];
  
}

export const VisibilityOffers: FunctionComponent<VisibilityOffersProps> = ({
  movaPackage,
  options,
  giftPrintPLV,
  printPLV,
  giftPrint,
  handleChangePrintPLV,
  onlyView,
  initData,
  formFormik
}: VisibilityOffersProps) => {

  useEffect(() => {
    if(formFormik) {
      handleChangePrintPLV({
        target: {
          value: formFormik.values[`plv${movaPackage.id}`],
        },
      } as  React.ChangeEvent<HTMLInputElement>);
   }
  }, [formFormik.values.repaireSupp]);

  const combinedHandleChange = (event: SelectChangeEvent<number>) => {
    formFormik.setFieldValue(`plv${movaPackage.id}`, event.target.value);
    formFormik.handleChange(event); 
    handleChangePrintPLV(event);
  };
  return (
    <Box style={flexCenterCol} sx={{minHeight: '12%', display: 'flex', width: '100%', flexDirection: 'column' }}>
      {options?.visibilite
        ?.filter((item: VisibilityOffersData) => item.id === 9)
        .map((item: VisibilityOffersData) => (
          <React.Fragment key={item.id}>
            <Box
              style={{ ...flexCenterCol, ...stylePLV }}
              sx={{width: '100%'}}
            >
              {item.offers.filter((offre: VisibilityOffersData["offers"][0]) => offre.id === movaPackage.id)[0].include ? (
                <CheckIcon className="CheckIcon" />
              ) : (
                <CloseIcon />
              )}
            </Box>
          </React.Fragment>
        ))}

      {options?.visibilite
        ?.filter((item: VisibilityOffersData) => item.id === 10)
        .map((item: VisibilityOffersData) => (
          <React.Fragment key={item.id}>
            <Box style={{ ...flexCenterCol, ...stylePLV }} sx={{ width: '85%', flexDirection: 'column', minHeight: '66px !important' }}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
                <IconButton onClick={giftPrint} sx={{ margin: 'auto' }}>
                  <CardGiftcardOutlinedIcon/>
                </IconButton>
                <FormControl fullWidth>
                  <InputLabel id='plv-select-label'>Impression PLV</InputLabel>
                  <Select
                    size='small'
                    disabled={onlyView}
                    labelId='plv-select-label'
                    id='plv-select'
                    onChange={combinedHandleChange}
                    name='plv'
                    value={formFormik.values[`plv${movaPackage.id}`]}
                    label={item.name}
                  >
                    {item?.offers?.filter((offre: VisibilityOffersData["offers"][0]) => offre.id === movaPackage.id)[0].defaultValueId ===
                      undefined && <MenuItem sx={{ height: '36px' }} value='' />}
                    {item?.offers
                      ?.filter((offre: VisibilityOffersData["offers"][0]) => offre.id === movaPackage.id)[0]
                      ?.values?.map((value: VisibilityOffersData["offers"][0]["values"][0]) => (
                        <MenuItem key={value.id} value={value.id}>
                          {value.description}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Typography
                variant='body2'
                className='my-color'
                sx={{
                  minHeight: '20px',
                  textDecoration: giftPrintPLV ? 'line-through' : 'none',
                }}
              >
                {printPLV !== 0 && `+ ${printPLV.toFixed(2)}€/mois`}
              </Typography>
            </Box>
          </React.Fragment>
        ))}
    </Box>
  );
};

export default VisibilityOffers;
