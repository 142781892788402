import {
  type Customer,
  CustomerType,
  DayOfWeek,
  type Event,
  EventState,
  EventType,
  type Garage,
  Logger,
  OrderPreference,
  OrderState,
  type Prestation,
  type User,
} from '@movalib/movalib-commons';
import type { Theme } from '@mui/material';
import {
  type Locale,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  isValid,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { fr } from 'date-fns/locale';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import type { CSSProperties } from 'react';
import { persistor } from '../store';
import {
  COOKIE_DEFAULT_EXPIRES,
  COOKIE_IS_AUTHENTICATED,
  COOKIE_PRO_TOKEN,
  COOKIE_SELECTED_GARAGE_ID,
  COOKIE_USER_ID,
  FIRE_EVENT_DEFAULT_HOURS,
  PALETTE_THIRD_COLOR_LIGHT,
  PALETTE_THIRD_COLOR_MAIN,
} from './Constants';
import { compareDates } from './DateUtils';
import { PeriodType, VehicleDepositPreference } from './Enums';

export function getCustomerFirstLetter(customer: Customer): string {
  let firstLetter: string = '';

  // Vérifie le type de client et la validité des champs
  if (customer.type === CustomerType.INDIVIDUAL && customer.lastname && customer.lastname.length > 0) {
    firstLetter = customer.lastname[0].toUpperCase();
  } else if (customer.type === CustomerType.PROFESSIONAL && customer.companyName && customer.companyName.length > 0) {
    firstLetter = customer.companyName[0].toUpperCase();
  }

  // Vérifie si la première lettre est un chiffre ou une lettre, sinon retourne un fallback
  return /[0-9]/.test(firstLetter) ? '0-9' : firstLetter || ''; // Si tout échoue, retourne une chaîne vide
}

export function cleanString(input: string) {
  // Supprime les espaces, les chiffres, et une gamme complète de caractères invisibles au début et la fin de la chaîne
  return input.replace(/^[\p{Z}\s\u00A0\u1680\u2000-\u200F\u202F\u205F\u3000\uFEFF\u3164\u0009-\u000D]+|[\p{Z}\s\u00A0\u1680\u2000-\u200F\u202F\u205F\u3000\uFEFF\u3164\u0009-\u000D]+$/gu, '');

}

export const getDayOfWeekFromString = (day: string): DayOfWeek | undefined => {
  const dayUpperCase = day.toUpperCase();
  return DayOfWeek[dayUpperCase as keyof typeof DayOfWeek];
};

export const importIcon = (iconName: string): string => {
  try {
    const lowerCaseIconName = iconName.toLowerCase();
    return require(`../assets/images/icons/${lowerCaseIconName}.png`);
  } catch (_error) {
    console.error(`Icon not found: ${iconName}.png`);
    return ''; // or a path to a default icon
  }
};

export const isDemoEnv = () => {
  const hostname: string = window?.location?.hostname;
  // Sommes-nous sur l'environnement de démonstration ?
  if (hostname) {
    return hostname.includes('demo');
  }
  return false;
};

export function getVehicleDepositPreferenceLabel(vehicleDepositPreference: VehicleDepositPreference | '') {
  if (vehicleDepositPreference) {
    switch (vehicleDepositPreference) {
      case VehicleDepositPreference.DAY_BEFORE:
        return 'La veille';
      case VehicleDepositPreference.SAME_DAY:
        return 'Le jour même';
    }
  }
  return '';
}

export function getVehicleDepositPreferenceFromEvent(event: Event): VehicleDepositPreference | '' {
  if (event?.start && event.vehicleDepositDate) {
    return compareDates(event.vehicleDepositDate, event.start) < 0
      ? VehicleDepositPreference.DAY_BEFORE
      : VehicleDepositPreference.SAME_DAY;
  }
  return '';
}

function createDateFromTimeString(timeString: string, referenceDate: Date): Date {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  const date = new Date(referenceDate);
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);
  date.setMilliseconds(0);
  return date;
}

export function getEventVehicleDepositDate(startDate: Date, garage: Garage): Date {
  const { zonedAmVehicleDeposit, zonedPmVehicleDeposit } = garage;

  // Extraire le jour de la semaine de la date de départ
  const dayOfWeek = startDate.toLocaleDateString('en-US', { weekday: 'long' }).toUpperCase();

  // Trouver l'horaire d'ouverture spécifique pour le jour de la semaine
  const dailySchedule = garage.schedules.find((schedule) => schedule.dayOfWeek === dayOfWeek);

  let amDate: Date | undefined;
  let pmDate: Date | undefined;

  if (zonedAmVehicleDeposit) {
    amDate = createDateFromTimeString(String(zonedAmVehicleDeposit), startDate);
  }

  if (zonedPmVehicleDeposit) {
    pmDate = createDateFromTimeString(String(zonedPmVehicleDeposit), startDate);
  }

  // Ajustement des horaires d'ouverture spécifique pour le jour de la semaine
  if (dailySchedule && dailySchedule.intervals.length > 0) {
    const earliestInterval = dailySchedule.intervals[0];
    const earliestOpeningTime = createDateFromTimeString(String(earliestInterval.startTime), startDate);

    // Si l'horaire d'ouverture est plus tardif que l'horaire AM global, on utilise l'horaire spécifique
    if (amDate && earliestOpeningTime && earliestOpeningTime > amDate) {
      amDate = earliestOpeningTime;
    }
  }

  let closestDeposit: Date | undefined;

  if (amDate && pmDate && startDate) {
    // Les deux horaires sont disponibles, trouver le plus proche
    closestDeposit = amDate;

    // Si l'heure de dépôt de l'aprem est antérieur à l'heure du rdv, on l'applique
    if (pmDate <= startDate) {
      closestDeposit = pmDate;
    }
  } else if (amDate) {
    // Seulement l'horaire du matin est disponible
    closestDeposit = amDate;
  } else if (pmDate) {
    // Seulement l'horaire de l'après-midi est disponible
    closestDeposit = pmDate;
  }

  // Si closestDeposit est défini, retourner la date avec l'horaire le plus proche
  if (closestDeposit) {
    const resultDate = new Date(startDate);
    resultDate.setHours(closestDeposit.getHours());
    resultDate.setMinutes(closestDeposit.getMinutes());
    resultDate.setSeconds(closestDeposit.getSeconds());
    resultDate.setMilliseconds(closestDeposit.getMilliseconds());

    return resultDate;
  }

  // Si aucune des horaires n'est disponible, retourner la date de départ inchangée
  return new Date(startDate);
}

export function isEnvProd() {
  return process.env.NODE_ENV === 'production';
}

export function updateServiceWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .getRegistration()
      .then((registration) => {
        if (registration) {
          registration
            .update()
            .then(() => {
              console.log('Service Worker Update Checked');
              // Ici, vous pouvez notifier l'utilisateur d'une mise à jour ou rafraîchir la page
            })
            .catch((err) => {
              console.error('Error during Service Worker update:', err);
            });
        }
      })
      .catch((err) => {
        console.error('Error during Service Worker registration retrieval:', err);
      });
  }
}
/**
 * Formater les dates en chaînes de caractères qui reflètent le fuseau horaire local sans conversion.
 * Idéal pour transmettre les dates avec le fuseau horaire à l'API lors d'un appel à JSON.stringify
 * TODO : rendre dynamique le timeZone en paramètre
 * @param date
 * @returns
 */
export function formatLocalDateToISOString(date: Date, timeZone = 'Europe/Paris'): string {
  return moment(date).tz(timeZone).format('YYYY-MM-DDTHH:mm:ssZ') + '[' + timeZone + ']';
}

export function capitalizeFirstLetterNew(string: string) {
  const separators = string.match(/\s+|-/g) || []; // Trouve tous les séparateurs (espaces ou tirets)
  const words = string.split(/\s+|-/); // Divise la chaîne sur les séparateurs

  return words
    .map((word, index) => (word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : ''))
    .reduce((acc, word, index) => {
      // Ajoute le mot et le séparateur original (s'il existe) à la chaîne accumulée
      const separator = separators[index] || '';
      return `${acc}${word}${separator}`;
    }, '');
}

export function formatTime(time: '' | Date | null): string | null {
  if (!time) return null;

  const date = new Date(time);
  if (isValid(date)) {
    return format(date, 'HH:mm');
  } else {
    return ''; // ou retourner une chaîne de caractères par défaut
  }
}

export function formatIban(iban: string): string {
  // Supprime tous les espaces existants pour éviter les problèmes de formatage
  const cleanIban = iban.replace(/\s+/g, '');

  // Utilise une expression régulière pour insérer un espace tous les 4 caractères
  return cleanIban.replace(/(.{4})/g, '$1 ').trim();
}

export const hasRole = (user: User, roleName: string): boolean => {
  return user.roles.some((role) => role.name === roleName);
};

export const formatEurosCurrency = (number: number) => {
  return `${number.toLocaleString('fr-FR')} €`;
};

export const isNewOrActiveEvent = (event: Event): boolean => {
  return event.state !== undefined && event.state !== EventState.CANCELLED && event.state !== EventState.DONE;
};

export const isActiveEvent = (event: Event): boolean => {
  return (
    event.state !== undefined &&
    event.state !== EventState.NEW &&
    event.state !== EventState.CANCELLED &&
    event.state !== EventState.DONE
  );
};

export const truncateText = (text: string, size: number) => {
  if (text && size) {
    return text.length > size ? text.substring(0, size) + '...' : text;
  }
  return text;
};

export const purgeGlobalSate = () => {
  localStorage.clear();

  if (isEnvProd()) {
    Logger.enableLogging();
  }

  // Pour purger l'état persisté
  persistor.purge();
};

/**
 * Recherche au sein de la liste des opérations d'une prestation
 * s'il existe au moins une application de définit. Dans ce cas, cela signifie
 * qu'il faut choisir l'application pour cette prestation
 * @param prestation
 * @returns
 */
export const applicationChoiceRequired = (prestation: Prestation | undefined): boolean => {
  if (prestation && prestation.operations) {
    // Si au moins une opérations dans cette prestation contient une application, alors il faut choisir
    return prestation.operations?.filter((o) => o.application).length > 0;
  }

  return false;
};

export const getOrderPreferenceLabel = (orderPreference: OrderPreference | undefined): string => {
  switch (orderPreference) {
    case OrderPreference.LOW_RANGE:
      return 'Low cost';
    case OrderPreference.MID_RANGE:
      return 'Intermédiaire';
    case OrderPreference.HIGH_RANGE:
      return 'Haut de gamme';
  }

  return 'Inconnu';
};

export const getProductOrderColor = (state: OrderState | undefined) => {
  switch (state) {
    case OrderState.NEW:
    case OrderState.CANCELLED:
      return 'default';
    case OrderState.QUOTE_REQUESTED:
      return 'secondary';
    case OrderState.ORDERED:
      return 'secondary';
    case OrderState.DONE:
      return 'primary';
    default:
      return 'default';
  }
};

export const getProductOrderState = (state: OrderState | undefined) => {
  switch (state) {
    case OrderState.NEW:
    case OrderState.CANCELLED:
    case OrderState.DONE:
      return '';
    case OrderState.QUOTE_REQUESTED:
      return 'Demande de devis envoyée ...';
    case OrderState.ORDERED:
      return 'Commande envoyée ...';
  }
};

export function getRotatedIconStyle(): CSSProperties {
  return {
    position: 'relative',
    width: '100%',
    top: '-20px',
    zIndex: 200,
    left: '-49%',
    height: '40px',
    marginBottom: '-30px',
    transform: 'rotate(-20deg)',
  };
}

export function sortEventsAsc(events: Event[]): Event[] {
  return events.slice().sort((a, b) => {
    if (a.start && b.start) {
      return a.start.getTime() - b.start.getTime(); // Compare les dates normalement si elles existent toutes les deux
    } else if (a.start) {
      return -1; // b.start est undefined, donc a vient en premier
    } else if (b.start) {
      return 1; // a.start est undefined, donc b vient en premier
    } else {
      return 0; // Les deux dates sont undefined, donc elles sont équivalentes
    }
  });
}

export function sortEventsDesc(events: Event[]): Event[] {
  return events.slice().sort((a, b) => {
    if (b.start && a.start) {
      return b.start.getTime() - a.start.getTime(); // Compare les dates normalement si elles existent toutes les deux
    } else if (b.start) {
      return -1; // a.start est undefined, donc b vient en premier
    } else if (a.start) {
      return 1; // b.start est undefined, donc a vient en premier
    } else {
      return 0; // Les deux dates sont undefined, donc elles sont équivalentes
    }
  });
}
export const flexStart: CSSProperties = {
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
};

export const flexEnd: CSSProperties = {
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
};

export const flexLeftRow: CSSProperties = {
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
};

export const flexCenterRow: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const flexCenterCol: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};

export const isWithinLastIndicatedHours = (givenDate: Date | undefined, hours: number): boolean => {
  if (givenDate) {
    const now = new Date();
    const timeDifference = givenDate.getTime() - now.getTime();
    const timeDifferenceInHours = timeDifference / (1000 * 60 * 60);

    return timeDifferenceInHours > 0 && timeDifferenceInHours < hours;
  }

  return false;
};

export const deleteCookie = (name: string) => {
  if (name) {
    Cookies.remove(name);
  }
};

export const readCookie = (name: string) => {
  if (name) {
    return Cookies.get(name);
  }
};

/**
 * Création d'un cookie.
 * expires : nombre de jours avant expiration
 * sameSite : 'None', ce qui signifie que le cookie sera envoyé même lors de requêtes intersites TODO : réactiver !
 * secure : pour s'assurer que le cookie n'est envoyé que sur une connexion sécurisée (HTTPS)
 * @param name : nom du cookie
 * @param value : valeur du cookie
 */
export const createCookie = (name: string, value: string) => {
  if (name && value) {
    Cookies.set(name, value, { expires: COOKIE_DEFAULT_EXPIRES, sameSite: 'None', secure: true });
  }
};

export const deleteCookies = () => {
  Cookies.remove(COOKIE_IS_AUTHENTICATED);
  Cookies.remove(COOKIE_USER_ID);
  Cookies.remove(COOKIE_PRO_TOKEN);
  Cookies.remove(COOKIE_SELECTED_GARAGE_ID);
};

export const getDaysDiff = (start: Date, end: Date): number => {
  if (start && end) {
    const diffInTime = end.getTime() - start.getTime();
    const diffInDays = Math.round(diffInTime / (1000 * 60 * 60 * 24));
    return diffInDays + 1;
  }

  return 0;
};

export const getEventStateBorder = (event: Event): string => {
  // Bordure par défaut
  const defaultBorder = '1px solid white';

  if (event.state === EventState.NEW) {
    return isWithinLastIndicatedHours(event.start, FIRE_EVENT_DEFAULT_HOURS) ? '2px solid red' : '2px solid #00BF08';
  }
  if (event.state === EventState.REJECTED) {
    return '2px dashed red';
  }

  return defaultBorder;
};

export const getEventStateColor = (event: Event, theme: Theme): string => {
  // Couleur par défaut, pour les event d'un autre type que RDV
  let color: string = theme.palette.grey[200];

  // Si l'event est une note, alors on utilise la couleur associée
  if (event?.type === EventType.NOTE) {
    return event.color === null || event?.color?.length === 0 ? '#4285F4' : event?.color!;
  }
  if(event?.type === EventType.UNAVAILABILITY && event.id === '') {
    return theme.palette.grey[100]
  }
  if(event?.end && event?.type === EventType.UNAVAILABILITY && event.id !== '') {
    const isPastEvent = event?.end < new Date();
    return isPastEvent ? theme.palette.grey[200] : theme.palette.grey[400]
  }

  if (event?.end && event.type === EventType.APPOINTMENT && theme) {
    const isPastEvent = event?.end < new Date();

    switch (event.state) {
      case EventState.NEW:
        color = theme.palette.background.default;
        break;
      case EventState.ACCEPTED:
      case EventState.REJECTED:
        color = isPastEvent ? PALETTE_THIRD_COLOR_LIGHT : PALETTE_THIRD_COLOR_MAIN;
        break;
      case EventState.SCHEDULED:
        color = isPastEvent ? theme.palette.secondary.light : theme.palette.secondary.main;
        break;
      case EventState.COMPLETED:
        color = isPastEvent ? theme.palette.primary.light : theme.palette.primary.main;
        break;
      case EventState.DONE:
        color = theme.palette.primary.light;
        //color = isPastEvent ? theme.palette.primary.light : theme.palette.primary.main;
        break;
      case EventState.CANCELLED:
        color = isPastEvent ? theme.palette.grey[200] : theme.palette.grey[400];
        break;

      default:
        color = isPastEvent ? theme.palette.grey[200] : theme.palette.grey[400];
        break;
    }
  }

  return color;
};

export const getEventStateLabel = (event: Event): string => {
  if (event) {
    if (event.type === EventType.NOTE) {
      return 'Note';
    }
    if (event.type === EventType.UNAVAILABILITY) {
      return 'Indispo';
    }

    switch (event.state) {
      case EventState.NEW:
        return 'Rendez-vous à confirmer';
      case EventState.ACCEPTED:
        return 'Devis client en attente';
      case EventState.REJECTED:
        return 'Devis refusé par le client';
      case EventState.SCHEDULED:
        return 'Dispo pièces à confirmer';
      case EventState.COMPLETED:
        return 'Rendez-vous prêt !';
      case EventState.DONE:
        return 'Rendez-vous clôturé';
      case EventState.CANCELLED:
        return 'Rendez-vous annulé';
    }
  }
  return '';
};
export const getFullFormatedEventDate = (start: Date | undefined, end: Date | undefined) => {
  // Le new Date() permet d'être certain de manipuler une date (si le json renvoie un string)
  if (start && end) {
    start = new Date(start);
    end = new Date(end);
    if (start.getDay() === end.getDay()) {
      return `${capitalizeFirstLetter(format(start, "eeee dd MMMM yyyy 'de' HH':'mm", { locale: fr }))}
        ${format(end, "' à ' HH':'mm", { locale: fr })}`;
    } else {
      return `${capitalizeFirstLetter(format(start, "eeee dd MMMM yyyy HH':'mm", { locale: fr }))}
        ${format(end, "' / 'eeee dd MMMM yyyy HH':'mm", { locale: fr })}`;
    }
  }
};

export const getFormatedEventDate = (start: Date | undefined, end: Date | undefined) => {
  // Le new Date() permet d'être certain de manipuler une date (si le json renvoie un string)
  if (start && end) {
    start = new Date(start);
    end = new Date(end);
    if (start.getDay() === end.getDay()) {
      return `${capitalizeFirstLetter(format(start, "eeee dd MMMM 'de' HH':'mm", { locale: fr }))}
        ${format(end, "' à ' HH':'mm", { locale: fr })}`;
    } else {
      return `${capitalizeFirstLetter(format(start, "eeee dd MMMM HH':'mm", { locale: fr }))}
        ${format(end, "' / 'eeee dd MMMM HH':'mm", { locale: fr })}`;
    }
  }
};
/**
 * Function to generate time values for the components
 * @param start
 * @param end
 * @returns Array of string representing each time step
 */
export const generateTimeValues = (start: number, end: number) => {
  const times: string[] = [];
  for (let i = start; i <= end; i++) {
    for (let j = 0; j < 60; j += 30) {
      const hour = i.toString().padStart(2, '0');
      const minute = j.toString().padStart(2, '0');
      times.push(`${hour}:${minute}`);
    }
  }

  return times;
};

export const getIconUrl = (icon: string) => {
  // Assuming the icons are stored in the public folder
  return `${process.env.PUBLIC_URL}/icons/${icon}.svg`;
};

export function getDayLabel(day: DayOfWeek) {
  return day.toString();
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function getHoursBetween(date1: Date, date2: Date): number {
  const diffInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
  return diffInMilliseconds / (1000 * 60 * 60);
}

export const copyToClipboard = (text: string | undefined) => {
  if (text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch((err) => {
        console.error('Error in copying text: ', err);
      });
  }
};

export function capitalizeFirstLetter(str: string): string {
  if (str.length === 0) {
    return str;
  }

  const firstChar = str.charAt(0).toUpperCase();
  const restOfString = str.slice(1);

  return firstChar + restOfString;
}

export function getEndOf(date: Date, endOf: PeriodType, locale: Locale = fr): Date {
  switch (endOf) {
    case PeriodType.WEEK:
      return endOfWeek(date, { weekStartsOn: 1, locale });

      case PeriodType.FOUR_DAY:
      return endOfWeek(date, { weekStartsOn: 1, locale });
    case PeriodType.MONTH:
      return endOfMonth(date);

    case PeriodType.DAY:
      return endOfDay(date);

    default:
      throw new Error(`Invalid StartEndOfType value: ${endOf}`);
  }
}

export function getStartOf(date: Date, startOf: PeriodType, locale: Locale = fr): Date {
  switch (startOf) {
    case PeriodType.WEEK:
      return startOfWeek(date, { weekStartsOn: 1, locale });

    case PeriodType.FOUR_DAY:
     return startOfWeek(date, { weekStartsOn: (date.getDay() as 0|1|2|3|4|5|6), locale });

    case PeriodType.MONTH:
      return startOfMonth(date);

    case PeriodType.DAY:
      return startOfDay(date);

    default:
      throw new Error(`Invalid StartEndOfType value: ${startOf}`);
  }
}

/**
 * Prend un Objet de type <string, string> et la clé recherchée en tant que paramètres.
 * Elle utilise Object.keys pour obtenir toutes les clés de l'objet,
 * puis Array.find pour trouver la clé correspondante.
 * @param obj
 * @param key
 * @returns Si une correspondance est trouvée,
 * la fonction renvoie la valeur correspondante à partir de l'objet.
 * Si aucune correspondance n'est trouvée, elle renvoie undefined.
 */
export const findValueByKey = (obj: Record<string, string>, key: string): string | undefined => {
  const keys = Object.keys(obj);
  const foundKey = keys.find((k) => k === key);

  if (foundKey) {
    return obj[foundKey];
  }

  return undefined;
};

export function isEmpty(data: Object): boolean {
  return Object.keys(data).length === 0;
}

export const isInvalidMobileNumber = (phoneNumber = '') => {
  // Vérifier si le numéro commence par 06 ou 07
  return !/^0[67]/.test(phoneNumber);
};

export const isInvalidEmail = (email = '') => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  // Retourne true si l'e-mail ne correspond pas au pattern
  return !emailPattern.test(email);
};