import type React from 'react';
import { Box, Typography, Switch, Grid, Button, darken, IconButton, Link } from '@mui/material';
import theme from '../theme';
import SettingsIcon from '@mui/icons-material/Settings';
import { PALETTE_THIRD_COLOR_MAIN } from '../helpers/Constants';
import { flexCenter } from '@movalib/movalib-commons/dist/src/helpers/Tools';

interface CustomCardProps {
  title: string;
  description: string;
  image: string;
  isActive: boolean;
  onChange: (checked: boolean) => void;
  isIncludedText?: string | null;
}

const CustomOption: React.FC<CustomCardProps> = ({
  title,
  description,
  image,
  isActive,
  onChange,
  isIncludedText = null,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        p: 2,
        border: `2px solid ${isActive ? theme.palette.primary.dark : theme.palette.text.secondary}`,
        borderRadius: '25px',
        maxWidth: '650px',
      }}
    >
      {/* Image and optional text */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          pr: 2.5,
        }}
      >
        <img style={{ width: '125px', filter: isActive ? 'none' : 'grayscale(100%)' }} src={image} alt={title} />
        {isIncludedText && (
          <Typography
            variant='body1'
            fontFamily={'CarterOne'}
            color={isActive ? theme.palette.primary.dark : theme.palette.text.secondary}
            sx={{ pt: 1 }}
          >
            <b>{isIncludedText}</b>
          </Typography>
        )}
      </Box>

      {/* Title and description */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          justifyContent: 'start',
          width: '350px',
        }}
      >
        <Typography
          color={isActive ? theme.palette.primary.dark : theme.palette.text.secondary}
          fontFamily={'CarterOne'}
          sx={{ mb: 1, fontSize: '1.1rem' }}
        >
          <b>{title}</b>
        </Typography>
        <Typography variant='body1' color={theme.palette.text.secondary}>
          {description}
        </Typography>
      </Box>

      {/* Switch and status */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
        }}
      >
        <Switch checked={isActive} onChange={(e) => onChange(e.target.checked)} color='primary' />
        <Typography
          fontFamily={'CarterOne'}
          color={isActive ? theme.palette.primary.dark : theme.palette.text.secondary}
          sx={{ fontSize: '1.1rem' }}
          style={flexCenter}
        >
          {isActive ? 'Actif' : 'Inactif'}
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomOption;
