import { GarageService, MovaAppType, VehicleService } from '@movalib/movalib-commons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CUSTOMERS } from '../customer/CustomerQueryKeys';
import { GARAGES } from '../garage/GarageQueryKeys';

export const useGetVehicleDetails = () =>
  useMutation({
    mutationFn: async (plate: string) => VehicleService.getVehicleDescription(MovaAppType.GARAGE, plate),
  });

export const usePatchVehicleInfos = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: VehicleService.editVehicle,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [CUSTOMERS] });
    },
  });
};
export const useGetVehicleGarage = () =>
  useMutation({
    mutationFn: async (garageId: string) => await GarageService.getAllGarageVehicles(garageId),
  });

  