import { GarageService } from "@movalib/movalib-commons";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { GARAGE_VEHICLES } from "./GarageQueryKeys";

export const useFetchGarageVehicles = (garageId: string) =>
    useQuery({
      queryKey: [GARAGE_VEHICLES, garageId], // Add the queryKey property
      queryFn: async () => {
        const { data: garageVehicles } = await GarageService.getAllGarageVehicles(garageId);
        return garageVehicles ? garageVehicles : undefined;
      }
    });

    export const useGarageAddVehicle = () => {
        const queryClient = useQueryClient();
        return useMutation({
          mutationFn: GarageService.addGarageVehicle,
          onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [GARAGE_VEHICLES] });
          },
        });
      };

      export const useGarageDeleteVehicle = () => {
        const queryClient = useQueryClient();
        return useMutation({
          mutationFn: GarageService.deleteGarageVehicle,
          onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [GARAGE_VEHICLES] });
          },
        });
      };