import {
    formatFrenchVehiclePlate,
    GarageService,
    Logger,
    oldRegexPlate,
    regexPlate,
    type VehicleGarage,
    type Garage,
} from '@movalib/movalib-commons';
import { Box, Button, CircularProgress, Grid, IconButton, InputAdornment, Switch, TextField, Typography } from '@mui/material';
import { useEffect, useState, type FunctionComponent } from 'react';
import theme from '../../../theme';
import { flexCenterCol, flexCenterRow, flexStart } from '../../../helpers/Tools';
import CloseIcon from '@mui/icons-material/Close';
import { VehiclePlateFormat } from '@movalib/movalib-commons/dist/src/helpers/Enums';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../../slices/snackbarSlice';
import { useGetVehicleDetails } from '../../../query/vehicle/VehicleQuery';
import {
    useFetchGarageVehicles,
    useGarageAddVehicle,
    useGarageDeleteVehicle,
} from '../../../query/garage/GarageVehicle';
import SearchIcon from '@mui/icons-material/Search';

interface LoanerVehicleProps {
    garage: Garage;
    refreshGarage: () => void;
}
const LoanerVehicle: FunctionComponent<LoanerVehicleProps> = ({ garage, refreshGarage }) => {
    const [isActive, setIsActive] = useState(garage.loanerVehicleRequestActive);
    const [value, setValue] = useState<string>('');
    const [foundVehicle, setFoundVehicle] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [isMaxLoanerVehicles, setIsMaxLoanerVehicles] = useState<boolean>(false);
    const [displaySearch, setDisplaySearch] = useState<boolean>(false);

    const { mutateAsync: getVehicleDetails, isPending } = useGetVehicleDetails();
    const { data: garageVehicle } = useFetchGarageVehicles(garage.id);
    const { mutateAsync: createVehicle } = useGarageAddVehicle();
    const { mutateAsync: deleteVehicle } = useGarageDeleteVehicle();

    const [lastLength, setLastLength] = useState<number>(0); // Ajout d'un état pour stocker la longueur précédente
    const dispatch = useDispatch();
    useEffect(() => {
        setIsMaxLoanerVehicles(garageVehicle?.length >= 10);
    }, [garageVehicle]);

    const handleChangeIsActive = (v: boolean) => {
        const request: any = { loanerVehicleRequestActive: v };
        GarageService.toogleGarageLoanerVehicleRequest(garage.id, request)
            .then((response) => {
                if (response.success) {
                    setIsActive(v);
                    dispatch(
                        setSnackbar({
                            open: true,
                            message: `L\'option véhicule de prêt pour les clients est ${v ? 'activé' : 'desactivé'}`,
                            severity: 'success',
                        }),
                    );
                    refreshGarage();
                } else {
                    dispatch(
                        setSnackbar({
                            open: true,
                            message: `Impossible ${v ? "d'activer" : ' de désactiver'} l\'option véhicule de prêt pour les clients`,
                            severity: 'error',
                        }),
                    );
                }

            })
            .catch((e) => {
                dispatch(
                    setSnackbar({
                        open: true,
                        message: `Impossible ${v ? "d'activer" : ' de désactiver'} l\'option véhicule de prêt pour les clients`,
                        severity: 'error',
                    }),
                );
            });
    };
    const [loadingPlate, setLoadingPlate] = useState(false);
    const onValidPlateProxy = async (plate: string) => {
        setFoundVehicle('');
        setDisplaySearch(false);
        setError('');
        setLoadingPlate(true);
        const response = await getVehicleDetails(plate);
        if (response.success && response.data) {
            setFoundVehicle(response.data.split('  ')[0]);
        } else {
            setError('Identification du véhicule impossible');
        }
        setLoadingPlate(false);
    };

    const getPlateFormat = (plate: string): VehiclePlateFormat | undefined => {
        if (plate === '') {
            // Si la saisie est vide, retournez un format par défaut (nouveau format, par exemple)
            return undefined;
        }

        if (/^[A-Za-z]/.test(plate)) {
            // Commence par une lettre => nouveau format
            return VehiclePlateFormat.FRENCH_NEW;
        } else if (/^\d/.test(plate)) {
            // Commence par un chiffre => ancien format
            return VehiclePlateFormat.FRENCH_OLD;
        }
        Logger.error('Format de plaque inconnu');
        // On retourne le nouveau format par défaut
        return VehiclePlateFormat.FRENCH_NEW;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFoundVehicle('');
        setError('');
        let inputValue = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, ''); // Convertir en majuscules et supprimer les caractères non valides
        // Si la saisie commence par une lettre, on contrôle l'ancien format, sinon le nouveau
        switch (getPlateFormat(inputValue)) {
            case VehiclePlateFormat.FRENCH_NEW: {
                setDisplaySearch(false);
                handleChangeFrenchNew(inputValue);
                break;
            }

            case VehiclePlateFormat.FRENCH_OLD: {
                setDisplaySearch(true);
                handleChangeFrenchOld(inputValue);
                break;
            }

            case undefined: {
                setDisplaySearch(false);
                setValue('');
            }
        }
    };

    const handleChangeFrenchOld = (inputValue: string) => {
        // ON bloque la saisie à 12 caractères max (limite des anciennes plaques)
        if (!(inputValue.length > 12)) {
            setValue(inputValue);
            if (oldRegexPlate.test(inputValue)) {
                // onValidPlateProxy(inputValue);
            } else {
                setFoundVehicle('');
            }
        }

        setLastLength(inputValue.length); // Mettre à jour la longueur précédente
    };

    const handleChangeFrenchNew = (inputValue: string) => {
        // Vérifier si l'utilisateur est en train de supprimer un caractère
        const isDeleting = inputValue.length < lastLength;

        // Supprimer les tirets pour avoir une chaîne propre
        const cleanInput = inputValue.replace(/-/g, '');

        // Ajouter des tirets aux positions appropriées
        if (cleanInput.length > 1 && !(cleanInput.length === 2 && isDeleting)) {
            inputValue = `${cleanInput.slice(0, 2)}-${cleanInput.slice(2)}`;
        }

        if (cleanInput.length > 4 && !(cleanInput.length === 5 && isDeleting)) {
            inputValue = `${cleanInput.slice(0, 2)}-${cleanInput.slice(2, 5)}-${cleanInput.slice(5, 7)}`;
        }

        setValue(inputValue);

        // On teste la plaque une fois la saisie terminée
        if (inputValue.length === 9 && regexPlate.test(inputValue)) {
            onValidPlateProxy(inputValue);
        } else {
            setFoundVehicle('');
        }

        setLastLength(inputValue.length); // Mettre à jour la longueur précédente
    };
    const addVehicleGarage = (plate: string) => {
        createVehicle({ garageId: garage.id, req: { plate: plate } }).then((response) => {
            if (response.success) {
                dispatch(setSnackbar({ open: true, message: 'Véhicule de prêt enregistré', severity: 'success' }));
                setValue('');
                setFoundVehicle('');
            } else {
                dispatch(
                    setSnackbar({
                        open: true,
                        message: (response as { success: boolean; error: string }).error ?? 'Ajout du véhicule de prêt impossible',
                        severity: 'error',
                    }),
                );
            }
        });
    };
    const deleteVehicleGarage = (id: number) => {
        deleteVehicle({ garageId: garage.id, vehicleId: id }).then((response) => {
            if (response.success) {
                dispatch(setSnackbar({ open: true, message: 'Véhicule de prêt supprimé', severity: 'success' }));
            } else {
                dispatch(setSnackbar({ open: true, message: 'Suppression du véhicule de prêt impossible', severity: 'error' }));
            }
        });
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            justifyContent: 'start',
                            //width: '300px',
                            pl: 1,
                        }}
                    >
                        <Typography
                            variant='h6'
                            color={isActive ? theme.palette.primary.dark : theme.palette.text.secondary}
                            sx={{ mb: 1 }}
                        >
                            <Switch checked={isActive} onChange={(e) => handleChangeIsActive(e.target.checked)} color='primary' />
                            <b>Disponible pour les clients</b>
                        </Typography>
                        <Typography variant='body1' color={theme.palette.text.secondary}>
                            Activez cette option pour offrir à vos clients la possibilité de demander un véhicule de prêt lors de la
                            prise de rendez-vous en ligne.
                        </Typography>
                    </Box>

                    <Grid sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography variant='h6' color='text.secondary' sx={{ alignSelf: 'center' }}>
                                <b>Ajouter un véhicule de prêt</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ mt: 1.5 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        border: `1px solid ${foundVehicle.length !== 0 ? theme.palette.primary.dark : theme.palette.text.secondary}`,
                                        borderRadius: '25px',
                                        width: 'fit-content',
                                        px: 2,
                                        alignSelf: 'center',
                                    }}
                                >
                                    <Box sx={{ ...flexCenterCol, pr: 1 }}>
                                    </Box>
                                    <TextField
                                        id='standard-basic'
                                        value={value}
                                        disabled={isMaxLoanerVehicles}
                                        sx={{
                                            alignSelf: 'start',
                                            '& .MuiInputBase-input': {
                                                fontSize: '1.1rem',
                                                fontWeight: 'bold',
                                                fontFamily: 'Outfit',
                                                color: theme.palette.text.secondary,
                                            },
                                        }}
                                        margin='dense'
                                        variant='standard'
                                        placeholder='Immatriculation'
                                        helperText={
                                            <Box sx={{ minHeight: '24px' }}>
                                                {foundVehicle.length !== 0 && (
                                                    <Typography variant='body1' color={theme.palette.primary.dark}>
                                                        {foundVehicle}
                                                    </Typography>
                                                )}
                                                {error.length !== 0 && (
                                                    <Typography variant='body1' color={theme.palette.warning.dark}>
                                                        {error}
                                                    </Typography>
                                                )}
                                            </Box>
                                        }
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                { displaySearch && <IconButton
                                                    onClick={() => onValidPlateProxy(value)}
                                                    edge="end"
                                                >
                                                    <SearchIcon />
                                                </IconButton>}
                                            </InputAdornment>
                                        }}
                                        onChange={handleChange}
                                    />

                                    <Box sx={{ ...flexCenterCol, pl: 1 }}>
                                        {loadingPlate ? (
                                            <CircularProgress size='24px' />
                                        ) : (
                                            <Button
                                                sx={{ color: theme.palette.primary.dark, fontFamily: 'CarterOne', fontSize: '24px' }}
                                                disabled={foundVehicle.length === 0 || isMaxLoanerVehicles}
                                                onClick={() => addVehicleGarage(value)}
                                            >
                                                {' '}
                                                OK{' '}
                                            </Button>
                                        )}
                                    </Box>
                                </Box>
                                {isMaxLoanerVehicles && (
                                    <Typography variant='body1' color={theme.palette.warning.dark}>
                                        Vous avez atteint la limite de véhicules de prêt
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{ pt: 3 }}>
                            <Typography variant='h6' color='text.secondary'>
                                <b>Liste des véhicules de prêt </b>&nbsp;&nbsp; {garageVehicle?.length} / 10
                            </Typography>
                            <Box
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(2, 1fr)',
                                    mt: 2,
                                    gap: 1.5,
                                    justifyContent: 'space-between',
                                }}
                            >
                                {garageVehicle
                                    ?.sort((a: VehicleGarage, b: VehicleGarage) => a.index - b.index)
                                    .map((vehicle: VehicleGarage, index: number) => {
                                        return (
                                            <Box
                                                key={index}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        ...flexCenterRow,
                                                        border: '2px solid #B1DF6E',
                                                        borderRadius: '25px',
                                                        width: 'fit-content',
                                                        px: 2,
                                                    }}
                                                >
                                                    <Box sx={{ ...flexCenterCol, pr: 1, borderRight: `1px solid ${theme.palette.primary.main}` }}>
                                                        <Typography variant='body2' fontFamily={'Outfit'} color={theme.palette.text.secondary}>
                                                            <b>VP</b>
                                                        </Typography>
                                                        <Typography variant='body2' fontFamily={'Outfit'} color={theme.palette.primary.dark}>
                                                            <b>{vehicle.index}</b>
                                                        </Typography>
                                                    </Box>

                                                    <Box sx={{ ...flexCenterCol, pl: 1, pr: 1 }}>
                                                        <Typography
                                                            variant='body1'
                                                            fontFamily={'CarterOne'}
                                                            sx={{ textAlign: 'center', fontWeight: 'bold' }}
                                                            color={theme.palette.text.secondary}
                                                        >
                                                            {vehicle.vehicle.brand} {vehicle.vehicle.model}
                                                        </Typography>
                                                        <Typography variant='body1' color={theme.palette.primary.dark}>
                                                            {formatFrenchVehiclePlate(vehicle.vehicle.plate)}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <IconButton
                                                    size='large'
                                                    aria-label='delete'
                                                    sx={{ color: theme.palette.warning.dark, ml: 1 }}
                                                    onClick={() => deleteVehicleGarage(vehicle.vehicle.id)}
                                                >
                                                    <CloseIcon sx={{ fontSize: '28px' }} />
                                                </IconButton>
                                            </Box>
                                        );
                                    })}
                                {garage?.vehicles?.length === 0 && (
                                    <Typography variant='body1' color={theme.palette.text.secondary}>
                                        Aucun véhicule de prêt enregistré
                                    </Typography>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
export default LoanerVehicle;
