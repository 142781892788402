import { ConfirmationDialog, GarageService, type Event, type Vehicle, DocumentType, type Document } from '@movalib/movalib-commons';
import { Grid, Box, Chip, Button } from '@mui/material';
import  { useEffect, useRef } from 'react';
import { type FunctionComponent, useState } from 'react';
import { AttachFile, Build, CameraAlt, InsertDriveFile } from '@mui/icons-material';
import theme from '../../../theme';

interface ListChipDocumentEventProps {
    localEvent: Event,
    refreshEvent: () => void;
}

const ListChipDocumentEvent: FunctionComponent<ListChipDocumentEventProps> = ({ localEvent, refreshEvent }) => {
    const [expandedListChipDocument, setExpandedListChipDocument] = useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const currentDocumentToDelete = useRef<Document | null>(null);
    const maxVisibleChips = 3;
    const visibleDocuments = expandedListChipDocument ? localEvent?.documents?.filter((doc) => doc.type !== DocumentType.USER_APPOINTMENT_QUOTE)?.sort((a, b) => new Date(a.creationDate as unknown as string).getTime() - new Date(b.creationDate as unknown as string).getTime()) : 
    localEvent?.documents?.filter((doc) => doc.type !== DocumentType.USER_APPOINTMENT_QUOTE).sort((a, b) => new Date(a.creationDate as unknown as string).getTime() - new Date(b.creationDate as unknown as string).getTime()).slice(0, maxVisibleChips);
    useEffect(() => {
        return () => {
            currentDocumentToDelete.current = null;
        };
    }, []);

    const handleDeleteDocument = (document: Document) => {
        currentDocumentToDelete.current = document;
        setOpenConfirmDelete(true);
    };

    const handleConfirmDelete = () => {
        setOpenConfirmDelete(false);
        if (currentDocumentToDelete.current) {
            GarageService.deleteEventDocument(localEvent.garageId.toString(), localEvent.id, currentDocumentToDelete.current.id).finally(() => {
                refreshEvent();
            });
            currentDocumentToDelete.current = null;
        }
    };

    const getIconFromDocumentType = (documentType: DocumentType) => {
        switch (documentType) {
            case DocumentType.EVENT_VISUAL_PROOFS:
                return <CameraAlt />
            case DocumentType.EVENT_MAINTENANCE:
                return <Build />;
            default:
                return <InsertDriveFile />;
        }
    }

    return (
        <>
            <Grid
                item
                xs={1}
                sx={{
                    mt: 1,
                    display: { xs: 'none', md: 'flex' },
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <AttachFile sx={{ color: theme.palette.grey[500] }} />
            </Grid>
            <Grid item xs={12} md={11} sx={{ mt: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, width: '80%' }}>
                    {visibleDocuments?.map((doc: Document) => (
                        <Chip
                            icon={getIconFromDocumentType(doc.type)}
                            key={doc.id}
                            label={doc.originalFileName || doc.fileName}
                            onClick={() => window.open(doc.fileSignedUrl, '_blank')}
                            onDelete={() => handleDeleteDocument(doc)}
                            sx={{ cursor: 'pointer' }}
                        />
                    ))}

                </Box>
                {localEvent?.documents && localEvent.documents.length > maxVisibleChips && (
                    <Button onClick={() => setExpandedListChipDocument(!expandedListChipDocument)} size="small">
                        {expandedListChipDocument ? 'Réduire' : 'Voir plus'}
                    </Button>
                )}
                {openConfirmDelete && (<ConfirmationDialog open={openConfirmDelete} onClose={() => setOpenConfirmDelete(false)} onConfirm={handleConfirmDelete} message={"Voulez-vous supprimer ce document?"} />)}
            </Grid>
        </>
    );
};

export default ListChipDocumentEvent;