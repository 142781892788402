import {
  type Garage,
  Logger,
  MovaAppType,
  SubscriptionPaymentInterval,
  type User,
  UserService,
} from '@movalib/movalib-commons';
import { readCookie } from '@movalib/movalib-commons/dist/src/helpers/CookieUtils';
import { flexCenter, flexStart } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import {
  Alert,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  type SxProps,
  Tabs,
  TextField,
  type Theme,
  Typography,
  darken,
  useTheme,
} from '@mui/material';
import type React from 'react';
import { type FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import LogoProLarge from '../../assets/images/logo/logo_pro_large_border.png';
import { COOKIE_PRO_TOKEN } from '../../helpers/Constants';
import { StyledTab } from '../../helpers/Styled';
import { flexEnd } from '../../helpers/Tools';
import { setSnackbar } from '../../slices/snackbarSlice';
import { MovaPackageForm } from '../../pages/registration/SignUp';
import InfoIcon from '@mui/icons-material/Info';


interface PricingTableProps {
  formData: MovaPackageForm;
  sx?: SxProps<Theme>;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

enum SubscriptionTabs {
  ALLOWANCE = 0,
  GAIN = 1,
  LAUNCH_OFFER = 2,
}

interface SubscriptionPricing {
  basePrice: number;
  annualPrice: number;
  biennialPrice: number;
}

const PricingTable: FunctionComponent<PricingTableProps> = ({
  formData,
  sx,
}) => {
  const isAuthenticated: boolean = Boolean(readCookie(COOKIE_PRO_TOKEN));
  const [connectedUser, setConnectedUser] = useState<User | undefined>();
  const [registeredGarages, setRegisteredGarages] = useState<Garage[] | []>();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();

  // const [dailyInvoices, setDailyInvoices] = useState<number>(6);
  // const [tabValue, setTabValue] = useState<SubscriptionTabs>(SubscriptionTabs.ALLOWANCE);
  // const [starterPackCost, setStarterPackCost] = useState<number>();
  // const [techniciansCost, setTechniciansCost] = useState<number>();
  // const [subscriptionCost, setSubscriptionCost] = useState<number>();

  // Chargement initial de la page
  useEffect(() => {
    if (isAuthenticated) {
      // On contrôle que l'utilisateur dispose du rôle adéquat
      UserService.getCurrentUser(MovaAppType.GARAGE).then((response) => {
        if (response.success) {
          setConnectedUser(response.data);
          refreshRegisteredGarages();
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? "Chargement de votre compte impossible, contactez l'équipe Movalib",
              severity: 'error',
            }),
          );
        }
      });
    }
  }, [location.search]);

  // Chargement initial de la page
  useEffect(() => {
    refreshRegisteredGarages();
  }, [connectedUser]);


  const refreshRegisteredGarages = () => {
    if (connectedUser) {
      // Chargement des inscriptions de garage rattachées au profil
      UserService.getSalesGarages(connectedUser.id).then((response) => {
        if (response.success) {
          Logger.info(response.data);
          setRegisteredGarages(response.data);
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: response.error ?? "Chargement des inscriptions impossible, contactez l'équipe Movalib",
              severity: 'error',
            }),
          );
        }
      });
    }
  };


  return (
    <Box sx={{ ...sx, height: '100%' }}>
      <Grid container style={flexCenter}>
        <Grid item xs={6} style={flexCenter} sx={{ pt: 2 }}>
          <img src={LogoProLarge} style={{ width: '50%' }} />
        </Grid>
        <Grid item xs={6}>
          <Alert
            severity='success'
            icon={<br />}
            style={flexCenter}
            sx={{ width: '100%', textTransform: 'none', textAlign: 'center', px: 1 }}
          >
            <b>VOTRE CONTRAT</b>
            <br />
            <Typography variant='body2' style={flexCenter} sx={{ mt: 1 }}>
              Étape n°7 / 7
            </Typography>
          </Alert>
        </Grid>
        <Grid item xs={12} sx={{ mt: 3, pr: 3 }}>
          <Grid container>

            <Grid item xs={12}>
              <Typography
                variant='h6'
                component='h3'
                style={{ color: theme.palette.text.secondary, fontSize: '1.2rem' }}
              >
                Niveau d'engagement : {formData.movaPackage.engagement} mois
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{ mb: 3 }}>
              <Typography
                variant='h6'
                component='h3'
                style={{ color: theme.palette.text.secondary, fontSize: '1.2rem' }}
              >
                Date de début d’engagement : le lendemain du jour de mise en service
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{ mb: 3 }}>
              <Typography
                variant='h6'
                component='h3'
                style={{ color: theme.palette.text.secondary, fontSize: '1.1rem' }}
              >
                Veuillez indiquer tout commentaire utile concernant votre souscription, puis cliquez sur "Envoyer le Mandat" pour poursuivre la procédure.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant='h6'
                component='h3'
                style={{ color: theme.palette.text.secondary, fontSize: '1.1rem', verticalAlign: 'center' }}
              >
                ℹ️ Pour rappel, en acceptant le mandat de prélèvement et les Conditions Générales de Ventes et d'Utilisations, vous signez un contrat avec Movalib.
                </Typography>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PricingTable;
