import React, { useEffect, useState, type FunctionComponent } from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import type { Garage } from '@movalib/movalib-commons';
import type { FormField } from '../../../helpers/Constants';

const initialFormState = {
    workforce: { value: 0, isValid: true },
};
 type Form = {
    workforce: FormField;
  };
interface MyTeamProps {
 garage: Garage,
 saveSetting: (r: any) => void;
}

const MyTeam: FunctionComponent<MyTeamProps> = ({ garage, saveSetting }) => {
    const [form, setForm] = useState<Form>(initialFormState);
    useEffect(() => {
        if (garage) {
          setForm((prevForm) => ({
            ...prevForm,
    workforce: { ...prevForm['workforce'], value: garage.workforce },
         }));
        }
      }, [garage]);

      const handleChange = (fieldName: keyof Form, newValue: any, save?: boolean) => {
        setForm((prevForm) => ({ ...prevForm, [fieldName]: { ...prevForm[fieldName], value: newValue } }));
    
        if (save) {
          const request = {
            [fieldName]: newValue,
          };
    
          saveSetting(request);
        }
      };
  return (
    <Grid container sx={{ pb: 2, pr: 4 }}>
      <Grid item xs={12}>
        <Typography variant="h6" color="text.secondary">
          <b>Effectif atelier</b>
        </Typography>
        <TextField
          id="settings-workforce"
          label="NOMBRE DE RÉPARATEURS"
          value={form.workforce.value}
          disabled
          type="number"
          required
          size="small"
          onBlur={(e) => handleChange('workforce', e.target.value, true)}
          onChange={(e) => handleChange('workforce', e.target.value, false)}
          error={Boolean(form.workforce.error)}
          sx={{ width: '50%', mt: 2.5 }}
        />
      </Grid>
    </Grid>
  );
};

export default MyTeam;
