import { type Garage, GarageService } from "@movalib/movalib-commons";
import { Button } from "@mui/material";
import { useEffect, useState, type FunctionComponent } from "react";
import { setGarageData } from "../slices/garageSlice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const CsmList: FunctionComponent = () => {
    const dispatch = useDispatch();
    const [garages, setGarages] = useState<Garage[] | null>(null);
    const [searchQuery, setSearchQuery] = useState<string>(''); // État pour la recherche
    const history = useHistory();

    useEffect(() => {
        if(!garages){
            GarageService.getGaragesCsm().then(response => {
                if (response.success) {
                    setGarages(response.data as Garage[]);
                }
            });
        }
    }, []);
    function onGarageClick(id: string): void {
        if(id){
            GarageService.getOneGarageCsm(id).then(response => {
                if (response.success) {
                    dispatch(setGarageData(response.data as unknown as Garage));
                    history.push('/agenda');

                }
            });
        }
    }

    const filteredGarages = garages?.filter(garage =>
        garage.name.toLowerCase().includes(searchQuery.toLowerCase())
    );


    return (<>
                <input
                type="text"
                placeholder="Rechercher un garage..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{
                    margin: '21px',
                    maxWidth: '400px',
                    marginBottom: '10px',
                    padding: '8px',
                    width: '100%',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                }}
            />
            <ul>
                {(filteredGarages || []).sort((a, b) =>
                    (a.id as unknown as number) - (b.id as unknown as number)
                ).map((garage) => (
                    <li key={garage.id}>
                        <Button onClick={() => onGarageClick(garage.id)} sx={{color: 'darkgreen'}}>
                            #{garage.id} {garage.name} {garage.address.cityName} {garage.address.streetName}
                        </Button>
                    </li>
                ))}
            </ul>
    </>);
};
export default CsmList;