import moment from 'moment';
import {
  type CSSProperties,
  Dispatch,
  type FunctionComponent,
  type ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Calendar,
  type EventPropGetter,
  type NavigateAction,
  type SlotInfo,
  type View,
  Views,
  momentLocalizer,
} from 'react-big-calendar';
import 'moment/locale/fr';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './MyCalendar.css';
import {
  CustomerType,
  DateFormatTypes,
  Event,
  EventState,
  EventType,
  VehicleGarage,
  type Garage,
  Logger,
  SlotAlgorithm,
  formatDateByCountryCode,
  GarageService,
  StyledToggleButton
} from '@movalib/movalib-commons';
import InfoIcon from '@mui/icons-material/Info';
import MuiDrawer from '@mui/material/Drawer';
import {
  Alert,
  Box,
  Button,
  type CSSObject,
  FormHelperText,
  Grid,
  IconButton,
  styled,
  TextField,
  type Theme,
  ToggleButtonGroup,
  Tooltip,
  tooltipClasses,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import invariant from 'invariant';
import { debounce, isEqual, set } from 'lodash';
import withDragAndDrop, {
  type EventInteractionArgs,
  type withDragAndDropProps,
} from 'react-big-calendar/lib/addons/dragAndDrop';

import {
  flexCenter,
  formatFrenchVehiclePlate,
  formatPhoneNumber,
} from '@movalib/movalib-commons/dist/src/helpers/Tools';
import EventScheduleIcon from '@mui/icons-material/AccessTimeFilledRounded';
import { isBefore } from 'date-fns';
import { useDispatch } from 'react-redux';
import ConfirmationDialog from '../../dialogs/ConfirmationDialog';
import { EventNoteDialog } from '../../dialogs/calendar/EventNoteDialog';
import { EventUnavailabilityDialog } from '../../dialogs/calendar/EventUnavailabilityDialog';
import {
  CALENDAR_MAX_TIME_HOUR,
  CALENDAR_MAX_TIME_MIN,
  CALENDAR_MIN_TIME_HOUR,
  CALENDAR_MIN_TIME_MIN,
  CULTURE,
  DEFAULT_CALENDAR_VIEW,
  DEFAULT_EVENT_STATE,
  DEFAULT_EVENT_TIMEPICKER_STEP,
  DEFAULT_EVENT_TYPE,
  FIRE_EVENT_DEFAULT_HOURS,
} from '../../helpers/Constants';
import { getCalendarPeriod } from '../../helpers/DateUtils';
import { CalendarView } from '../../helpers/Enums';
import {
  flexCenterCol,
  flexStart,
  formatLocalDateToISOString,
  getEventStateBorder,
  getEventStateColor,
  getEventVehicleDepositDate,
  isInvalidEmail,
  isInvalidMobileNumber,
  isWithinLastIndicatedHours,
} from '../../helpers/Tools';
import { useBoolState } from '../../helpers/hooks/useBoolState';
import { useEditGarageEvent, useFetchEventsList } from '../../query/event/EventQuery';
import { useFetchAdministratedGarages } from '../../query/garage/GarageQuery';
import { setSnackbar } from '../../slices/snackbarSlice';
import theme from '../../theme'; // Import du thème personnalisé
import DateTimePickerGroup from '../DateTimePickerGroup';
import UserAvatar from '../UserAvatar';
import MyCalendarDayView from './MyCalendarDayView';
import MyCalendarFourDaysView from './MyCalendarFourDaysView';
import MyCalendarEmployees from './MyCalendarEmployeeView';
import MyCalendarEvent from './MyCalendarEvent';
import MyCalendarEventDialog, { isLoanVehicleAvailable } from './MyCalendarEventDialog';
import { MyCalendarEventMemoList } from './MyCalendarEventMemoList';
import MyCalendarToolBar from './MyCalendarToolBar';
import { MyEventDialog, MyEventState } from './MyCalendarTypes';
import MyCalendarWeek from './MyCalendarWeek';
import MuiAppBar, { type AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { ModifyDropOffVehicle } from './dialog/ModifyDropOffVehicle';
import { ShowMoreNote } from './dialog/ShowMoreNote';
import LoanVehicleDialog from './dialog/LoanVehicleDialog';
import ConflictLoanVehicleDialog from '../../dialogs/calendar/ConflictLoanVehicleDialog';
import { gu, is } from 'date-fns/locale';

type MyCalendarProps = {
  garageId: string;
};

const messages = {
  allDay: 'Tous les jours',
  previous: 'Précédent',
  next: 'Suivant',
  yesterday: 'Hier',
  tomorrow: 'Demain',
  today: "Aujourd'hui",
  month: 'Mois',
  week: 'Semaine',
  // Petit hack de la vue work week en vue Employé (gain de temps)
  work_week: 'Réparateurs',
  day: 'Jour',
  resource: 'Resource',
  agenda: '4 jours',
  date: 'Date',
  time: 'Heure',
  event: 'Evénement',
  noEventsInRange: 'Aucun événements sur cette période.',
  showMore: (total: number) => `+ ${total} ...`,
};

const localizer = momentLocalizer(moment);

const DndCalendar = withDragAndDrop<Event>(Calendar);

const getDefaultUpdateSchedulingCustomerMessage = (event: Event, garage: Garage, startDate: Date) => {
  return `Bonjour, votre rendez-vous chez ${garage.name} a été décalé au ${formatDateByCountryCode(startDate, 'FR', DateFormatTypes.LONG_FORMAT_DATETIME)}. Bonne journée. Suivi : app.movalib.com`;
};

export const eventStyleGetter: EventPropGetter<Event> = (event: Event) => {
  const style: CSSProperties = {
    backgroundColor: getEventStateColor(event, theme),
    borderRadius: '6px',
    color: '#464646',
    borderColor: 'white',
    border: getEventStateBorder(event),
    animation: event.state === EventState.NEW ? 'blink 1.5s infinite' : 'none',
  };

  let className = '';

  // Condition pour ajouter une classe spécifique aux événements UNAVAILABILITY
  if (event.type === EventType.UNAVAILABILITY) {
    className = 'no-hover-effect'; // Nom de la classe CSS à appliquer
  } else if (event.type === EventType.NOTE) {
    className = 'my-note-event';
  } else {
    switch (event.state) {
      case EventState.NEW:
        if (isWithinLastIndicatedHours(event.start, FIRE_EVENT_DEFAULT_HOURS)) {
          className = 'my-new-event-fire';
        } else {
          className = 'my-new-event';
        }
        break;

      case EventState.ACCEPTED:
        className = 'my-accepted-event';
        break;
      case EventState.SCHEDULED:
        className = 'my-scheduled-event';
        break;
      case EventState.COMPLETED:
        className = 'my-completed-event';
        break;
      case EventState.REJECTED:
        className = 'my-rejected-event';
        break;
      case EventState.DONE:
        className = 'my-done-event';
        break;
      case EventState.CANCELLED:
        className = 'my-cancelled-event';
        break;
    }
  }

  return {
    style,
    className, // Retourne la classe conditionnelle avec l'objet de style
  };
};

const MyCalendar: FunctionComponent<MyCalendarProps> = ({ garageId }) => {
  const dispatch = useDispatch();
  const calendarRef = useRef(null);

  // Fetch les infos du garage dynamiquement selon la demande
  const { data: garage } = useFetchAdministratedGarages(garageId);

  const { mutateAsync: updateGarageEvent } = useEditGarageEvent();
  const [openConflictLoanVehicleDialog, setOpenConflictLoanVehicleDialog] = useState(false);
  const oncloseConflictLoanVehicleDialog = () => {
    setOpenConflictLoanVehicleDialog(false);
  };

  const getDefaultView = (): View => {
    if (garage?.teamManagementActive && garage?.defaultView && garage.employees && garage.employees?.length > 0) {
      switch (garage.defaultView) {
        case CalendarView.DAY:
          return Views.DAY;
        case CalendarView.MONTH:
          return Views.MONTH;
        case CalendarView.WEEK:
          return Views.WEEK;
        case CalendarView.EMPLOYEES:
          return Views.WORK_WEEK;
        case CalendarView.FOUR_DAYS:
          return Views.AGENDA;
        default:
          return DEFAULT_CALENDAR_VIEW;
      }
    }
    return DEFAULT_CALENDAR_VIEW;
  };

  const [view, setView] = useState<View>(getDefaultView() ?? DEFAULT_CALENDAR_VIEW);
  const [openEvent, setOpenEvent] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<Event>();

  const [movedEvent, setMovedEvent] = useState<EventInteractionArgs<Event>>();
  const [typeMove, setTypeMove] = useState<'move' | 'resize'>();
  //const [movedDepositDate, setMovedDepositDate] = useState<Date>();
  const movedDepositDate = useRef<Date>();

  const [updateSchedulingAlert, setUpdateSchedulingAlert] = useState<boolean>(false);
  const [updateSchedulingCustomerMessage, setUpdateSchedulingCustomerMessage] = useState<string>('');

  const [periodDate, setPeriodDate] = useState<Date>(new Date());
  const eventDialogType = useRef<MyEventDialog>();
  const { startDate, endDate } = getCalendarPeriod(periodDate.getTime(), view, garage?.schedules);

  const { data: events, refetch: refetchEvents } = useFetchEventsList({
    view,
    dispatch,
    garageId,
    schedules: garage?.schedules || [],
    startPeriod: startDate,
    endPeriod: endDate,
  });

  const { isEditVehiculeDepositDialogOpened, toggleEditVehiculeDepositDialogOpened } = useBoolState(
    false,
    'editVehiculeDepositDialogOpened',
  );
  const { isAlertCustomerDialogOpened, toggleAlertCustomerDialogOpened } = useBoolState(
    false,
    'alertCustomerDialogOpened',
  );
  const [isNoteDialogOpened, setIsNoteDialogOpened] = useState<boolean>(false);
  const [noteEvent, setNoteEvent] = useState<Event[]>([]);

  useEffect(() => {
    if (movedEvent?.event && movedEvent?.start && garage) {
      setUpdateSchedulingCustomerMessage(
        getDefaultUpdateSchedulingCustomerMessage(
          movedEvent?.event,
          garage,
          garage.slotAlgorithm === SlotAlgorithm.DAY_PERIOD
            ? new Date(movedDepositDate.current || movedEvent.event.vehicleDepositDate!)
            : new Date(movedEvent.start!),
        ),
      );
    }
  }, [movedEvent?.event, garage, movedEvent?.start, movedDepositDate.current]);

  const handleCloseDialogNote = () => {
    setIsNoteDialogOpened(false);
    setNoteEvent([]);
  };
  const diplayShowMoreDialog = (events: Event[]) => {
    setNoteEvent(events);
    setIsNoteDialogOpened(true);
  };
  /**
   * Si un événement est sélectionné, on regarde si on a la dernière version de celui-ci
   */
  useEffect(() => {
    if (selectedEvent && events?.length && eventDialogType.current === MyEventDialog.DETAILS) {
      const event = events.find((e) => e.id === selectedEvent.id);
      if (event && !isEqual(event, selectedEvent)) {
        setSelectedEvent(event);
      }
    }
  }, [selectedEvent, events]);

  const [loanVehicleByDate, setLoanVehicleByDate] = useState<{ [date: string]: VehicleGarage[] }>({});
  const [isOpenLoanVehicleDialog, setOpenLoanVehicleDialog] = useState<boolean>(false);
  const loanVehicleDialogRef = useRef<VehicleGarage | null>();
  const displayDialogVehicleGarage = (loanVehicle: VehicleGarage) => {
    loanVehicleDialogRef.current = loanVehicle;
    setOpenLoanVehicleDialog(true);
  };
  const handleCloseLoanVehicleDialog = () => {
    loanVehicleDialogRef.current = null;
    setOpenLoanVehicleDialog(false);
  };
  useEffect(() => {
    const getGarageVehicleById = (id: number): VehicleGarage => {
      return garage?.vehicles?.find((vehicle) => vehicle.id === id) as VehicleGarage;
    };
    const tmpLoanVehicleByDate = {} as { [date: string]: VehicleGarage[] };
    events?.forEach((event) => {
      if (event.garageVehicleId) {
        const startDate = new Date(event.start as Date);
        const endDate = new Date(event.end as Date);

        // Boucle pour parcourir toutes les dates entre start et end
        for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
          const formattedDate = date.toISOString().split('T')[0]; // Format YYYY-MM-DD
          if (tmpLoanVehicleByDate[formattedDate] !== undefined) {
            tmpLoanVehicleByDate[formattedDate].push(getGarageVehicleById(event.garageVehicleId!));
          } else {
            tmpLoanVehicleByDate[formattedDate] = [getGarageVehicleById(event.garageVehicleId!)];
          }
        }
      }
      setLoanVehicleByDate(tmpLoanVehicleByDate);
    });
  }, [events]);

  const eventStyleGetter: EventPropGetter<Event> = (event: Event) => {
    const style: CSSProperties = {
      backgroundColor: getEventStateColor(event, theme),
      borderRadius: '6px',
      color: '#464646',
      borderColor: 'white',
      border: getEventStateBorder(event),
      animation: event.state === EventState.NEW ? 'blink 1.5s infinite' : 'none',
    };

    let className = '';
    if (event.type === EventType.UNAVAILABILITY && event.id !== '') {
      className = 'my-cancelled-event';
    } else if (event.type === EventType.UNAVAILABILITY && event.id === '') {
      className = 'no-hover-effect'; // Nom de la classe CSS à appliquer
    } else if (event.type === EventType.NOTE) {
      className = 'my-note-event';
    } else {
      switch (event.state) {
        case EventState.NEW:
          if (isWithinLastIndicatedHours(event.start, FIRE_EVENT_DEFAULT_HOURS)) {
            className = 'my-new-event-fire';
          } else {
            className = 'my-new-event';
          }
          break;

        case EventState.ACCEPTED:
          className = 'my-accepted-event';
          break;
        case EventState.SCHEDULED:
          className = 'my-scheduled-event';
          break;
        case EventState.COMPLETED:
          className = 'my-completed-event';
          break;
        case EventState.REJECTED:
          className = 'my-rejected-event';
          break;
        case EventState.DONE:
          className = 'my-done-event';
          break;
        case EventState.CANCELLED:
          className = 'my-cancelled-event';
          break;
      }
    }

    return {
      style,
      className, // Retourne la classe conditionnelle avec l'objet de style
    };
  };

  const diplayCTALoanVehicleHeaderOrGutter = (
    loanVehicleDataLight: VehicleGarage[],
    loanVehicleDataFull: VehicleGarage[],
    withCollaps = false,
    props?: any,
    isExpanded?: boolean,
    setIsExpanded?: (b: boolean) => void,
  ) => {
    const chip = (LoanVehicle: VehicleGarage) => {
      return (
        <CustomTooltipLoanVehicle
          placement='top'
          title={
            <>
              <div style={{ fontWeight: 'bold' }}>Véhicule de prêt n° {LoanVehicle?.index} </div>
              <div style={{ fontWeight: 'bold', fontSize: '16px', color: theme.palette.primary.dark }}>
                {LoanVehicle?.vehicle?.model}
              </div>
              <div style={{ fontSize: '12px' }}>{formatFrenchVehiclePlate(LoanVehicle?.vehicle?.plate)}</div>
            </>
          }
          arrow
          key={`loan-vehicle-${LoanVehicle?.vehicle?.id}-${LoanVehicle?.index}-tooltip`}
        >
          <Box
            key={`loan-vehicle-${LoanVehicle?.vehicle?.id}-${LoanVehicle?.index}-content`}
            sx={{
              ...flexCenterCol,
              borderRadius: '25px',
              height: '34px',
              width: '34px',
              marginRight: '3px',
              border: `2px solid ${theme.palette.primary.dark}`,
              marginBottom: '4px',
              cursor: 'pointer',
            }}
            onClick={(e) => {
              e.stopPropagation();
              displayDialogVehicleGarage(LoanVehicle);
            }}
          >
            <Typography
              variant='body1'
              sx={{
                color: theme.palette.text.secondary,
                fontWeight: 'bold',
                fontSize: '12px',
                lineHeight: '12px',
              }}
            >
              VP
            </Typography>
            <Typography
              variant='body1'
              sx={{
                color: theme.palette.primary.dark,
                fontWeight: 'bold',
                fontSize: '12px',
                lineHeight: '12px',
              }}
            >
              {LoanVehicle?.index}
            </Typography>
          </Box>
        </CustomTooltipLoanVehicle>
      );
    };
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {withCollaps
            ? loanVehicleDataLight
              .sort((a: VehicleGarage, b: VehicleGarage) => a.index - b.index)
              .map((LoanVehicle: VehicleGarage) => chip(LoanVehicle))
            : loanVehicleDataFull
              .sort((a: VehicleGarage, b: VehicleGarage) => a.index - b.index)
              .map((LoanVehicle: VehicleGarage) => chip(LoanVehicle))}
          {loanVehicleDataFull.length > 1 && withCollaps && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setIsExpanded?.(!isExpanded);
              }}
            >
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </Box>
        {props?.label}
      </Box>
    );
  };

  // Définir l'heure de début du calendrier
  const minTime = new Date();
  minTime.setHours(CALENDAR_MIN_TIME_HOUR, CALENDAR_MIN_TIME_MIN, 0);

  // Définir l'heure de fin du calendrier
  const maxTime = new Date();
  maxTime.setHours(CALENDAR_MAX_TIME_HOUR, CALENDAR_MAX_TIME_MIN, 0);

  const onNavigate = (date: Date, _view: View, _action: NavigateAction) => {
    setPeriodDate(date);
  };

  const onSelectEvent = (event: Event, dialogType = MyEventDialog.CREATE) => {
    try {
      setSelectedEvent(event);
      setOpenEvent(true);
      eventDialogType.current = dialogType;
    } catch (e) {
      console.log(e);
    }
  };

  const handleSelectSlot = useCallback(
    ({ start, end, resourceId, action }: SlotInfo) => {
      if (action === 'click' && start.getDay() === end.getDay()) {
        end.setHours(start.getHours() + 1, start.getMinutes(), 0, 0);
      }
      // On crée "une coquille vide" avec uniquement le créneau sélectionné et l'éventuelle ressource
      const newEvent = new Event(
        '',
        1,
        DEFAULT_EVENT_TYPE,
        '',
        '',
        Number(garageId),
        '',
        DEFAULT_EVENT_STATE,
        undefined,
        start,
        end,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        resourceId ? Number(resourceId) : undefined,
      );

      // Ajout date de dépot véhicule par défaut
      if (garage && garage.slotAlgorithm === SlotAlgorithm.DAY_PERIOD) {
        newEvent.vehicleDepositDate = getEventVehicleDepositDate(start, garage);
        Logger.info('hello : ', newEvent.vehicleDepositDate);
      }

      eventDialogType.current = MyEventDialog.CREATE;
      setSelectedEvent(newEvent);
      setOpenEvent(true);
    },
    [garageId],
  );

  const handleSelectEvent = useCallback((event: Event) => {
    if (event.editable) {
      setSelectedEvent(event);
      /**
       * On ouvre la popup de l'event en mode Détails (consultation)
       */
      eventDialogType.current = MyEventDialog.DETAILS;
      setOpenEvent(true);
    }
  }, []);

  const handleOnView = useCallback((view: View) => {
    setView(view);
  }, []);

  // Callback appelé à la fermeture de la boite de dialogue de gestion d'un événement
  const handleCloseEventDialog = () => {
    setOpenEvent(false);
    refetchEvents();
  };
  const checkConflictLoanVehicle = (event: Event, start: Date, end: Date) => {
    if (
      garage?.loanerVehicleActive &&
      typeof event.garageVehicleId === typeof 666 &&
      (start.getUTCDate() !== event?.start?.getUTCDate() || end.getUTCDate() !== event?.end?.getUTCDate())
    ) {
      if (!isLoanVehicleAvailable(start, end, loanVehicleByDate, event.garageVehicleId!)) {
        // indispo
        GarageService.deassignGarageVehicleEvent(garageId, event.id);
        setOpenConflictLoanVehicleDialog(true);
      }
    }
  };

  const onMoveEvent = useCallback<NonNullable<withDragAndDropProps<Event>['onEventDrop']>>(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false, resourceId }) => {
      const newStartDate = new Date(start);
      const startDateHours = newStartDate.getHours();

      const newEndDate = new Date(end);
      const endDateHours = newEndDate.getHours();
      checkConflictLoanVehicle(event, newStartDate, newEndDate);

      // Si l'évènement est place dans le header, alors il prends la journée entière
      // alors l'évènement se termine donc le lendemain de la date de fin initiale
      if (droppedOnAllDaySlot && startDateHours !== 0 && endDateHours !== 0) {
        newStartDate.setHours(0, 0, 0, 0);

        newEndDate.setDate(newEndDate.getDate() + 1);
        newEndDate.setHours(0, 0, 0, 0);
      }

      const req = {
        title: event.title,
        startDate: formatLocalDateToISOString(newStartDate),
        endDate: newEndDate,
        employeeId: resourceId,
        notes: event.notes,
        vehicleDepositDate: movedDepositDate.current ? formatLocalDateToISOString(movedDepositDate.current) : undefined,
        schedulingUpdateMessage: updateSchedulingAlert ? updateSchedulingCustomerMessage : undefined,
      };

      updateGarageEvent({ garageId, eventId: event.id, req }).then((request) => {
        if (request.success || request.data) {
          refetchEvents();
          onResetMovedEvent();
        }
      });
    },
    [
      garageId,
      refetchEvents,
      updateGarageEvent,
      movedDepositDate.current,
      updateSchedulingAlert,
      updateSchedulingCustomerMessage,
    ],
  );

  const onMoveEventProxy = useCallback<NonNullable<withDragAndDropProps<Event>['onEventDrop']>>(
    ({ event, start, end, resourceId, ...rest }) => {
      const newStartDate = new Date(start);
      const newEndDate = new Date(end);

      //Si l'évènement n'a pas bougé, alors ne rien faire
      if (event?.start?.getTime() === newStartDate.getTime() && event?.end?.getTime() === newEndDate.getTime()) {
        // On contrôle si un changement d'opérateur a été effectué via le déplacement de l'event, auquel cas on déclenche l'update
        if (event?.resourceId !== resourceId) {
          onMoveEvent({ event, start, end, resourceId, ...rest });
        }
        return;
      }

      if (event.type === EventType.NOTE || event.type === EventType.UNAVAILABILITY) {
        onMoveEvent({ event, start, end, ...rest });
        return;
      }

      try {
        invariant(garage, 'Impossible de récupérer le garage concerné');
      } catch (e) {
        console.error(e);
        return;
      }

      setMovedEvent({ event, start, end, resourceId, ...rest });
      setTypeMove('move');
      if (garage.slotAlgorithm === SlotAlgorithm.DAY_PERIOD) {
        //setMovedDepositDate(new Date(getEventVehicleDepositDate(new Date(start), garage)));
        movedDepositDate.current = new Date(getEventVehicleDepositDate(new Date(start), garage));
        toggleEditVehiculeDepositDialogOpened();
      } else {
        toggleAlertCustomerDialogOpened();
      }
    },
    [onMoveEvent, garage, toggleAlertCustomerDialogOpened, toggleEditVehiculeDepositDialogOpened],
  );

  const onResetMovedEvent = useCallback(() => {
    setMovedEvent(undefined);
    setTypeMove(undefined);
    //setMovedDepositDate(undefined);
    movedDepositDate.current = undefined;
    setUpdateSchedulingAlert(false);
    setUpdateSchedulingCustomerMessage('');
  }, []);

  const onCloseDepositEditDialog = useCallback(() => {
    onResetMovedEvent();
    toggleEditVehiculeDepositDialogOpened();
  }, [toggleEditVehiculeDepositDialogOpened, onResetMovedEvent]);

  const isBeforeOrEqual = (date1: Date, date2: Date) => {
    return isBefore(date1, date2) || date1.getTime() === date2.getTime(); // Vérifie si date1 est antérieure ou égale à date2
  };

  const onValidDepositDate = useCallback(
    (resetValue = false) => {
      const isDepositDateBeforeEvent = resetValue
        ? isBeforeOrEqual(new Date(movedEvent?.event!.vehicleDepositDate!), new Date(movedEvent?.start!))
        : isBeforeOrEqual(new Date(movedDepositDate.current!), new Date(movedEvent?.start!));

      if (!isDepositDateBeforeEvent) {
        dispatch(
          setSnackbar({
            open: true,
            message: "L'heure de dépôt doit être antérieure à l'heure du rendez-vous",
            severity: 'warning',
          }),
        );
        return;
      }

      if (resetValue && movedEvent) {
        //setMovedDepositDate(undefined);
        movedDepositDate.current = undefined;

        if (typeMove === 'move') {
          onMoveEvent(movedEvent);
        } else {
          onResizeEvent(movedEvent);
        }
        return;
      }
      toggleEditVehiculeDepositDialogOpened();
      toggleAlertCustomerDialogOpened();
    },
    [
      toggleEditVehiculeDepositDialogOpened,
      toggleAlertCustomerDialogOpened,
      dispatch,
      movedDepositDate.current,
      movedEvent,
    ],
  );

  const onResizeEvent = useCallback<NonNullable<withDragAndDropProps<Event>['onEventResize']>>(
    ({ event, start, end }) => {
      const newStartDate = new Date(start);
      const newEndDate = new Date(end);
      checkConflictLoanVehicle(event, newStartDate, newEndDate);

      const req = {
        title: event.title,
        startDate: formatLocalDateToISOString(newStartDate),
        endDate: newEndDate,
        notes: event.notes,
        vehicleDepositDate: movedDepositDate.current ? formatLocalDateToISOString(movedDepositDate.current) : undefined,
        schedulingUpdateMessage: updateSchedulingAlert ? updateSchedulingCustomerMessage : undefined,
      };

      updateGarageEvent({ garageId, eventId: event.id, req }).then((request) => {
        if (request.success || request.data) {
          refetchEvents();
          onResetMovedEvent();
        }
      });
    },
    [
      garageId,
      refetchEvents,
      updateGarageEvent,
      movedDepositDate.current,
      updateSchedulingAlert,
      updateSchedulingCustomerMessage,
    ],
  );

  const onResizeEventProxy = useCallback<NonNullable<withDragAndDropProps<Event>['onEventDrop']>>(
    ({ event, start, end, ...rest }) => {
      const newStartDate = new Date(start);
      const newEndDate = new Date(end);

      //Si l'évènement n'a pas bougé, alors ne rien faire
      if (event?.start?.getTime() === newStartDate.getTime() && event?.end?.getTime() === newEndDate.getTime()) {
        return;
      }

      //Si l'évènement n'a pas bougé, alors ne rien faire
      if (
        event.type === EventType.NOTE ||
        event.type === EventType.UNAVAILABILITY ||
        event?.start?.getTime() === newStartDate.getTime()
      ) {
        onResizeEvent({ event, start, end, ...rest });
        return;
      }

      try {
        invariant(garage, 'Impossible de récupérer le garage concerné');
      } catch (e) {
        console.error(e);
        return;
      }

      setMovedEvent({ event, start, end, ...rest });
      setTypeMove('resize');
      if (garage.slotAlgorithm === SlotAlgorithm.DAY_PERIOD) {
        //setMovedDepositDate(new Date(getEventVehicleDepositDate(new Date(start), garage)));
        movedDepositDate.current = new Date(getEventVehicleDepositDate(new Date(start), garage));
        toggleEditVehiculeDepositDialogOpened();
      } else {
        toggleAlertCustomerDialogOpened();
      }
    },
    [onResizeEvent, toggleEditVehiculeDepositDialogOpened, toggleAlertCustomerDialogOpened, garage],
  );

  const onCloseAlertCustomerDialog = useCallback(() => {
    onResetMovedEvent();
    toggleAlertCustomerDialogOpened();
  }, [onResetMovedEvent, toggleAlertCustomerDialogOpened]);

  const onConfirmUserAlertNotification = useCallback(() => {
    if (!movedEvent) {
      return;
    }
    if (typeMove === 'move') {
      onMoveEvent(movedEvent);
    } else {
      onResizeEvent(movedEvent);
    }
    toggleAlertCustomerDialogOpened();
  }, [typeMove, movedEvent, onMoveEvent, onResizeEvent, toggleAlertCustomerDialogOpened]);

  const resourceIdAccessor = (resource: any): string => resource.id;

  const appointmentIsInDay = (event: Event): boolean => {
    const stripTime = (date: Date) => {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    };
    if (event?.start && event?.end) {
      const startDateEvent = stripTime(event?.start);
      const endDateEvent = stripTime(event?.end);
      const currentDisplayStartDateCalendar = stripTime(startDate);
      const endDateCalendarWorkWeek = new Date(startDate.getTime());
      endDateCalendarWorkWeek.setDate(endDateCalendarWorkWeek.getDate() + (garage?.schedules?.length ?? 6));
      const currentDisplayEndDateCalendar =
        view === Views.WORK_WEEK ? stripTime(endDateCalendarWorkWeek) : stripTime(startDate);
      return startDateEvent <= currentDisplayEndDateCalendar && endDateEvent >= currentDisplayStartDateCalendar;
    }
    return false;
  };

  const resourceTitleAccessor = (resource: any): ReactNode => (
    <Grid
      container
      sx={{
        backgroundImage: 'linear-gradient(to right, #ececec, #ffffff)',
        borderRadius: 20,
        width: '100%',
        whiteSpace: 'nowrap',
      }}
    >
      <UserAvatar size='medium' avatar={resource.avatar} alt={resource.firstname} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          flex: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          ml: 1,
        }}
      >
        <Typography sx={{ fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {resource.firstname} {resource.lastname}
        </Typography>

        {/* Nombre de rendez-vous affectés */}
        <Typography
          sx={{
            fontFamily: 'caveat',
            fontSize: '1.2rem',
            lineHeight: 1.2,
            color: theme.palette.primary.dark,
            whiteSpace: 'nowrap',
          }}
        >
          <b>
            {events?.filter(
              (event) =>
                event.type === EventType.APPOINTMENT && event.resourceId === resource.id && appointmentIsInDay(event),
            ).length || 0}
          </b>
          &nbsp;intervention
          {(events?.filter(
            (event) =>
              event.type === EventType.APPOINTMENT && event.resourceId === resource.id && appointmentIsInDay(event),
          ).length ?? 0) > 1
            ? 's'
            : ''}
        </Typography>
      </Box>
    </Grid>
  );

  const displayMessageOnModifyScheduleDialog = () => {
    const mobileInvalid = isInvalidMobileNumber(movedEvent?.event?.customer?.companyPhoneNumber) && isInvalidMobileNumber(movedEvent?.event?.customer?.phoneNumber)
    const emailInvalid = isInvalidEmail(movedEvent?.event?.customer?.email);

    let message: string;

    if (mobileInvalid) {
      message = 'Nous lui envoyons un e-mail,';
    } else if (emailInvalid) {
      message = 'Nous lui envoyons le SMS ci-dessous,';
    } else {
      message = "Nous lui envoyons le SMS ci-dessous ainsi qu'un email,";
    }

    return message;
  };

  const drawerWidth = 320;

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(8)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(9)} + 1px)`,
    },
  });

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<AppBarProps>(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    variants: [
      {
        props: ({ open }) => open,
        style: {
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      },
    ],
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }));

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(true);

  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  function handleEvents(events: Event[]): Event[] {
    return events.filter((event) => event.id !== '');
  }
  function handleBackGroundEvent(events: Event[]): Event[] {
    return events.filter((event) => event.id === '');
  }

  const CustomTooltipLoanVehicle = styled(({ className, ...props }: any) => (
    <Tooltip {...props} disableInteractive classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#fff',
      color: theme.palette.grey[700],
      fontSize: '14px',
      border: `2px solid ${theme.palette.primary.dark}`,
      borderRadius: '8px',
      padding: '10px',
      textAlign: 'center',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.primary.dark,
    },
  }));

  return (
    <>
      <Box sx={{ display: 'flex', height: '100%' }}>
        <Drawer
          variant='permanent'
          open={open}
          sx={{ display: { xs: 'none', md: 'block' } }}
          PaperProps={{ sx: { position: 'relative', backgroundColor: '#f0f0f000' } }}
        >
          <DrawerHeader sx={{ top: 'auto', minHeight: '48px !important', pt: 1 }}>
            <Typography
              color={'rgb(60, 64, 67)'}
              sx={{
                fontFamily: 'Caveat',
                fontSize: '2rem',
                display: open ? 'block' : 'none',
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              <b>Mémos</b>
            </Typography>

            <Button
              variant='contained'
              color='inherit'
              sx={{ minWidth: '52px', backgroundColor: 'transparent' }}
              onClick={handleToggleDrawer}
            >
              {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </Button>
          </DrawerHeader>
          <MyCalendarEventMemoList
            handleToggleDrawer={handleToggleDrawer}
            events={events}
            view={view}
            garage={garage!}
            handleSelectEvent={handleSelectEvent}
            openDrawer={open}
          />
        </Drawer>

        <Box
          component='main'
          ref={calendarRef}
          sx={{
            flexGrow: 1,
            maxWidth: {
              xs: '100vw', // Pour les écrans plus petits que 900px (xs et sm)
              md: `calc(100vw - ${open ? '320px' : '73px'})`, // Pour les écrans à partir de 900px (md et plus)
            },
          }}
          className='scrollable-container'
        >
          <DndCalendar
            enableAutoScroll
            allDayMaxRows={isMobile ? 2 : 100}
            onShowMore={(eventsNote, date) => {
              diplayShowMoreDialog(eventsNote);
            }}
            doShowMoreDrillDown={false}
            resizableAccessor={(event) => !(event.type === EventType.UNAVAILABILITY && !event.editable)}
            draggableAccessor={(event) =>
              !(
                (event.type === EventType.UNAVAILABILITY && !event.editable) ||
                event.state === EventState.NEW ||
                event.state === EventState.DONE ||
                event.state === EventState.CANCELLED
              )
            }
            resizable
            showMultiDayTimes
            onEventDrop={onMoveEventProxy}
            onEventResize={onResizeEventProxy}
            selectable={true}
            step={DEFAULT_EVENT_TIMEPICKER_STEP}
            timeslots={4}
            className='my-calendar'
            culture={CULTURE}
            components={{
              toolbar: (props) => (
                <MyCalendarToolBar
                  {...props}
                  garage={garage}
                  garageId={garageId}
                  selectedEvent={selectedEvent}
                  onSelectEvent={onSelectEvent}
                  onRefresh={refetchEvents}
                  calendarRef={calendarRef}
                />
              ),
              event: (props) => (
                <MyCalendarEvent
                  {...props}
                  view={view}
                  teamManagement={garage?.teamManagementActive}
                  garage={garage!}
                />
              ),
              header: (props) => {
                const [isExpanded, setIsExpanded] = useState(false);

                const currentLoanVehicles = loanVehicleByDate[moment(props.date).format('YYYY-MM-DD')];
                const visibleItems = isExpanded ? currentLoanVehicles : currentLoanVehicles?.slice(0, 1);

                const isTodayOrLater = props.date.getTime() >= new Date().setHours(0, 0, 0, 0);

                if (
                  garage?.loanerVehicleActive &&
                  (view === Views.WEEK || view === Views.AGENDA || view === Views.WORK_WEEK) &&
                  currentLoanVehicles?.length > 0 &&
                  isTodayOrLater
                ) {
                  return diplayCTALoanVehicleHeaderOrGutter(
                    visibleItems,
                    currentLoanVehicles,
                    isMobile,
                    props,
                    isExpanded,
                    setIsExpanded,
                  );
                }
                return <>{props.label}</>;
              },
              timeGutterHeader: () => {
                const [isExpanded, setIsExpanded] = useState(false);
                const currentDateDisplayed = moment(periodDate).format('YYYY-MM-DD');
                const currentLoanVehicles = loanVehicleByDate[currentDateDisplayed];
                const visibleItems = isExpanded ? currentLoanVehicles : currentLoanVehicles?.slice(0, 1);

                const isTodayOrLater = periodDate.getTime() >= new Date().setHours(0, 0, 0, 0);

                if (garage?.loanerVehicleActive && (view === Views.DAY || view === Views.WORK_WEEK) && currentLoanVehicles?.length > 0 && isTodayOrLater) {
                  return diplayCTALoanVehicleHeaderOrGutter(visibleItems, currentLoanVehicles, true, null, isExpanded, setIsExpanded);
                }
                return <></>
              },
            }}
            views={{
              month: true,
              week: MyCalendarWeek,
              day: MyCalendarDayView,
              agenda: MyCalendarFourDaysView,
              work_week:
                garage?.teamManagementActive && garage?.employees && garage?.employees.length > 0
                  ? MyCalendarEmployees
                  : false,
            }}
            localizer={localizer}
            events={handleEvents(events)}
            backgroundEvents={handleBackGroundEvent(events)}
            eventPropGetter={eventStyleGetter}
            messages={messages}
            defaultView={getDefaultView()}
            min={minTime}
            max={maxTime}
            onView={handleOnView}
            onNavigate={onNavigate}
            onSelectEvent={handleSelectEvent}
            onSelectSlot={(slotInfo) => {
              handleSelectSlot(slotInfo);
            }}
            //formats={formats}
            tooltipAccessor={() => ''}
            startAccessor='start'
            endAccessor='end'
            // Les ressources sont activées selon la vue et le paramétrage garage (triées par ordre alphabétique)
            resources={
              garage?.teamManagementActive &&
                garage?.employees?.length &&
                (view === Views.WORK_WEEK || view === Views.DAY)
                ? garage?.employees?.sort((a, b) => a.firstname.localeCompare(b.firstname))
                : undefined
            }
            resourceIdAccessor={resourceIdAccessor}
            resourceTitleAccessor={resourceTitleAccessor}
          />
        </Box>
      </Box>
      {selectedEvent && garage && selectedEvent.type === EventType.UNAVAILABILITY && (
        <EventUnavailabilityDialog
          refetchEvents={refetchEvents}
          garageId={garageId}
          open={openEvent}
          onClose={handleCloseEventDialog}
          event={selectedEvent}
        />
      )}
      {selectedEvent && garage && selectedEvent.type === EventType.NOTE && (
        <EventNoteDialog
          refetchEvents={refetchEvents}
          garageId={garageId}
          open={openEvent}
          onClose={handleCloseEventDialog}
          event={selectedEvent}
          type={MyEventDialog.DETAILS}
        />
      )}
      {selectedEvent && garage && selectedEvent.type === EventType.APPOINTMENT && (
        <MyCalendarEventDialog
          loanVehicleByDate={loanVehicleByDate}
          event={selectedEvent}
          open={openEvent}
          type={eventDialogType.current}
          onClose={handleCloseEventDialog}
          onRefresh={refetchEvents}
          garage={garage}
          garageId={garageId}
        />
      )}
      {isEditVehiculeDepositDialogOpened && movedEvent && (
        <ConfirmationDialog
          open
          title='Modification heure de dépôt véhicule'
          onClose={onCloseDepositEditDialog}
          onClickBtnClose={() => onValidDepositDate(true)}
          onConfirm={() => {
            if (isEqual(new Date(movedEvent.event.vehicleDepositDate as Date), movedDepositDate.current)) {
              // Pas de modification sur l'horaire de dépôt, on appelle la conservation de l'horaire initiale
              onValidDepositDate(true);
            } else {
              onValidDepositDate(false);
            }
          }}
          confirmLabel='Modifier'
          closeLabel="Conserver l'heure de dépôt initiale"
          message={<ModifyDropOffVehicle event={movedEvent.event as Event} movedDepositDate={movedDepositDate} />}
        />
      )}{' '}
      {isNoteDialogOpened && (
        <ShowMoreNote
          events={noteEvent}
          teamManagement={garage?.teamManagementActive}
          isNoteDialogOpened={isNoteDialogOpened}
          handleCloseDialogNote={handleCloseDialogNote}
          handleSelectEvent={handleSelectEvent}
        />
      )}
      {isOpenLoanVehicleDialog && (
        <LoanVehicleDialog
          onRefresh={refetchEvents}
          events={events}
          garage={garage!}
          isDialogOpened={isOpenLoanVehicleDialog}
          handleCloseDialog={handleCloseLoanVehicleDialog}
          vehicle={loanVehicleDialogRef.current!}
        />
      )}
      {isAlertCustomerDialogOpened && (
        <ConfirmationDialog
          open
          onClose={onCloseAlertCustomerDialog}
          onConfirm={onConfirmUserAlertNotification}
          closeLabel='Annuler'
          title='Prévenir le client ?'
          confirmLabel={updateSchedulingAlert ? 'Enregistrer et prévenir le client' : 'Enregistrer'}
          confirmBtnComponent={
            <Button
              onClick={onConfirmUserAlertNotification}
              color='inherit'
              autoFocus
              sx={{ ml: 1 }}
              disabled={
                updateSchedulingAlert &&
                isInvalidEmail(movedEvent?.event?.customer?.email) &&
                isInvalidMobileNumber(movedEvent?.event?.customer?.companyPhoneNumber) && isInvalidMobileNumber(movedEvent?.event?.customer?.phoneNumber)
              }
            >
              <b>{updateSchedulingAlert ? 'Enregistrer et prévenir le client' : 'Enregistrer'}</b>
            </Button>
          }
          message={
            <Grid container>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <Typography variant='subtitle1'>
                  Vous avez modifié{' '}
                  {garage?.slotAlgorithm === SlotAlgorithm.DAY_PERIOD ? (
                    <b>l'heure de dépôt du véhicule</b>
                  ) : (
                    <b>l'heure du rendez-vous</b>
                  )}
                  , deux possibilités :
                </Typography>
              </Grid>
              <Grid xs={12} sx={{ mb: 2 }}>
                <ToggleButtonGroup
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  size='small'
                  value={updateSchedulingAlert}
                  exclusive
                  onChange={(_, value: boolean) => {
                    setUpdateSchedulingAlert(value);
                  }}
                  aria-label='update-scheduling-alert'
                  sx={{ gap: 3 }}
                >
                  <StyledToggleButton
                    size='small'
                    sx={{ width: '230px' }}
                    customSelectedBackgroundColor={theme.palette.warning.main}
                    customHoverColor={theme.palette.warning.light}
                    value={false}
                    selected={!updateSchedulingAlert}
                    aria-label='update scheduling alert'
                  >
                    <b>Vous prévenez le client</b>
                  </StyledToggleButton>
                  <StyledToggleButton
                    size='small'
                    sx={{ width: '230px' }}
                    customSelectedBackgroundColor={theme.palette.primary.main}
                    customHoverColor={theme.palette.primary.light}
                    value={true}
                    selected={updateSchedulingAlert}
                    aria-label='update scheduling no alert'
                  >
                    <b>Nous prévenons le client</b>
                  </StyledToggleButton>
                </ToggleButtonGroup>

                {!updateSchedulingAlert && (
                  <Alert
                    color='warning'
                    variant='outlined'
                    sx={{ textAlign: 'center', p: 0, mt: 1 }}
                    style={flexCenter}
                    className='styled-warning-alert'
                  >
                    {movedEvent?.start && (
                      <Typography variant='subtitle1'>
                        Le client est avec vous ou vous préférez lui passer un coup de téléphone. <br />{' '}
                        <div style={{ marginTop: '8px' }}>
                          {movedEvent.event?.customer?.type === CustomerType.INDIVIDUAL ? (
                            <>
                              {movedEvent.event?.customer?.firstname} {movedEvent.event?.customer?.lastname}
                            </>
                          ) : (
                            <>{movedEvent.event?.customer?.companyName}</>
                          )}
                          <br />
                          <b>
                            {movedEvent.event?.customer?.companyPhoneNumber &&
                              formatPhoneNumber(movedEvent.event?.customer?.companyPhoneNumber)}
                            {movedEvent.event?.customer?.phoneNumber &&
                              movedEvent.event?.customer?.companyPhoneNumber &&
                              ' / '}
                            {movedEvent.event?.customer?.phoneNumber &&
                              formatPhoneNumber(movedEvent.event?.customer?.phoneNumber)}
                          </b>
                        </div>
                      </Typography>
                    )}
                  </Alert>
                )}

                {updateSchedulingAlert && (
                  <Alert color='success' variant='outlined' sx={{ textAlign: 'center', borderRadius: '20px', mt: 1 }}>
                    {!(isInvalidMobileNumber(movedEvent?.event?.customer?.companyPhoneNumber) && isInvalidMobileNumber(movedEvent?.event?.customer?.phoneNumber))|| !isInvalidEmail(movedEvent?.event?.customer?.email) ? (
                      <Typography variant='subtitle1'>
                        {displayMessageOnModifyScheduleDialog()} pour le prévenir du nouvel horaire de rendez-vous :
                        &nbsp;&nbsp;
                        <b>
                          {formatDateByCountryCode(
                            garage?.slotAlgorithm === SlotAlgorithm.DAY_PERIOD
                              ? new Date(movedDepositDate.current || movedEvent?.event.vehicleDepositDate!)
                              : new Date(movedEvent!.start),
                            'FR',
                            DateFormatTypes.LONG_FORMAT_DATETIME,
                          )}
                        </b>
                      </Typography>
                    ) : (
                      <Typography variant='subtitle1'>
                        Le client n'a pas de numéro de téléphone ou d'email valide pour être prévenu.
                      </Typography>
                    )}
                    {!(isInvalidMobileNumber(movedEvent?.event?.customer?.companyPhoneNumber) && isInvalidMobileNumber(movedEvent?.event?.customer?.phoneNumber)) && (
                        <>
                          <TextField
                            id='update-scheduling-customer-message'
                            label='Contenu du message ...'
                            value={updateSchedulingCustomerMessage}
                            onChange={(e) => {
                              const newValue = e.target.value;
                                if (newValue.length <= 160) {
                              setUpdateSchedulingCustomerMessage(e.target.value)}
                            } }
                            multiline
                            rows={3}
                            autoFocus
                            variant='outlined'
                            error={!updateSchedulingCustomerMessage}
                            helperText={
                              !updateSchedulingCustomerMessage &&
                              "Si vous ne souhaitez pas envoyer de SMS, veuillez choisir l'autre option."
                            }
                            fullWidth
                            sx={{ mt: 2 }}
                            inputProps={{ maxLength: 160 }}
                          />
                          <FormHelperText>
                            Votre client ne peux pas répondre à ce SMS (sens unique).
                            <br />
                            <b>{160 - updateSchedulingCustomerMessage?.length}</b> caractères restants
                          </FormHelperText>
                        </>
                      )}
                  </Alert>
                )}
              </Grid>
            </Grid>
          }
        />
      )}
      {openConflictLoanVehicleDialog && (
        <ConflictLoanVehicleDialog open={openConflictLoanVehicleDialog} onClose={oncloseConflictLoanVehicleDialog} />
      )}
    </>
  );
};

export default MyCalendar;
