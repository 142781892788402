import type { FunctionComponent } from 'react';
import type { PricingPackageBasicProps } from '../PricingPackage';
import { Box, TextField, Typography } from '@mui/material';
import  { useEffect, useState } from 'react';
import { flexCenterCol } from '../../../../helpers/Tools';
import { styleReparateur } from '../pricingTools';
import React from 'react';

interface RepaireOffersProps extends PricingPackageBasicProps {
    repaireSupp: number;
    partialRepaireSupp: number;
    handleChangePartialRepaireSupp: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeRepaireSupp: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface RepaireOffersData {
  id: number;
  name: string;
  offers: [{ id: number, include: boolean; optional: boolean, description: string,  value: number;
    values: [{ id: number, description: string, price: number, quantity: number}] 
  }];
}
export interface PartialRepaireOffersData {
  id: number;
  name: string;
  offers: [{ id: number, include: boolean; optional: boolean,  value: number;}] 

}
const RepaireOffers: FunctionComponent<RepaireOffersProps>  = ({ movaPackage, options, repaireSupp, handleChangeRepaireSupp, workforce = 0, onlyView, formFormik, partialRepaireSupp, handleChangePartialRepaireSupp }: RepaireOffersProps) => {
  const currentOfferRepairSupp = options?.reparateur.filter((item: RepaireOffersData) => item.id === 8)[0]?.offers.filter((offre: RepaireOffersData['offers'][0]) => offre.id === movaPackage.id)[0].values as any[];
  const [priceByRepaire, setPriceByRepaire] = useState<number>(0);

  const currentOfferPartialRepairSupp = options?.reparateur.filter((item: RepaireOffersData) => item.id === 80)[0]?.offers.filter((offre: RepaireOffersData['offers'][0]) => offre.id === movaPackage.id)[0] as any;
  const [priceByPartialRepaire, setPriceByPartialRepaire] = useState<number>(currentOfferPartialRepairSupp.value);
  
  useEffect(() => {
    if(formFormik) {
      handleChangeRepaireSupp({
        target: {
          name: 'repaireSupp',
          value: formFormik.values.repaireSupp,
        },
      } as  React.ChangeEvent<HTMLInputElement>);
      handleChangePartialRepaireSupp({
        target: {
          name: 'partialRepaireSupp',
          value: formFormik.values.partialRepaireSupp,
        },
      } as  React.ChangeEvent<HTMLInputElement>)
        
      if (formFormik.values.repaireSupp === currentOfferRepairSupp[0].quantity) {
        setPriceByRepaire(currentOfferRepairSupp[0].price);
      } else if (formFormik.values.repaireSupp === currentOfferRepairSupp[1].quantity) {
        setPriceByRepaire(currentOfferRepairSupp[1].price);
      } else {
        setPriceByRepaire(currentOfferRepairSupp[2].price);
      }
   }
  }, [formFormik.values.repaireSupp, formFormik.values.partialRepaireSupp]);

  const combinedHandleChangeRepaireSupp = (event: React.ChangeEvent<HTMLInputElement>) => {

   formFormik.handleChange(event);  
    handleChangeRepaireSupp(event);
  };
  const combinedHandleChangePartialRepaireSupp = (event: React.ChangeEvent<HTMLInputElement>) => {

    formFormik.handleChange(event);  
     handleChangePartialRepaireSupp(event);
   };
    return (
        <Box sx={{minHeight: '28%', width: '100%', display: 'flex', flexDirection: 'column',}}>
         {options?.reparateur?.map((item: RepaireOffersData) => (
          <React.Fragment key={item.id}>
            <Box
              style={{ ...flexCenterCol, ...styleReparateur }}
              sx={{ minHeight: item.id === 8  || item.id === 80 ? '66px !important' : '' }}
            >
              {(item.id === 6) && (
                <Typography variant='body1' sx={{ margin: 0, padding: 0 }}>
                  {item.offers.filter((offre: RepaireOffersData['offers'][0]) => offre.id === movaPackage.id)[0]?.description}
                </Typography>
              )}
              {(item.name === 'Forfait SMS') && (
                <Typography variant='body1' sx={{ margin: 0, padding: 0 }}>
               {( 1 + formFormik.values.repaireSupp) * item.offers.filter((offre: RepaireOffersData['offers'][0]) => offre.id === movaPackage.id)[0]?.value} SMS
              </Typography>
              )}
              {item.id === 8 && (
                <Box sx={{ width: '85%' }} style={flexCenterCol}>
                    <TextField
                      size='small'
                      disabled={onlyView}
                      onChange={combinedHandleChangeRepaireSupp}
                      fullWidth
                      label={item.name}
                      value={formFormik.values.repaireSupp}
                      name="repaireSupp"
                      type='number'
                      variant='outlined'
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  <Typography
                    variant='body2'
                    className='my-color'
                    sx={{
                      minHeight: '20px',
                    }}
                  >
                    {repaireSupp !== 0 && `${priceByRepaire}€ / réparateur`}
                  </Typography>
                </Box>
              )}
               {item.id === 80 && (
                <Box sx={{ width: '85%' }} style={flexCenterCol}>
                    <TextField
                      size='small'
                      disabled={onlyView}
                      onChange={combinedHandleChangePartialRepaireSupp}
                      fullWidth
                      label={item.name}
                      value={formFormik.values.partialRepaireSupp}
                      name="partialRepaireSupp"
                      type='number'
                      variant='outlined'
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  <Typography
                    variant='body2'
                    className='my-color'
                    sx={{
                      minHeight: '20px',
                    }}
                  >
                    {partialRepaireSupp !== 0 && `${priceByPartialRepaire}€ / réparateur`}
                  </Typography>
                </Box>
              )}
            </Box>
          </React.Fragment>
        ))}
        </Box>
    );
};

export default RepaireOffers;
