import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';

const theme = createTheme({
  palette: {
    background: {
      paper: '#F0F0F0',
      default: '#FFFFFF',
    },
    primary: {
      main: '#BCD46C', // Définissez votre couleur primaire
      light: '#D2EDAB',
    },
    secondary: {
      main: '#FFD25C', //'#F29ABA', // Définissez votre couleur secondaire  // FFC700
      light: '#FEE08E', //'#F1C0D9'
    },
    success: {
      main: '#A8DB5EFF',
    },
    warning: {
      main: '#FFD25C',
      dark: '#FF4D4F',
    },
    /*  text: {
        //light: '#E4E4E4',
      } */
  },
  typography: {
    fontFamily: 'Outfit, sans-serif', // Police de caractère par défaut
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        noOptions: {
          //display: 'none'
        },
        root: {
          '& .MuiAutocomplete-clearIndicator': {
            visibility: 'visible !important',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ ownerState }) => ({
          fontSize: '0.9rem',
          backgroundColor: ownerState.color,
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#5f6368',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          '&.full-screen': {
          '@media (max-width:600px)': {
            top: '0 !important', // Place le menu en haut de l’écran
            left: '0 !important', // Place le menu à gauche de l’écran
            height: 'var(--fullHeight) !important', // 100% de la hauteur pour les petits écrans
            maxHeight: 'var(--fullHeight) !important', // Évite que Material-UI limite la hauteur
            overflowY: 'auto', // Active la scrollbar verticale si nécessaire
            width: '100vw !important',  // 100% de la largeur pour les petits écrans
            maxWidth: '100vw !important', // Évite que Material-UI limite la largeur
          },
        },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        // Name of the slot
        paper: {
          backgroundColor: 'white',
          borderRadius: 16,
          zIndex: 2000,
          //margin: '18px',
          '@media (max-width:600px)': {
            width: '100vw !important',  // 100% de la largeur pour les petits écrans
            height: 'var(--fullHeight)', // 100% de la hauteur pour les petits écrans
            maxWidth: '100vw !important', // Évite que Material-UI limite la largeur
            maxHeight: 'var(--fullHeight)', // Évite que Material-UI limite la hauteur
            margin: 0,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          display: 'flex !important',
          padding: '8px 16px !important',
          '@media (max-width:600px)': {  // Applique le style uniquement sur les écrans xs
            height: '36px !important',  // Hauteur dynamique pour xs
            maxHeight: '36px !important',  // Hauteur dynamique pour xs
            minHeight: '36px !important',  // Hauteur dynamique pour xs
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '@media (max-width:600px)': {  // Applique le style uniquement sur les écrans xs
            height: 'calc(var(--fullHeight) - 67px - 53px - 16px) !important',  // Hauteur dynamique pour xs
            maxHeight: 'calc(var(--fullHeight) - 67px - 53px - 16px) !important',  // Hauteur dynamique pour xs
            minHeight: 'calc(var(--fullHeight) - 67px - 53px - 16px) !important',  // Hauteur dynamique pour xs
          },
          padding: '8px 16px !important',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          '@media (max-width:600px)': {  // Applique le style uniquement sur les écrans xs
            height: '35px !important',  // Hauteur dynamique pour xs
            maxHeight: '35px !important',  // Hauteur dynamique pour xs
            minHeight: '35px !important',  // Hauteur dynamique pour xs
            fontSize: '1rem !important',
            '& .MuiTypography-root': {
            fontSize: '1rem !important',
          },
          },
          padding: '16px !important',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '40px',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
        },
        content: {
          padding: 0,
          margin: '8px',
          minHeight: '16px',
          '&.Mui-expanded': {
            margin: '8px',
            'min-height': '8px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '32px',
          '&.MuiButton-sizeSmall': {
            borderRadius: '12px',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiAccordionSummary-root': {
            padding: '0px',
          },
          '&.MuiMenuItem-root.Mui-selected': {
            'background-color': '#BCD46C',
          },
          '&.MuiMenuItem-root.Mui-selected:hover': {
            'background-color': '#cee580',
          },
        },
      },
    },
  },
});

export default theme;
