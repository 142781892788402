import {
  type Event,
  type DocumentType,
  GarageService,
  LinkedDocumentDialog,
  MovaAppType,
} from '@movalib/movalib-commons';
import {
  Grid,
  Typography,
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Toolbar,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import theme from '../../../theme';
import { AttachFile, CloseRounded } from '@mui/icons-material';
import { flexCenter } from '@movalib/movalib-commons/dist/src/helpers/Tools';

type UploadDocumentDialogProps = {
  currentUpload: boolean;
  setCurrentUpload: (value: boolean) => void;
  isShowConfirmUploadDocument: boolean;
  toggleShowConfirmUploadDocument: () => void;
  localEvent: Event;
  handleOnVehicleUploadDocument: (form: FormData) => void;
  refreshEvent: () => void;
};

export const UploadDocumentDialog = ({
  isShowConfirmUploadDocument,
  toggleShowConfirmUploadDocument,
  localEvent,
  handleOnVehicleUploadDocument,
  currentUpload = false,
  setCurrentUpload,
  refreshEvent,
}: UploadDocumentDialogProps) => {
  const [isShowLinkedDocument, setShowLinkedDocument] = useState(false);

  const currentDocType = useRef<DocumentType>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const isVehicle = useRef<boolean>(false);
  const [sizeLimit, setSizeLimit] = useState(false);
  useEffect(() => {
    return () => {
      currentDocType.current = undefined;
      fileInputRef.current = null;
    };
  }, []);

  const uploadToEvent = () => {
    isVehicle.current = false;
    setShowLinkedDocument(true);
  };
  const uploadToVehicle = () => {
    isVehicle.current = true;
    setShowLinkedDocument(true);
  };

  const toggleShowLinkedDocument = (docType: DocumentType | null) => {
    setShowLinkedDocument(!isShowLinkedDocument);
    if (docType && fileInputRef.current) {
      currentDocType.current = docType;
      fileInputRef.current.click();
    } else {
      currentDocType.current = undefined;
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, docType: DocumentType) => {
    event.preventDefault();
    if (event?.target.files && event.target.files.length > 0 && docType) {
      const document = event.target.files[0];
      if (document.size > 10000000) {
        setSizeLimit(true);
        return;
      }
      if (document && docType) {
        // Utilisation d'un formData pour permettre le trasnfert de fichier vers l'API
        const formData = new FormData();
        formData.append('documentType', docType);
        formData.append('file', document);
        setCurrentUpload(true);
        if (isVehicle.current) {
          handleOnVehicleUploadDocument(formData);
          toggleShowConfirmUploadDocument();
        } else {
          GarageService.uploadEventDocument(localEvent.garageId.toString(), localEvent.id, formData).finally(() => {
            refreshEvent();
            setCurrentUpload(false);
          });
          toggleShowConfirmUploadDocument();
        }
      }
    }
  };

  const inputUpload = () => {
    return (
      <div style={{ display: 'none' }}>
        <input
          accept='image/*, application/pdf'
          type='file'
          style={{ display: 'none' }}
          id='raised-button-invoice'
          onChange={(e) => handleFileChange(e, currentDocType.current!)}
        />
        <label htmlFor='raised-button-invoice'>
          <Button ref={fileInputRef} size='large' component='span' variant='outlined'>
            Ajouter un document
          </Button>
        </label>
      </div>
    );
  };

  return (
    <Dialog
      open={isShowConfirmUploadDocument}
      onClose={toggleShowConfirmUploadDocument}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <Toolbar
        disableGutters
        sx={{
          display: 'block',
          background: theme.palette.grey[200],
          minHeight: 3,
          p: 0,
        }}
      >
        <DialogTitle id='search-dialog-title'>
          <Box position='relative' sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <AttachFile sx={{ height: '30px', width: '30px' }} />
            <Typography
              variant='h6'
              sx={{
                textTransform: 'uppercase',
                fontStyle: 'bold',
                textAlign: 'center',
              }}
            >
              Ajouter un document
            </Typography>
            <IconButton size='small' aria-label='close' onClick={toggleShowConfirmUploadDocument} title={'Fermer'}>
              <CloseRounded />
            </IconButton>
          </Box>
        </DialogTitle>
      </Toolbar>
      <DialogContent sx={{ textAlign: 'center' }}>
        <DialogContentText id='alert-dialog-description' sx={{ pt: 2 }}>
          <Grid container sx={{ justifyContent: 'center' }}>
            <Grid xs={12} sx={{ mb: 2 }}>
              <Typography variant='h6'>
                <b>Souhaitez-vous rattacher ce document au véhicule du client ?</b>
              </Typography>
            </Grid>
            <Grid xs={11} sx={{ mb: 2 }}>
              <Typography variant='subtitle1'>
                Il sera accessible au client et conservé pour un prochain rendez-vous.
              </Typography>
              <Box sx={{ py: 2 }}>
                <Alert severity='warning' sx={{ borderRadius: 10 }} style={flexCenter}>
                  Vous êtes responsable des documents que vous importez. En ajoutant un document, vous certifiez avoir
                  obtenu le consentement explicite du client pour le stockage de ses données, conformément à la
                  réglementation RGPD.
                </Alert>
              </Box>

              {sizeLimit && (
                <Typography
                  variant='body1'
                  sx={{ animation: 'blink 1.5s infinite' }}
                  color={theme.palette.warning.dark}
                >
                  Echec de l'importation car la taille du fichier dépasse 10Mo
                </Typography>
              )}
              {!sizeLimit && <Typography variant='body2'>Taille maximale du fichier : 10Mo</Typography>}
              {currentUpload && (
                <Typography variant='body2' sx={{ animation: 'blink 1.5s infinite' }}>
                  Document en cours d'importation...
                </Typography>
              )}
            </Grid>
            {inputUpload()}
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          py: 1,
          backgroundColor: theme.palette.grey[200],
        }}
      >
        <Button onClick={uploadToEvent} color='inherit' disabled={currentUpload}>
          Non, lier au RDV
        </Button>
        <Button onClick={uploadToVehicle} color='inherit' autoFocus sx={{ ml: 1 }} disabled={currentUpload}>
          <b>Oui, lier au VÉHICULE</b>
        </Button>
      </DialogActions>
      {isShowLinkedDocument && (
        <LinkedDocumentDialog
          appType={MovaAppType.GARAGE}
          isVehicle={isVehicle.current}
          isShowLinkedDocument={isShowLinkedDocument}
          toggleShowLinkedDocument={toggleShowLinkedDocument}
        />
      )}
    </Dialog>
  );
};
