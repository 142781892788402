import React from 'react';
import { startOfWeek, endOfWeek, addDays, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import BigCalendar from 'react-big-calendar';
import { navigate } from 'react-big-calendar/lib/utils/constants';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import { DEFAULT_END_OF_WEEK, DEFAULT_CALENDAR_STEP } from "../../helpers/Constants";
import { connect } from 'react-redux';
import { Garage, Logger } from '@movalib/movalib-commons';

function isDayInSchedules(date, schedules) {
  if(date && schedules){
    const daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

    // Obtention du jour de la semaine sous forme de chaîne de caractères
    const dayOfWeekStr = daysOfWeek[date.getDay()];
  
    // Recherche dans le tableau
    const schedule = schedules.find(schedule => schedule.dayOfWeek === dayOfWeekStr);
  
    return schedule !== undefined; // Retourne true si trouvé, false sinon
  }

  return false;
}

const getRange = (date, garage, culture) => {

  // Premier jour de la semaine fonction de la date du jour (paramètre "date")
  const firstOfWeek = startOfWeek(date, { weekStartsOn: 1 });

  // Par défaut, on ajoute toute la semaine
  const end = addDays(firstOfWeek, 6, 'day');

  let current = firstOfWeek;
  const range = [];

  // Ensuite on boucle sur chaque journée
  while (current <= end) {
    // Si on ne l'a trouve pas dans les schedules du garage, on la filtre
    if(garage.garage && garage.garage.schedules !== undefined){
      if(isDayInSchedules(current, garage.garage.schedules)){
        range.push(current);
      }
    } else {
      range.push(current);
    }

    current = addDays(current, 1);
  }

  return range;
};

const mapStateToProps = (state) => ({
  garage: state.garage
});


class MyCalendarWeek extends React.Component {

  render() {

    const { garage, date, culture, localizer } = this.props;
    const range = getRange(date, garage, culture);

    return <TimeGrid {...this.props} 
              range={range} 
              eventOffset={DEFAULT_CALENDAR_STEP} 
              localizer={localizer}
              scrollToTime={localizer.startOf(new Date(), 'day')}
            />;
  }
}

MyCalendarWeek.navigate = (date, action) => {
  switch (action) {
    case navigate.PREVIOUS:
      return addDays(date, -7);

    case navigate.NEXT:
      return addDays(date, 7);

    default:
      return date;
  }
};

MyCalendarWeek.title = (date, { formats, culture }) => {

  let title = `${format(startOfWeek(date, { weekStartsOn: 1 })
  , 'MMMM yyyy', { locale: fr })}`;

  return `${title.charAt(0).toUpperCase() + title.slice(1)}`;
};


export default connect(mapStateToProps)(MyCalendarWeek);