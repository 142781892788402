import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CUSTOMERS_PER_PAGE } from '../../helpers/Constants';
import CustomerService from '../../services/CustomerService';
import { CUSTOMERS } from './CustomerQueryKeys';

/**
 * Il s'agira ici de récupérer les clients d'un garage donné
 * @param garageId
 * @param page
 * @param size
 */
export const useFetchCustomers = (garageId: string, page = 0, size = CUSTOMERS_PER_PAGE) => {
  return useQuery({
    queryKey: [CUSTOMERS, garageId, page, size],
    queryFn: async () => {
      if (!garageId) {
        return [];
      }

      const { data } = await CustomerService.getCustomers(garageId, size, page);
      return data || [];
    },
  });
};

/**
 * Il s'agira ici de récupérer le nombre de clients d'un garage donné
 * @param garageId
 */
export const useFetchCustomerCount = (garageId: string) => {
  return useQuery({
    queryKey: [CUSTOMERS, garageId],
    queryFn: async () => {
      if (!garageId) {
        return 0;
      }

      const { data } = await CustomerService.countCustomers(garageId);
      return data || 0;
    },
  });
};

/**
 * Il s'agira ici de rechercher les clients d'un garage donné
 * @param garageId
 * @param query
 */
export const useSearchCustomers = (garageId: string, query: string) => {
  return useQuery({
    queryKey: [CUSTOMERS, garageId, query],
    queryFn: async () => {
      if (query.length < 2) {
        return [];
      }

      const { data } = await CustomerService.searchCustomers(garageId, query);
      return data || [];
    },
  });
};

type UseUpsertCustomerProps = {
  garageId: string;
  payload: Record<string, unknown>;
};
export const useUpdateCustomer = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ garageId, payload }: UseUpsertCustomerProps) => {
      const response = await CustomerService.updateCustomer(garageId, payload);

      if (response.success) {
        await queryClient.invalidateQueries({ queryKey: [CUSTOMERS, garageId] });
      }

      return response;
    },
  });
};

export const useCreateCustomer = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ garageId, payload }: UseUpsertCustomerProps) => {
      const response = await CustomerService.createCustomer(garageId, payload);

      if (response.success) {
        await queryClient.invalidateQueries({ queryKey: [CUSTOMERS, garageId] });
      }

      return response;
    },
  });
};

export const useDeleteCustomer = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ garageId, customerId }: { garageId: string; customerId: string }) => {
      const response = await CustomerService.deleteCustomer(garageId, customerId);

      if (response.success) {
        await queryClient.invalidateQueries({ queryKey: [CUSTOMERS, garageId] });
      }

      return response;
    },
  });
};

export const useConvertCustomer = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ garageId, customerId }: { garageId: string; customerId: string }) => {
      const response = await CustomerService.convertCustomer(garageId, customerId);

      if (response.success) {
        await queryClient.invalidateQueries({ queryKey: [CUSTOMERS, garageId] });
      }

      return response;
    },
  });
};
