import React, { useRef } from 'react';
import { memo, useEffect, useState, type FunctionComponent } from 'react';
import {
    Grid,
    Typography,
    Button,
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Tooltip,
    ClickAwayListener,
    Box,
    CircularProgress,
    Collapse,
    FormControl,
    MenuItem,
    Select,
    type SelectChangeEvent,
    TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import CategoriesColorHelp from '../../../assets/images/help/presta_categories_color.png';
import EmptyCard from '../../../components/EmptyCard';
import { StyledTableCell, StyledTableRow } from '../../../helpers/Styled';
import { type CategoryPrestation, type Prestation, type Garage, GarageService, type MovaFormField, validateField, Logger, PrestationState } from '@movalib/movalib-commons';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../../slices/snackbarSlice';
import AddOrEditSupplierDialog from '../../../dialogs/AddOrEditSupplierDialog';
import AddPrestationDialog from '../../../dialogs/AddPrestationDialog';
import theme from '../../../theme';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { colors } from '../../../helpers/Constants';
import { flexEnd, flexStart } from '../../../helpers/Tools';
import { flexCenter } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import EditIcon from '@mui/icons-material/EditRounded';
import CancelIcon from '@mui/icons-material/CloseRounded';
import DoneIcon from '@mui/icons-material/DoneRounded';
import ValidateIcon from '@mui/icons-material/CheckRounded';
import SuppliersTable from './SuppliersTable';

export interface CatergoryPrestationAggrs extends CategoryPrestation {
    prestations: Prestation[];
}
interface MyServicesProps {
    refreshGarage: () => void;
    garage: Garage;
}

const MyServices: FunctionComponent<MyServicesProps> = ({
    refreshGarage,
    garage,
}) => {
    const [openAddSupplier, setOpenAddSupplier] = useState(false);
    const [openAddPrestation, setOpenAddPrestation] = useState(false);
    const [openDowntimeInfo, setOpenDowntimeInfo] = React.useState(false);
    const [openAppointmentDelay, setOpenAppointmentDelay] = React.useState(false);
    const [prestationsWithCategory, setPrestationsWithCategory] = useState<CatergoryPrestationAggrs[]>([]);
    const categoryOpen = useRef('');
    const dispatch = useDispatch();

    useEffect(() => {
        const categoryPrestations = garage?.prestationCategories as CategoryPrestation[];
          const categoryPrestationTable = [] as CatergoryPrestationAggrs[];
          categoryPrestations.forEach((category: CategoryPrestation) => {
            const prestations = garage?.prestations.filter(
              (p) => p.categoryCode === category.code,
            ) as Prestation[];
            categoryPrestationTable.push({ ...category, prestations: prestations });
          });
          categoryPrestationTable.sort((a, b) => a.position - b.position);
          setPrestationsWithCategory(categoryPrestationTable);
    }, [garage]);

    const handleOpenAddPrestation = () => {
        setOpenAddPrestation(true);
      };
    
    const handleCloseAddPrestation = (refresh: boolean) => {
        setOpenAddPrestation(false);
        // On refresh les data si demandée
        if (refresh) {
            refreshGarage();
        }
    };

    const savePrestation = (prestation: Prestation, request: any) => {
        if (garage && prestation) {
          GarageService.updateGaragePrestation(garage.id, String(prestation.id), request).then((response) => {
            if (response.success) {
              dispatch(
                setSnackbar({ open: true, message: response.data ?? 'Modifications enregistrées', severity: 'success' }),
              );
              refreshGarage();
            } else {
              dispatch(
                setSnackbar({
                  open: true,
                  message: response.error ?? 'Modifications des données impossible',
                  severity: 'error',
                }),
              );
            }
          });
        }
      };


    const CategoryPrestationRow = memo((props: { categoryPrestationRow: CatergoryPrestationAggrs }) => {
        const [open, setOpen] = React.useState(categoryOpen.current === props.categoryPrestationRow.code);
        const { categoryPrestationRow } = props;
        const [selectedColor, setSelectedColor] = useState('');
        const [isLoading, setIsLoading] = useState(false);
    
        const handleOpen = () => {
          categoryOpen.current = categoryPrestationRow.code;
          setOpen(!open);
        };
    
        useEffect(() => {
          setSelectedColor(categoryPrestationRow.color);
        }, []);
        const handleChangeColor = (event: SelectChangeEvent<string>) => {
          setSelectedColor(event.target.value);
          setIsLoading(true);
          GarageService.updateColorPrestationCategory(garage.id, categoryPrestationRow.code, event.target.value).finally(
            () => {
              setIsLoading(false);
            },
          );
        };
    
        return (
          <React.Fragment>
            <StyledTableRow
              sx={{
                '& > *': { borderBottom: 'unset' },
                backgroundColor: open ? theme.palette.primary.light : '',
                cursor: 'pointer',
              }}
              onClick={() => handleOpen()}
            >
              <TableCell sx={{ px: 2, py: '6px' }}>
                <IconButton aria-label='expand row' size='small' onClick={() => handleOpen()}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              <TableCell component='th' scope='row' sx={{ px: 2, py: '6px' }}>
                {categoryPrestationRow.name.toUpperCase()}
              </TableCell>
              <TableCell sx={{ px: 2, py: 0 }}>{categoryPrestationRow.description}</TableCell>
              <TableCell align='center' sx={{ px: 2, py: '6px' }}>
                {isLoading ? (
                  <Box display='flex' justifyContent='center' alignItems='center'>
                    <CircularProgress size={24} />
                  </Box>
                ) : (
                  <FormControl fullWidth variant='standard'>
                    <Select
                      size='small'
                      value={selectedColor}
                      onChange={(e) => {
                        e.stopPropagation(); // Arrête la propagation pour éviter d'ouvrir la ligne
                        handleChangeColor(e);
                      }}
                      displayEmpty
                      renderValue={(value: string) =>
                        value === '' || !value ? (
                          <Typography sx={{ color: theme.palette.text.secondary }}>Choisissez une couleur...</Typography>
                        ) : (
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Box
                              sx={{
                                width: 20,
                                height: 20,
                                borderRadius: '50%',
                                backgroundColor: value,
                              }}
                            />
                            {colors.find((color) => color.hex === value)?.name}
                          </Box>
                        )
                      }
                      onClick={(e) => e.stopPropagation()} // Ajoutez ceci pour éviter la propagation du clic
                    >
                      {colors.map((color) => (
                        <MenuItem key={color.hex} value={color.hex}>
                          <Box
                            sx={{
                              width: 20,
                              height: 20,
                              borderRadius: '50%',
                              backgroundColor: color.hex,
                              marginRight: '10px',
                            }}
                          />
                          {color.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </TableCell>
            </StyledTableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout='auto' unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                    {categoryPrestationRow?.prestations &&
                    [...categoryPrestationRow.prestations].filter((p) => p.active).length === 0 ? (
                      <Typography variant='h6' gutterBottom component='div'>
                        Pas de prestation pour cette catégorie
                      </Typography>
                    ) : (
                      <React.Fragment>
                        <Table size='small' aria-label='purchases'>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Nom</StyledTableCell>
                              <StyledTableCell>
                                Immobilisation véhicule
                                <ClickAwayListener onClickAway={() => setOpenDowntimeInfo(false)}>
                                  <Tooltip
                                    placement='top'
                                    PopperProps={{
                                      disablePortal: true,
                                    }}
                                    onClose={() => setOpenDowntimeInfo(false)}
                                    open={openDowntimeInfo}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title='Permet de définir la durée par défaut du rendez-vous sur votre agenda'
                                  >
                                    <IconButton
                                      size='small'
                                      color='primary'
                                      onClick={() => setOpenDowntimeInfo(true)}
                                      sx={{ color: theme.palette.primary.main, p: 0, ml: 1, mb: '2px' }}
                                    >
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                </ClickAwayListener>
                              </StyledTableCell>
                              <StyledTableCell>
                                Délai prise RDV
                                <ClickAwayListener onClickAway={() => setOpenAppointmentDelay(false)}>
                                  <Tooltip
                                    placement='top'
                                    PopperProps={{
                                      disablePortal: true,
                                    }}
                                    onClose={() => setOpenAppointmentDelay(false)}
                                    open={openAppointmentDelay}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title='Délai minimum avant de pouvoir envisager une prise de rendez-vous (côté client)'
                                  >
                                    <IconButton
                                      size='small'
                                      color='primary'
                                      onClick={() => setOpenAppointmentDelay(true)}
                                      sx={{ color: theme.palette.primary.main, p: 0, ml: 1, mb: '2px' }}
                                    >
                                      <InfoIcon />
                                    </IconButton>
                                  </Tooltip>
                                </ClickAwayListener>
                              </StyledTableCell>
                              <StyledTableCell>Actions</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {categoryPrestationRow?.prestations &&
                              [...categoryPrestationRow.prestations]
                                .filter((p) => p.active)
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((prestation) => (
                                  <PrestationRow key={prestation.id} withCategory={false} prestation={prestation} />
                                ))}
                          </TableBody>
                        </Table>
                      </React.Fragment>
                    )}
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
      });

      const PrestationRow = memo((props: { prestation: Prestation; withCategory: boolean }) => {
        type MovaOperationForm = {
          active: MovaFormField; // Boolean
        };
    
        type MovaPrestationForm = {
          active: MovaFormField; // Boolean
          name: MovaFormField;
          downtime: MovaFormField; // Integer
          appointmentDelay: MovaFormField; // Integer
          operations: MovaOperationForm[]; // Liste des opérations
        };
    
        const initialPrestationFormState = {
          active: { value: false, isValid: true },
          name: { value: '', isValid: true },
          downtime: { value: 0, isValid: true },
          appointmentDelay: { value: 0, isValid: true },
          operations: [],
        };
    
        const { prestation } = props;
        const [openRow, setOpenRow] = useState(false);
        const [editRow, setEditRow] = useState(false);
        const [formRow, setFormRow] = useState<MovaPrestationForm>(initialPrestationFormState);
    
        useEffect(() => {
          // Initialisation du formulaire
          initForm();
        }, [prestation]);
    
        const initForm = () => {
          // Initialisation du formulaire
          setFormRow(() => {
            const initialState = initialPrestationFormState;
    
            if (prestation) {
              initialState.active.value = true;
              initialState.downtime.value = prestation.downtime;
              initialState.appointmentDelay.value = prestation.appointmentDelay;
            }
    
            return initialState;
          });
        };
    
        const handleInputChange = (fieldName: keyof MovaPrestationForm, newValue: any) => {
          const newField: MovaFormField = { [fieldName]: { value: newValue, isValid: true } };
    
          setFormRow({ ...formRow, ...newField });
        };
    
        const validateForm = () => {
          let newForm: MovaPrestationForm = { ...formRow };
    
          // Validator pour les champs obligatoires
          newForm.downtime = validateField(formRow.downtime, (value) => !!value, 'Champ obligatoire');
          newForm.appointmentDelay = validateField(
            formRow.appointmentDelay,
            (value) => Number(value) >= 0,
            'Champ obligatoire',
          );
    
          // Validator pour l'email
          newForm.downtime = validateField(
            newForm.downtime,
            (value) => {
              return Number(value) > 0;
            },
            "Le temps d'immobilistation ne peut pas être 0",
          );
    
          setFormRow(newForm);
          Logger.info(newForm);
          return !Boolean(newForm.downtime.error || newForm.appointmentDelay.error);
        };
    
        const handleUpdatePrestation = (prestation: Prestation) => {
          if (prestation && validateForm()) {
            // On contrôle les données saisies
            let request = {
              downtime: formRow.downtime.value,
              appointmentDelay: formRow.appointmentDelay.value,
            };
    
            savePrestation(prestation, request);
    
            // Fermeture de l'édition de la ligne
            initForm();
            setEditRow(!editRow);
          }
        };
    
        const handleEnablePrestation = (prestation: Prestation) => {
          if (garage && prestation) {
            GarageService.enableGaragePrestation(garage.id, String(prestation.id)).then((response) => {
              if (response.success) {
                dispatch(setSnackbar({ open: true, message: response.data ?? 'Prestation activée', severity: 'success' }));
                refreshGarage();
              } else {
                dispatch(
                  setSnackbar({
                    open: true,
                    message: response.error ?? 'Activation de la prestation impossible',
                    severity: 'error',
                  }),
                );
              }
            });
          }
        };
    
        const handleDisablePrestation = (prestation: Prestation) => {
          if (garage && prestation) {
            GarageService.disableGaragePrestation(garage.id, String(prestation.id)).then((response) => {
              if (response.success) {
                dispatch(
                  setSnackbar({ open: true, message: response.data ?? 'Prestation désactivée', severity: 'success' }),
                );
                refreshGarage();
              } else {
                dispatch(
                  setSnackbar({
                    open: true,
                    message: response.error ?? 'Désactivation de la prestation impossible',
                    severity: 'error',
                  }),
                );
              }
            });
          }
        };
    
        return (
          <>
            <React.Fragment>
              <StyledTableRow
                sx={{
                  '& > *': { borderBottom: 'unset' },
                  backgroundColor: prestation.state === PrestationState.NEW ? theme.palette.secondary.light : 'transparent',
                  py: 2,
                }}
                onClick={() => (!(editRow || prestation.state === PrestationState.NEW) ? setOpenRow(!openRow) : null)}
              >
                {props.withCategory && (
                  <TableCell>
                    {garage?.prestationCategories
                      .find((category) => category.code === prestation.categoryCode)
                      ?.name.toUpperCase()}
                  </TableCell>
                )}
                <TableCell sx={{ pl: 1 }}>{prestation.name}</TableCell>
                <TableCell>
                  {!editRow ? (
                    <>
                      <b>{prestation.downtime}</b> h{' '}
                    </>
                  ) : (
                    <Box style={flexStart}>
                      <TextField
                        id='downtime'
                        value={formRow.downtime.value}
                        type='number'
                        required
                        size='small'
                        onClick={(e) => {
                          e.stopPropagation(); // Cela empêche l'événement de remonter au TableRow
                        }}
                        onChange={(e) => {
                          e.stopPropagation(); // Cela empêche l'événement de remonter au TableRow
                          handleInputChange('downtime', e.target.value);
                        }}
                        error={!formRow.downtime.isValid}
                        sx={{ width: '30%' }}
                        InputProps={{ inputProps: { min: 0 } }}
                      />{' '}
                      <Typography variant='body2' sx={{ ml: 3 }}>
                        {' '}
                        Heure(s)
                      </Typography>{' '}
                    </Box>
                  )}
                </TableCell>
                <TableCell>
                  {!editRow ? (
                    <>
                      <b>{prestation.appointmentDelay}</b> jr{' '}
                    </>
                  ) : (
                    <Box style={flexStart}>
                      <TextField
                        id='appointmentDelay'
                        value={formRow.appointmentDelay.value}
                        type='number'
                        required
                        size='small'
                        onClick={(e) => {
                          e.stopPropagation(); // Cela empêche l'événement de remonter au TableRow
                        }}
                        onChange={(e) => {
                          e.stopPropagation(); // Cela empêche l'événement de remonter au TableRow
                          handleInputChange('appointmentDelay', e.target.value);
                        }}
                        sx={{ width: '30%' }}
                        InputProps={{ inputProps: { min: 0 } }}
                      />{' '}
                      <Typography variant='body2' sx={{ ml: 3 }}>
                        {' '}
                        Jour(s)
                      </Typography>{' '}
                    </Box>
                  )}
                </TableCell>
    
                <TableCell align='right'>
                  {!editRow ? (
                    <Grid container sx={{ p: 0 }} style={flexEnd}>
                      {prestation.active && (
                        <Button
                          color='inherit'
                          variant='contained'
                          style={flexCenter}
                          aria-label='modifier prestation'
                          size='small'
                          sx={{ ml: 2, textTransform: 'none' }}
                          onClick={(e) => {
                            e.stopPropagation(); // Cela empêche l'événement de remonter au TableRow
                            setEditRow(!editRow);
                          }}
                        >
                          <EditIcon sx={{ fontSize: '1rem', mr: 1 }} />
                          MODIFIER
                        </Button>
                      )}
                      {prestation.state === PrestationState.VALIDATED && (
                        <Tooltip
                          title={
                            prestation.active
                              ? 'Vos clients ne pourront plus choisir cette prestation'
                              : 'Vos clients pourront choisir cette prestation'
                          }
                          placement='top'
                          followCursor
                          enterDelay={400}
                          slotProps={{
                            tooltip: {
                              sx: {
                                maxWidth: 'none',
                                display: 'flex',
                              },
                            },
                          }}
                        >
                          <Button
                            color={prestation.active ? 'error' : 'primary'}
                            variant='contained'
                            style={flexCenter}
                            aria-label='modifier prestation'
                            size='small'
                            sx={{ ml: 2, textTransform: 'none' }}
                            onClick={(e) => {
                              e.stopPropagation(); // Cela empêche l'événement de remonter au TableRow
                              {
                                prestation.active
                                  ? handleDisablePrestation(prestation)
                                  : handleEnablePrestation(prestation);
                              }
                            }}
                          >
                            {prestation.active ? (
                              <>
                                <CancelIcon sx={{ fontSize: '1rem', mr: 1 }} />
                                DÉSACTIVER
                              </>
                            ) : (
                              <>
                                <DoneIcon sx={{ fontSize: '1rem', mr: 1 }} />
                                ACTIVER CETTE PRESTATION
                              </>
                            )}
                          </Button>
                        </Tooltip>
                      )}
    
                      {prestation.state === PrestationState.NEW && (
                        <Typography
                          variant='body2'
                          sx={{ mt: 0.5, mb: 0.5 }}
                          style={flexCenter}
                          color={theme.palette.text.secondary}
                        >
                          En cours de configuration ...
                        </Typography>
                      )}
                    </Grid>
                  ) : (
                    <Grid container sx={{ p: 0 }} style={flexEnd}>
                      <Button
                        disableElevation
                        style={flexCenter}
                        color='primary'
                        aria-label='valider modifications'
                        size='small'
                        variant='contained'
                        onClick={(e) => {
                          e.stopPropagation(); // Cela empêche l'événement de remonter au TableRow
                          e.preventDefault();
                          handleUpdatePrestation(prestation);
                        }}
                      >
                        <ValidateIcon sx={{ fontSize: '1rem', mr: 1 }} />
                        Enregistrer
                      </Button>
                      <Button
                        color='inherit'
                        variant='text'
                        style={flexCenter}
                        aria-label='annuler modifications'
                        size='small'
                        sx={{ ml: 2 }}
                        onClick={(e) => {
                          e.stopPropagation(); // Cela empêche l'événement de remonter au TableRow
                          initForm();
                          setEditRow(!editRow);
                        }}
                      >
                        <CancelIcon sx={{ fontSize: '1.1rem', mr: 1 }} />
                        Annuler
                      </Button>
                    </Grid>
                  )}
                </TableCell>
              </StyledTableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                  <Collapse in={openRow} timeout='auto' unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Typography variant='subtitle1' gutterBottom component='div'>
                        <b>Opérations associées</b>
                      </Typography>
                      {prestation?.operations?.map((operation) => (
                        <Typography variant='body2' key={operation.description} gutterBottom component='div'>
                          {operation.description}
                        </Typography>
                      ))}
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          </>
        );
      });
    
    return (
        <>
            <Grid container sx={{ pb: 2, pr: 4 }}>
                {/* Section Grossistes */}
                {garage && <SuppliersTable garage={garage!} refreshGarage={refreshGarage} />}
               
                {/* Section Prestations actives */}
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Typography variant="h6" color="text.secondary">
                        <b>Prestations actives</b> ({garage?.prestations.filter((p) => p.active).length})
                    </Typography>
                    <Typography color="primary" sx={{ fontFamily: 'Caveat', fontSize: '1.3rem', mb: 1 }}>
                        <b>Les prestations actives sont disponibles lors d'une prise de rendez-vous par vos clients ou par vous-même</b>
                    </Typography>
                    <Grid container>
                        <Grid item xs={12}>
                            <Button
                                onClick={handleOpenAddPrestation}
                                color="primary"
                                size="small"
                                variant="contained"
                                sx={{ mt: 1, mb: 1, textTransform: 'none', width: '125px' }}
                                startIcon={<AddIcon />}
                            >
                                Ajouter
                            </Button>
                        </Grid>
                        {(!garage?.prestations || garage.prestations.length === 0) && (
                            <Grid item xs={12}>
                                <EmptyCard />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <TableContainer component={Card} sx={{ mt: 2 }}>
                                <Table stickyHeader size="small" aria-label="prestations table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <StyledTableCell>Nom</StyledTableCell>
                                            <StyledTableCell>Description</StyledTableCell>
                                            <StyledTableCell>
                                                Couleur
                                                <Tooltip
                                                    placement="top"
                                                    title={
                                                        <Grid container>
                                                            <Typography sx={{ fontSize: '0.9rem', mb: 1 }}>
                                                                Ajoute un bandeau de couleur sur la gauche de chaque rdv de cette catégorie
                                                            </Typography>
                                                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <img src={CategoriesColorHelp} alt="Illustration couleur categories" style={{ height: '70px' }} />
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                >
                                                    <IconButton
                                                        size="small"
                                                        color="primary"
                                                        sx={{ color: 'primary.main', p: 0, ml: 1, mb: '2px' }}
                                                    >
                                                        <InfoIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {prestationsWithCategory.map((row) => (
                                            <CategoryPrestationRow key={row.code} categoryPrestationRow={row} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Section Prestations inactives */}
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Typography variant="h6" color="text.secondary">
                        <b>Prestations inactives</b> ({garage?.prestations.filter((p) => !p.active).length})
                    </Typography>
                    <Typography color="primary" sx={{ fontFamily: 'Caveat', fontSize: '1.3rem', mb: 1 }}>
                        <b>Les prestations inactives ne sont pas disponibles pour une prise de rendez-vous</b>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Card} sx={{ mt: 2 }}>
                        <Table stickyHeader size="small" aria-label="prestations table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Catégorie</StyledTableCell>
                                    <StyledTableCell>Nom</StyledTableCell>
                                    <StyledTableCell>
                                        Immobilisation véhicule
                                        <ClickAwayListener onClickAway={() => setOpenDowntimeInfo(false)}>
                                            <Tooltip
                                                placement="top"
                                                PopperProps={{ disablePortal: true }}
                                                onClose={() => setOpenDowntimeInfo(false)}
                                                open={openDowntimeInfo}
                                                title="Permet de définir la durée par défaut du rendez-vous sur votre agenda"
                                            >
                                                <IconButton
                                                    size="small"
                                                    color="primary"
                                                    onClick={() => setOpenDowntimeInfo(true)}
                                                    sx={{ color: 'primary.main', p: 0, ml: 1, mb: '2px' }}
                                                >
                                                    <InfoIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </ClickAwayListener>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        Délai prise RDV
                                        <ClickAwayListener onClickAway={() => setOpenAppointmentDelay(false)}>
                                            <Tooltip
                                                placement="top"
                                                PopperProps={{ disablePortal: true }}
                                                onClose={() => setOpenAppointmentDelay(false)}
                                                open={openAppointmentDelay}
                                                title="Délai minimum avant de pouvoir envisager une prise de rendez-vous (côté client)"
                                            >
                                                <IconButton
                                                    size="small"
                                                    color="primary"
                                                    onClick={() => setOpenAppointmentDelay(true)}
                                                    sx={{ color: 'primary.main', p: 0, ml: 1, mb: '2px' }}
                                                >
                                                    <InfoIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </ClickAwayListener>
                                    </StyledTableCell>
                                    <StyledTableCell>Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {garage?.prestations && garage.prestations.length === 0 && (
                                    <TableRow key="0-presta">
                                        <TableCell colSpan={5} align="center" sx={{ color: 'text.secondary', p: 4 }}>
                                            AUCUN RESULTAT
                                        </TableCell>
                                    </TableRow>
                                )}
                                {garage?.prestations
                                    ?.filter((p) => !p.active)
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((prestation) => (
                                        <PrestationRow key={prestation.id} withCategory prestation={prestation} />
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            {openAddPrestation && garage && garage.id && (
                <AddPrestationDialog
                    open={openAddPrestation}
                    garageId={garage.id}
                    onClose={handleCloseAddPrestation}
                    showHelp={true}
                />
            )}
        </>
    );

};

export default MyServices;
