import { CloseRounded } from '@mui/icons-material';
import {
  Dialog,
  Toolbar,
  DialogTitle,
  Box,
  Typography,
  IconButton,
  DialogContent,
  Button,
  useMediaQuery,
} from '@mui/material';
import theme from '../../../theme';

import { GarageService, type Event, type Garage, type VehicleGarage } from '@movalib/movalib-commons';
import MyCalendarEvent from '../MyCalendarEvent';
import { eventStyleGetter } from '../MyCalendar';
import { flexCenter, formatFrenchVehiclePlate } from '@movalib/movalib-commons/dist/src/helpers/Tools';
import { flexCenterCol } from '../../../helpers/Tools';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../../slices/snackbarSlice';

type LoanVehicleDialogProps = {
  vehicle: VehicleGarage;
  garage: Garage;
  events: Event[];
  isDialogOpened: boolean;
  onRefresh: () => void;
  handleCloseDialog: () => void;
};

export const LoanVehicleDialog = ({
  vehicle,
  garage,
  events,
  isDialogOpened,
  handleCloseDialog,
  onRefresh,
}: LoanVehicleDialogProps) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const eventsFiltered = events
    .filter((event) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const eventEnd = new Date(event.end as Date);
      eventEnd.setHours(0, 0, 0, 0);
      return event.garageVehicleId === vehicle.id && eventEnd >= today;
    })
    .sort((a, b) => (a.start as Date).getTime() - (b.start as Date).getTime());
  const deassignVehicle = (event: Event) => {
    GarageService.deassignGarageVehicleEvent(garage.id, event.id)
      .then(() => {
        dispatch(
          setSnackbar({ open: true, message: "Véhicule de prêt désassigné d'un rendez-vous", severity: 'success' }),
        );
        onRefresh();
      })
      .catch((error) => {
        dispatch(setSnackbar({ open: true, message: 'Impossible de désassigner le véhicule', severity: 'error' }));
      })
      .finally(() => {
        handleCloseDialog();
      });
  };
  return (
    <Dialog
      open={isDialogOpened}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth='md'
      sx={{ height: { md: '100%', xs: 'auto' } }}
    >
      <Toolbar
        disableGutters
        sx={{
          display: 'block',
          background: theme.palette.grey[200],
          minHeight: 3,
          p: 0,
        }}
      >
        <DialogTitle id='search-dialog-title'>
          <Box position='relative'>
            <Typography
              variant='h6'
              sx={{
                textTransform: 'uppercase',
                textAlign: 'center',
              }}
            >
              Véhicule de prêt n° {vehicle?.index} :{' '}
              <b>
                {vehicle.vehicle.brand} {vehicle.vehicle.model}
              </b>
            </Typography>
            <IconButton
              sx={{ position: 'absolute', right: 0, top: 0, zIndex: 1 }}
              size='small'
              aria-label='close'
              onClick={handleCloseDialog}
              title={'Fermer'}
            >
              <CloseRounded />
            </IconButton>
          </Box>
        </DialogTitle>
      </Toolbar>
      <DialogContent>
        <Box sx={flexCenterCol}>
          {eventsFiltered.map((event) => {
            const { style, className } = eventStyleGetter(
              event,
              event.start ?? new Date(),
              event.end ?? new Date(),
              false,
            );
            return (
              <Box
                key={`${event.id}-loanVehicle-${vehicle.id}-container`}
                sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: isMobile ? '100%' : '80%' }}
              >
                <Box
                  key={`${event.id}-loanVehicle-${vehicle.id}`}
                  style={style}
                  className={className}
                  sx={{ my: 1, width: isMobile ? '90%' : '70%', p: 1 }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography variant='body2'>
                      {moment(event.start).isSame(event.end, 'day') ? (
                        <>
                          <strong>{moment(event.start).format('DD/MM')}</strong>&nbsp;&nbsp;
                          {moment(event.start).format('HH:mm')}
                        </>
                      ) : (
                        <>
                          <strong>{moment(event.start).format('DD/MM')}</strong>&nbsp;-&nbsp;
                          {moment(event.start).format('HH:mm')}
                        </>
                      )}
                    </Typography>
                    &nbsp;&nbsp;&nbsp;
                    <Typography variant='body2'>
                      {!moment(event.start).isSame(event.end, 'day') && (
                        <>
                          <strong>{moment(event.end).format('DD/MM')}</strong>&nbsp;-&nbsp;
                        </>
                      )}
                      {moment(event.end).format('HH:mm')}
                    </Typography>
                    <Box
                      key={`${event.id}-loanVehicle-${vehicle.id}-spacer-1`}
                      sx={{ display: 'flex', flexGrow: '1' }}
                    />
                    <Typography variant='body2'>{formatFrenchVehiclePlate(event.vehicle?.plate)}</Typography>
                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                      {' '}
                      / {event.customer?.companyName ?? event.customer?.lastname}
                    </Typography>
                  </Box>
                </Box>
                <Box key={`${event.id}-loanVehicle-${vehicle.id}-spacer-1`} sx={{ display: 'flex', flexGrow: '1' }} />
                <Button
                  key={`${event.id}-loanVehicle-${vehicle.id}-button`}
                  color='error'
                  onClick={() => deassignVehicle(event)}
                  variant='text'
                >
                  Désaffecter
                </Button>
              </Box>
            );
          })}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default LoanVehicleDialog;
