import { type Event, EventState } from '@movalib/movalib-commons';
import { formatISO, startOfDay } from 'date-fns';
import type { AnyAction, Dispatch } from 'redux';
import { request } from '../helpers/ApiHelper';
import { API_BASE_URL } from '../helpers/Constants';
import { APIMethod } from '../helpers/Enums';
import type Garage from '../models/Garage';

/**
 * Les méthodes de ce Service sont toujours fonctionnels.
 * Cependant, il est recommandé d'utiliser le GarageService fournit par le paquet @movalib/movalib-commons
 * @deprecated
 */
export default class GarageService {
  static incrementRepairOrderCounter(
    dispatch: Dispatch<AnyAction>,
    garageId: string,
    eventId: string,
  ): Promise<string> {
    return request(
      {
        url: `${API_BASE_URL}/garage/${garageId}/events/${eventId}/increment-repair-order-counter`,
        method: APIMethod.POST,
      },
      dispatch,
    );
  }

  static getLastRepairOrderCounter(dispatch: Dispatch<AnyAction>, garageId: string, eventId: string): Promise<number> {
    return request(
      {
        url: `${API_BASE_URL}/garage/${garageId}/events/${eventId}/repair-order-counter`,
        method: APIMethod.GET,
      },
      dispatch,
    );
  }

  static getGarageEvent(dispatch: Dispatch<AnyAction>, garageId: string, eventId: string): Promise<Event> {
    return request(
      {
        url: `${API_BASE_URL}/garage/${garageId}/events/${eventId}`,
        method: APIMethod.GET,
      },
      dispatch,
    );
  }

  static searchGarageEvents(
    dispatch: Dispatch<AnyAction>,
    garageId: string,
    query: string,
    includeHistory: boolean,
  ): Promise<Event[]> {
    return request(
      {
        url: `${API_BASE_URL}/garage/${garageId}/events/search?query=${query}&history=${includeHistory}`,
        method: APIMethod.GET,
      },
      dispatch,
    );
  }

  static closeGarageEvent(
    dispatch: Dispatch<AnyAction>,
    garageId: string,
    eventId: string,
    formData: FormData,
  ): Promise<string> {
    return request(
      {
        url: `${API_BASE_URL}/garage/${garageId}/event/${eventId}/close`,
        method: APIMethod.PATCH,
        body: formData,
      },
      dispatch,
    );
  }

  static uncompleteGarageEvent(dispatch: Dispatch<AnyAction>, garageId: string, eventId: string): Promise<string> {
    return request(
      {
        url: `${API_BASE_URL}/garage/${garageId}/event/${eventId}/uncomplete`,
        method: APIMethod.PATCH,
      },
      dispatch,
    );
  }

  static completeGarageEvent(dispatch: Dispatch<AnyAction>, garageId: string, eventId: string): Promise<string> {
    return request(
      {
        url: `${API_BASE_URL}/garage/${garageId}/event/${eventId}/complete`,
        method: APIMethod.PATCH,
      },
      dispatch,
    );
  }

  static acceptGarageEvent(
    dispatch: Dispatch<AnyAction>,
    garageId: string,
    eventId: string,
    formData: FormData,
  ): Promise<string> {
    return request(
      {
        url: `${API_BASE_URL}/garage/${garageId}/event/${eventId}/accept`,
        method: APIMethod.PATCH,
        body: formData,
      },
      dispatch,
    );
  }

  static scheduleGarageEvent(garageId: string, eventId: string): Promise<string> {
    return request({
      url: `${API_BASE_URL}/garage/${garageId}/event/${eventId}/accept`,
      method: APIMethod.PATCH,
    });
  }

  static deleteGarageEvent(dispatch: Dispatch<AnyAction>, garageId: string, eventId: string): Promise<string> {
    return request(
      {
        url: `${API_BASE_URL}/garage/${garageId}/event/${eventId}`,
        method: APIMethod.DELETE,
      },
      dispatch,
    );
  }

  static cancelGarageEvent(dispatch: Dispatch<AnyAction>, garageId: string, eventId: string): Promise<string> {
    return request(
      {
        url: `${API_BASE_URL}/garage/${garageId}/event/${eventId}/cancel`,
        method: APIMethod.PATCH,
      },
      dispatch,
    );
  }

  static createGarageEvent(dispatch: Dispatch<AnyAction>, garageId: string, formData: FormData): Promise<string> {
    return request(
      {
        url: `${API_BASE_URL}/garage/${garageId}/events`,
        method: APIMethod.POST,
        body: formData, //JSON.stringify(req)
      },
      dispatch,
    );
  }

  static getAdministratedGarages(dispatch: Dispatch<AnyAction>, garageId: string): Promise<Garage> {
    return request(
      {
        url: `${API_BASE_URL}/user/garage/${garageId}`,
        method: APIMethod.GET,
      },
      dispatch,
    );
  }

  static updateGarage(dispatch: Dispatch<AnyAction>, garageId: string, req: any): Promise<Garage> {
    return request(
      {
        url: `${API_BASE_URL}/garage/${garageId}`,
        method: APIMethod.PATCH,
        body: JSON.stringify(req),
      },
      dispatch,
    );
  }

  static getCalendarEvents(
    dispatch: Dispatch<AnyAction>,
    garageId: string,
    startDate: Date,
    endDate: Date,
  ): Promise<Event[] | null | undefined> {
    return new Promise((resolve, reject) => {
      const startDateWithoutTime = startOfDay(startDate);
      const formattedStartDate = formatISO(startDateWithoutTime, { representation: 'complete' });
      const formattedEndDate = formatISO(endDate, { representation: 'complete' });

      const url = `${API_BASE_URL}/garage/${garageId}/events?startDate=${encodeURIComponent(
        formattedStartDate,
      )}&endDate=${encodeURIComponent(formattedEndDate)}&searchTerm=`;

      request(
        {
          url: url,
          method: APIMethod.GET,
        },
        dispatch,
      )
        .then((data) => {
          if (data) {
            // Cette première partie FILTRE les Nouvelles demandes de rendez-vous ANTERIEURES à la date du jour
            const filteredData = data
              .filter((event: { start: string | number | Date; end: string | number | Date; state: EventState }) => {
                // Convertit la date de fin de l'événement en objet Date
                const eventStartDate = new Date(event.start);
                // On renvoi les évènements dont la date de début est postérieure à la date actuelle et le statut est différent de NEW (nouvelle demande de RDV)
                return (eventStartDate < new Date() && event.state !== EventState.NEW) || eventStartDate >= new Date();
              })
              // Mappe les objets pour les convertir en objets Event
              // Attention a bien reprendre le nom des propriétés de l'objet Event pour ce map
              .map((obj: { start: string | number | Date; end: string | number | Date }) => ({
                ...obj,
                start: new Date(obj.start),
                end: new Date(obj.end),
              })) as Event[];
            // Résout la promesse avec les données filtrées
            resolve(filteredData);
          } else {
            // Résout la promesse avec null si aucune donnée n'est renvoyée
            resolve(null);
          }
        })
        .catch((error) => {
          // Rejette la promesse si une erreur se produit dans le bloc try
          reject(error);
        });
    });
  }
}
