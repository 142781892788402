import { GarageService, type Garage } from '@movalib/movalib-commons';
import { Box, Button, Grid, IconButton, Switch, TextField, Typography, useMediaQuery } from '@mui/material';
import { useState, type FunctionComponent } from 'react';
import theme from '../../../theme';
import LoanerVehicleImg from '../../../assets/images/loanerVehicle.png';
import PaymentAuthorizationImg from '../../../assets/images/paymentAuthorization.png';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../../slices/snackbarSlice';
import CustomOption from '../../../components/CustomOption';

interface MyOptionsProps {
  garage: Garage;
  refreshGarage: () => void;
}
const MyOptions: FunctionComponent<MyOptionsProps> = ({ garage, refreshGarage }) => {
  const [isLoanerActive, setIsLoanerActive] = useState<boolean>(garage.loanerVehicleActive ?? false);
  const [isPaymentAuthorizationActive, setIsPaymentAuthorizationActive] = useState<boolean>(
    garage.paymentAuthorizationActive ?? false,
  );
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleChangePaymentAuthorization = (v: boolean) => {
    GarageService.toogleGaragePaymentAuthorization(garage.id)
      .then((res) => {
        if (res.success) {
          setIsPaymentAuthorizationActive(v);
          dispatch(
            setSnackbar({
              open: true,
              message: `L\'option empreinte bancaire est ${v ? 'activé' : 'désactivé'}`,
              severity: 'success',
            }),
          );
          refreshGarage();
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: `Impossible ${v ? "d'activer" : ' de désactiver'} l\'option empreinte bancaire`,
              severity: 'error',
            }),
          );
        }
      })
      .catch(() => {
        dispatch(
          setSnackbar({
            open: true,
            message: `Impossible ${v ? "d'activer" : ' de désactiver'} l\'option empreinte bancaire`,
            severity: 'error',
          }),
        );
      });
  };

  const handleChangeLoanerVehicle = (v: boolean) => {
    GarageService.toogleGarageLoanerVehicle(garage.id)
      .then((res) => {
        if (res.success) {
          setIsLoanerActive(v);
          dispatch(
            setSnackbar({
              open: true,
              message: `L\'option véhicule de prêt est ${v ? 'activé' : 'désactivé'}`,
              severity: 'success',
            }),
          );
          refreshGarage();
        } else {
          dispatch(
            setSnackbar({
              open: true,
              message: `Impossible ${v ? "d'activer" : ' de désactiver'} l\'option véhicule de prêt`,
              severity: 'error',
            }),
          );
        }
      })
      .catch(() => {
        dispatch(
          setSnackbar({
            open: true,
            message: `Impossible ${v ? "d'activer" : ' de désactiver'} l\'option véhicule de prêt`,
            severity: 'error',
          }),
        );
      });
  };

  return (
    <>
      <Grid container spacing={2} sx={{ pr: 2 }}>
        <Grid item xs={isMobile ? 12 : 6} sx={{ mt: 2 }}>
          <CustomOption
            title='Véhicules de prêt'
            description='Paramétrez votre flotte de véhicule et affectez vos véhicules de prêt sur vos rendez-vous.'
            image={LoanerVehicleImg}
            isActive={isLoanerActive}
            onChange={handleChangeLoanerVehicle}
          />{' '}
        </Grid>
        <Grid item xs={isMobile ? 12 : 6} sx={{ mt: 2 }}>
          <CustomOption
            title='Confirmation RDV avec empreinte CB'
            description="Une empreinte bancaire sera requise pour confirmer toute demande de rendez-vous en ligne au dela d'une durée paramétrable."
            image={PaymentAuthorizationImg}
            isActive={isPaymentAuthorizationActive}
            onChange={handleChangePaymentAuthorization}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default MyOptions;
